import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./candidatesummary.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  candidateSummaryList,
  candidateFilterSummaryList,
} from "../../../../slice/candidates/candidatesSlice";
import {
  pipelineSummaryRcFilter,
  pipelineClearSummaryRcFilter,
} from "../../../../slice/layout/layoutSlice";
//import { countAllTask } from "../../../../slice/task/taskSlice"
import {
  summaryFilter,
  countAll,
} from "../../../../slice/candidates/list/listSlice";
//import { summaryFilter, dbReadyList, recAdminList, countAll } from "../../../../slice/candidates/list/listSlice";
import {
  FaCheck,
  FaRegCalendarAlt,
  FaMale,
  FaTransgenderAlt,
  FaMinus,
  FaTimes,
  FaReplyAll,
} from "react-icons/fa";

const Summary = () => {
  const dispatch = useDispatch();
  const { isLoading, candidateDataSummaryList } = useSelector(
    (state) => state.candidate
  );
  const { filterRecId, RecValue, RecName } = useSelector(
    (state) => state.common
  );
  //const pathname = window.location.pathname;
  //const segment3 = pathname.split("/")[3];
  const [list, setList] = useState([]);

  useEffect(() => {
    document.title = "Candidate Summary | DKG Recruitment | DK Global CRM";
  }, []);

  const [isYearFilter, setIsYearFilter] = useState(false);
  const [yearFilterText, setYearFilterText] = useState("");

  const [isMonthsFilter, setIsMonthsFilter] = useState(false);
  const [monthsFilterText, setMonthsFilterText] = useState("");

  const [isCategoryFilter, setIsCategoryFilter] = useState(false);
  const [categoryFilterText, setCategoryFilterText] = useState("");

  const [isGenderFilter, setIsGenderFilter] = useState(false);
  const [genderFilterText, setGenderFilterText] = useState("");

  const [isFlagFilter, setIsFlagFilter] = useState(false);
  const [flagFilterText, setFlagFilterText] = useState("");

  const [isProfileIndicatorFilter, setIsProfileIndicatorFilter] =
    useState(false);
  const [profileIndicatorText, setProfileIndicatorText] = useState("");

  const [isCanMotivationStatusFilter, setIsCanMotivationStatusFilter] =
    useState(false);
  const [canMotivationStatusText, setCanMotivationStatusText] = useState("");

  const [isAccountManagerFilter, setIsAccountManagerFilter] = useState(false);
  const [accountManagerFilterText, setAccountManagerFilterText] = useState("");

  const [isRecruiterFilter, setIsRecruiterFilter] = useState(false);
  const [recruiterFilterText, setRecruiterFilterText] = useState("");
  const [isRecruiterAdminFilter, setIsRecruiterAdminFilter] = useState(false);
  const [recruiterAdminFilterText, setRecruiterAdminFilterText] = useState("");

  const [isCompanyFilter, setIsCompanyFilter] = useState(false);
  const [companyFilterText, setCompanyFilterText] = useState("");

  const [isProfileStatus, setIsProfileStatus] = useState(false);
  const [ProfileStatusText, setProfileStatusText] = useState("");

  const [isCaseStatusFilter, setIsCaseStatusFilter] = useState(false);
  const [CaseStatusFilterText, setCaseStatusFilterText] = useState("");

  const [isPipelineFilter, setIsPipelineFilter] = useState(false);
  const [pipelineFilterText, setPipelineFilterText] = useState("");

  const [isCaseProgressFilter, setIsCaseProgressFilter] = useState(false);
  const [CaseProgressFilterText, setCaseProgressFilterText] = useState("");

  const [isIndustriesFilter, setIsIndustriesFilter] = useState(false);
  const [isIndustriesFilterText, setIsIndustriesFilterText] = useState("");

  const [isVisaFilter, setIsVisaFilter] = useState(false);
  const [isVisaFilterText, setIsVisaFilterText] = useState("");

  const [isCountryFilter, setIsCountryFilter] = useState(false);
  const [isCountryFilterText, setIsCountryFilterText] = useState("");

  const [isNationalityFilter, setIsNationalityFilter] = useState(false);
  const [isNationalityFilterText, setIsNationalityFilterText] = useState("");

  const [isCityFilter, setIsCityFilter] = useState(false);
  const [isCityFilterText, setIsCityFilterText] = useState("");

  const [isNative1Filter, setIsNative1Filter] = useState(false);
  const [isNative1FilterText, setIsNative1FilterText] = useState("");

  const [isNative2Filter, setIsNative2Filter] = useState(false);
  const [isNative2FilterText, setIsNative2FilterText] = useState("");

  const [isCaseStageFilter, setIsCaseStageFilter] = useState(false);
  const [CaseStageFilterText, setCaseStageFilterText] = useState("");

  const [isCvSourceFilter, setIsCvSourceFilter] = useState(false);
  const [cvSourceFilterText, setCvSourceFilterText] = useState("");

  const [isLocationFilter, setIsLocationFilter] = useState(false);
  const [locationText, setLocationText] = useState("");

  const [isRoleTypeFilter, setIsRoleTypeFilter] = useState(false);
  const [roleTypeFilterText, setRoleTypeFilterText] = useState("");

  const [isProjectFilter, setIsProjectFilter] = useState(false);
  const [projectText, setProjectText] = useState("");

  const [isLanguageFilter, setIsLanguageFilter] = useState(false);
  const [languageFilterText, setLanguageFilterText] = useState("");

  const [isApprovelFilter, setIsApprovelFilter] = useState(false);
  const [approvelFilterText, setApprovelFilterText] = useState("");

  const [rcUserList, setRcUserList] = useState([]);
  const [raUserList, setRaUserList] = useState([]);

  const [amUserList, setAmUserList] = useState([]);

  const [companyList, setCompanyList] = useState([]);
  const [cpList, setCpList] = useState([]);
  const [inList, setInList] = useState([]);
  const [visaList, setVisaList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [nationalityList, setNationalityList] = useState([]);
  const [native1List, setNative1List] = useState([]);
  const [native2List, setNative2List] = useState([]);
  const [csList, setCsList] = useState([]);
  const [cvSourceList, setCvSourceList] = useState([]);
  const [roleTypeList, setRoleTypeList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [langList, setLangList] = useState([]);
  const [locationList, setLocationList] = useState([]);

  const [statusList, setStatusList] = useState([]);

  useEffect(() => {
    if (!isLoading && candidateDataSummaryList.length > 0) {
      //setList(candidateDataSummaryList)
    }
  }, [candidateDataSummaryList, isLoading]);

  useEffect(() => {
    const fetchList = async () => {
      try {
        if (filterRecId != "") {
          setIsRecruiterFilter(true);
          setRecruiterFilterText(filterRecId.RecName);
          setFilter({ ...filter, ["rcId"]: filterRecId.RecValue });
          await dispatch(
            candidateFilterSummaryList({ rec_id: filterRecId.rec_id })
          );
        } else {
          await dispatch(candidateSummaryList());
          //await dispatch(countAllTask())
        }
      } catch (err) {}
    };
    fetchList();
  }, [dispatch]);

  useEffect(() => {
    const raResult = [];
    const ramap = new Map();
    for (const item of list) {
      if (!ramap.has(item.raId)) {
        ramap.set(item.raId, true);
        raResult.push({
          id: item.raId,
          name: item.raFirstName,
          userImg: item.raProfileImage,
          count: list.filter(function (val) {
            return val.raId === item.raId;
          }).length,
        });
      }
    }
    raResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setRaUserList(raResult);

    const rcResult = [];
    const rcmap = new Map();
    for (const item of list) {
      if (!rcmap.has(item.rcId)) {
        rcmap.set(item.rcId, true);
        rcResult.push({
          id: item.rcId,
          name: item.rcFirstName,
          userImg: item.rcProfileImage,
          count: list.filter(function (val) {
            return val.rcId === item.rcId;
          }).length,
        });
      }
    }
    rcResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setRcUserList(rcResult);

    const amResult = [];
    const amMap = new Map();
    for (const item of list) {
      if (!amMap.has(item.amId)) {
        amMap.set(item.amId, true);
        amResult.push({
          id: item.amId,
          name: item.amFirstName,
          userImg: item.amProfileImage,
          count: list.filter(function (val) {
            return val.amId === item.amId;
          }).length,
        });
      }
    }
    amResult.sort(function (a, b) {
      return b.count - a.count;
    });

    setAmUserList(amResult);

    const cpResult = [];
    const cpmap = new Map();
    for (const item of list) {
      if (!cpmap.has(item.case_progress)) {
        cpmap.set(item.case_progress, true);
        cpResult.push({
          name: item.case_progress != "" ? item.case_progress : "Blank",
          count: list.filter(function (val) {
            return val.case_progress === item.case_progress;
          }).length,
        });
      }
    }
    cpResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setCpList(cpResult);

    const inResult = [];
    const inmap = new Map();
    for (const item of list) {
      if (!inmap.has(item.industries)) {
        inmap.set(item.industries, true);
        inResult.push({
          name: item.industries != "" ? item.industries : "Blank",
          count: list.filter(function (val) {
            return val.industries === item.industries;
          }).length,
        });
      }
    }
    inResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setInList(inResult);

    const visaResult = [];
    const visamap = new Map();
    for (const item of list) {
      if (!visamap.has(item.visaStatus)) {
        visamap.set(item.visaStatus, true);
        visaResult.push({
          name: item.visaStatus != "" ? item.visaStatus : "Blank",
          count: list.filter(function (val) {
            return val.visaStatus === item.visaStatus;
          }).length,
        });
      }
    }
    visaResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setVisaList(visaResult);

    const countryResult = [];
    const countrymap = new Map();
    for (const item of list) {
      if (!countrymap.has(item.c_country)) {
        countrymap.set(item.c_country, true);
        countryResult.push({
          name: item.c_country != "" ? item.c_country : "Blank",
          count: list.filter(function (val) {
            return val.c_country === item.c_country;
          }).length,
        });
      }
    }
    countryResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setCountryList(countryResult);

    const cityResult = [];
    const cityymap = new Map();
    for (const item of list) {
      if (!cityymap.has(item.c_city)) {
        cityymap.set(item.c_city, true);
        cityResult.push({
          name: item.c_city != "" ? item.c_city : "Blank",
          count: list.filter(function (val) {
            return val.c_city === item.c_city;
          }).length,
        });
      }
    }
    cityResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setCityList(cityResult);

    const nationalityResult = [];
    const nationalityymap = new Map();
    for (const item of list) {
      if (!nationalityymap.has(item.nationality)) {
        nationalityymap.set(item.nationality, true);
        nationalityResult.push({
          name: item.nationality != "" ? item.nationality : "Blank",
          count: list.filter(function (val) {
            return val.nationality === item.nationality;
          }).length,
        });
      }
    }
    nationalityResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setNationalityList(nationalityResult);

    const native1Result = [];
    const native1map = new Map();
    for (const item of list) {
      if (!native1map.has(item.native1)) {
        native1map.set(item.native1, true);
        native1Result.push({
          name: item.native1 != "" ? item.native1 : "Blank",
          count: list.filter(function (val) {
            return val.native1 === item.native1;
          }).length,
        });
      }
    }
    native1Result.sort(function (a, b) {
      return b.count - a.count;
    });
    setNative1List(native1Result);

    const native2Result = [];
    const native2map = new Map();
    for (const item of list) {
      if (!native2map.has(item.native2)) {
        native2map.set(item.native2, true);
        native2Result.push({
          name: item.native2 != "" ? item.native2 : "Blank",
          count: list.filter(function (val) {
            return val.native2 === item.native2;
          }).length,
        });
      }
    }
    native2Result.sort(function (a, b) {
      return b.count - a.count;
    });
    setNative2List(native2Result);

    const csResult = [];
    const csmap = new Map();
    for (const item of list) {
      if (!csmap.has(item.case_stage)) {
        csmap.set(item.case_stage, true);
        csResult.push({
          name: item.case_stage != "" ? item.case_stage : "Blank",
          count: list.filter(function (val) {
            return val.case_stage === item.case_stage;
          }).length,
        });
      }
    }
    csResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setCsList(csResult);

    const cvSourceResult = [];
    const cvSourcemap = new Map();
    for (const item of list) {
      if (!cvSourcemap.has(item.cv_source)) {
        cvSourcemap.set(item.cv_source, true);
        cvSourceResult.push({
          name: item.cv_source != "" ? item.cv_source : "Blank",
          count: list.filter(function (val) {
            return val.cv_source === item.cv_source;
          }).length,
        });
      }
    }
    cvSourceResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setCvSourceList(cvSourceResult);

    const companyResult = [];
    const companymap = new Map();
    for (const item of list) {
      if (!companymap.has(item.company)) {
        companymap.set(item.company, true);
        companyResult.push({
          name: item.company != "" ? item.company : "Blank",
          count: list.filter(function (val) {
            return val.company === item.company;
          }).length,
        });
      }
    }
    companyResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setCompanyList(companyResult);

    const statusResult = [];
    const statusmap = new Map();
    for (const item of list) {
      if (!statusmap.has(item.status)) {
        statusmap.set(item.status, true);
        statusResult.push({
          name: item.status != "" ? item.status : "Blank",
          count: list.filter(function (val) {
            return val.status === item.status;
          }).length,
        });
      }
    }
    statusResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setStatusList(statusResult);

    const LocationResult = [];
    const locationmap = new Map();
    for (const item of list) {
      if (!locationmap.has(item.location)) {
        locationmap.set(item.location, true);
        LocationResult.push({
          name: item.location != "" ? item.location : "Blank",
          count: list.filter(function (val) {
            return val.location === item.location;
          }).length,
        });
      }
    }
    LocationResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setLocationList(LocationResult);

    const ProjectResult = [];
    const projectemap = new Map();
    for (const item of list) {
      if (!projectemap.has(item.project)) {
        projectemap.set(item.project, true);
        ProjectResult.push({
          name: item.project != "" ? item.project : "Blank",
          count: list.filter(function (val) {
            return val.project === item.project;
          }).length,
        });
      }
    }
    ProjectResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setProjectList(ProjectResult);

    const roleTyResult = [];
    const roleTypemap = new Map();
    for (const item of list) {
      if (!roleTypemap.has(item.role)) {
        roleTypemap.set(item.role, true);
        roleTyResult.push({
          name: item.role != "" ? item.role : "Blank",
          count: list.filter(function (val) {
            return val.role === item.role;
          }).length,
        });
      }
    }
    roleTyResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setRoleTypeList(roleTyResult);

    const langResult = [];
    const langmap = new Map();
    for (const item of list) {
      if (!langmap.has(item.lang)) {
        langmap.set(item.lang, true);
        langResult.push({
          name: item.lang != "" ? item.lang : "Blank",
          count: list.filter(function (val) {
            return val.lang === item.lang;
          }).length,
        });
      }
    }
    langResult.sort(function (a, b) {
      return b.count - a.count;
    });
    setLangList(langResult);
  }, [list]);

  //const [filterCategoryText, setFilterCategoryText] = useState('0')
  //const [flagFilterId, setFlagFilterId] = useState('0')
  // const [flagFilterColor, setFlagFilterColor] = useState('0')
  //const [rcFilterId, setRcFilterId] = useState('0')
  //const [rcFilterImg, setRcFilterImg] = useState('0')

  const [filter, setFilter] = useState({});
  const [filterTags, setFilterTags] = useState([]);

  function filterBy(list, criteria) {
    return list.filter((candidate) =>
      Object.keys(criteria).every((key) => candidate[key] === criteria[key])
    );
  }

  const selectFilter = (e) => {
    const type = e.target.dataset.type;
    const value = e.target.dataset.value;

    let i;
    switch (type) {
      case "newprofilestatus":
        setIsProfileStatus(true);
        setProfileStatusText(value);
        setFilter({ ...filter, [type]: value });

        break;
      case "caseStatus":
        // if (value == "Promotion") {
        //     setIsCaseStatusFilter(true)
        //     setCaseStatusFilterText(value)
        //     setFilter({ ...filter, [type]: "On Hold" })
        // } else {
        //     setFilter("")
        //     console.log("2-" + value)
        //     setIsCaseStatusFilter(true)
        //     setCaseStatusFilterText(value)
        //     setFilter({ ...filter, [type]: value })
        // }

        setIsCaseStatusFilter(true);
        setCaseStatusFilterText(value);
        setFilter({ ...filter, [type]: value });

        // i = filterTags.findIndex(function (x) { return x.type === 'caseStatus' })
        // if (i === -1) {
        //     if (value === "Promotion") {
        //         filterTags.push({ type, newValue })
        //         //newValue = "On Hold"
        //     } else {

        //         filterTags.push({ type, value })
        //     }
        // } else {
        //     filterTags.splice(i, 1);
        //     filterTags.push({ type, value })
        // }
        break;

      case "year":
        if (value == "") {
          setYearFilterText("Blank");
        } else {
          setYearFilterText(value);
        }
        setIsYearFilter(true);
        setFilter({ ...filter, ["year"]: Number(e.target.dataset.value) });
        break;

      case "month":
        if (value == "") {
          setMonthsFilterText("Blank");
        } else {
          setMonthsFilterText(value);
        }
        setIsMonthsFilter(true);
        setFilter({ ...filter, ["month"]: e.target.dataset.value });
        break;

      case "category":
        if (value == "") {
          setCategoryFilterText("Blank");
        } else {
          setCategoryFilterText(value);
        }
        setIsCategoryFilter(true);
        setFilter({ ...filter, ["category"]: e.target.dataset.value });
        break;

      case "status":
        //console.log(value)
        // if (value == "New CV") {
        //     setIsPipelineFilter(true)
        //     setPipelineFilterText("New Case")
        //     setFilter({ ...filter, [type]: value })
        // } else {
        //     setIsPipelineFilter(true)
        //     setPipelineFilterText(value)
        //     setFilter("")
        //     setFilter({ ...filter, [type]: value })
        // }

        if (value == "Case Query") {
          setIsPipelineFilter(true);
          setPipelineFilterText("Qualifying");
          setFilter({ ...filter, [type]: value });
        } else if (value == "Selected") {
          setIsPipelineFilter(true);
          setPipelineFilterText("Selected");
          setFilter({ ...filter, [type]: value });
        } else if (value == "To Progress") {
          setIsPipelineFilter(true);
          setPipelineFilterText("RC To Do");
          setFilter({ ...filter, [type]: value });
        } else if (value == "In Progress") {
          setIsPipelineFilter(true);
          setPipelineFilterText("RC In Progress");
          setFilter({ ...filter, [type]: value });
        } else if (value == "Completed") {
          setIsPipelineFilter(true);
          setPipelineFilterText("RC Completed");
          setFilter({ ...filter, [type]: value });
        } else if (value == "Sendout") {
          setIsPipelineFilter(true);
          setPipelineFilterText("AM Sendout");
          setFilter({ ...filter, [type]: value });
        } else if (value == "Job Offers") {
          setIsPipelineFilter(true);
          setPipelineFilterText("Job Offers");
          setFilter({ ...filter, [type]: value });
        } else {
          setIsPipelineFilter(true);
          setPipelineFilterText(e.target.dataset.value);
          setFilter({ ...filter, [type]: value });
        }
        // setFilter({ ...filter, ['status']: e.target.dataset.value })
        break;

      case "gender":
        setIsGenderFilter(true);
        setGenderFilterText(e.target.dataset.value);
        setFilter({ ...filter, ["gender"]: e.target.dataset.value });
        break;

      case "flag_type":
        setIsFlagFilter(true);
        setFlagFilterText(e.target.dataset.text);
        setFilter({ ...filter, ["flag_type"]: Number(e.target.dataset.value) });
        break;

      case "profileStatus":
        setIsApprovelFilter(true);
        setApprovelFilterText(e.target.dataset.name);
        setFilter({
          ...filter,
          ["profileStatus"]: Number(e.target.dataset.value),
        });
        break;

      case "profileIndicator":
        setIsProfileIndicatorFilter(true);
        setProfileIndicatorText(e.target.dataset.text);
        setFilter({
          ...filter,
          ["profileIndicator"]: Number(e.target.dataset.value),
        });
        break;

      case "motivationStatus":
        setIsCanMotivationStatusFilter(true);
        setCanMotivationStatusText(e.target.dataset.text);
        setFilter({
          ...filter,
          ["motivationStatus"]: Number(e.target.dataset.value),
        });
        break;

      case "amId":
        setIsAccountManagerFilter(true);
        setAccountManagerFilterText(e.target.dataset.name);
        setFilter({ ...filter, ["amId"]: Number(e.target.dataset.value) });
        break;

      case "raId":
        setIsRecruiterAdminFilter(true);
        setRecruiterAdminFilterText(e.target.dataset.name);
        setFilter({ ...filter, ["raId"]: Number(e.target.dataset.value) });
        break;

      case "rcId":
        dispatch(
          pipelineSummaryRcFilter({
            rec_id: e.target.dataset.value,
            RecValue: e.target.dataset.recval,
            RecName: e.target.dataset.name,
          })
        );
        setIsRecruiterFilter(true);
        setRecruiterFilterText(e.target.dataset.name);
        setFilter({ ...filter, ["rcId"]: Number(e.target.dataset.value) });
        dispatch(summaryFilter({ rec_id: Number(e.target.dataset.value) }));
        break;

      case "company":
        setIsCompanyFilter(true);
        setCompanyFilterText(e.target.dataset.value);
        setFilter({ ...filter, ["company"]: e.target.dataset.value });
        break;

      case "case_progress":
        setIsCaseProgressFilter(true);
        setCaseProgressFilterText(e.target.dataset.value);
        setFilter({ ...filter, ["case_progress"]: e.target.dataset.value });
        break;

      case "industries":
        setIsIndustriesFilter(true);
        setIsIndustriesFilterText(e.target.dataset.value);
        setFilter({ ...filter, ["industries"]: e.target.dataset.value });
        break;

      case "visaStatus":
        setIsVisaFilter(true);
        setIsVisaFilterText(e.target.dataset.value);
        setFilter({ ...filter, ["visaStatus"]: e.target.dataset.value });
        break;

      case "c_country":
        setIsCountryFilter(true);
        setIsCountryFilterText(e.target.dataset.value);
        setFilter({ ...filter, ["c_country"]: e.target.dataset.value });
        break;

      case "nationality":
        setIsNationalityFilter(true);
        setIsNationalityFilterText(e.target.dataset.value);
        setFilter({ ...filter, ["nationality"]: e.target.dataset.value });
        break;

      case "c_city":
        setIsCityFilter(true);
        setIsCityFilterText(e.target.dataset.value);
        setFilter({ ...filter, ["c_city"]: e.target.dataset.value });
        break;

      case "native1":
        setIsNative1Filter(true);
        setIsNative1FilterText(e.target.dataset.value);
        setFilter({ ...filter, ["native1"]: e.target.dataset.value });
        break;

      case "native2":
        setIsNative2Filter(true);
        setIsNative2FilterText(e.target.dataset.value);
        setFilter({ ...filter, ["native2"]: e.target.dataset.value });
        break;

      case "case_stage":
        setIsCaseStageFilter(true);
        setCaseStageFilterText(e.target.dataset.value);
        setFilter({ ...filter, ["case_stage"]: e.target.dataset.value });
        break;
      case "cv_source":
        setIsCvSourceFilter(true);
        setCvSourceFilterText(e.target.dataset.value);
        setFilter({ ...filter, ["cv_source"]: e.target.dataset.value });
        break;

      case "lang":
        setIsLanguageFilter(true);
        setLanguageFilterText(e.target.dataset.value);
        setFilter({ ...filter, ["lang"]: e.target.dataset.value });
        break;

      case "location":
        setIsLocationFilter(true);
        setLocationText(e.target.dataset.value);
        setFilter({ ...filter, ["location"]: e.target.dataset.value });
        break;
      case "role":
        setIsRoleTypeFilter(true);
        setRoleTypeFilterText(e.target.dataset.value);
        setFilter({ ...filter, ["role"]: e.target.dataset.value });
        break;
      case "project":
        setIsProjectFilter(true);
        setProjectText(e.target.dataset.value);
        setFilter({ ...filter, ["project"]: e.target.dataset.value });
        break;

      default:
        break;
    }
  };

  const removeTag = async (e) => {
    const type = e.target.dataset.type;
    delete filter[type];

    switch (type) {
      case "newprofilestatus":
        setIsProfileStatus(false);
        setProfileStatusText("");
        break;
      case "caseStatus":
        setIsCaseStatusFilter(false);
        setCaseStatusFilterText("");
        break;

      case "status":
        setIsPipelineFilter(false);
        setPipelineFilterText("");
        break;

      case "year":
        setIsYearFilter(false);
        setYearFilterText("");
        break;

      case "month":
        setIsMonthsFilter(false);
        setMonthsFilterText("");
        break;

      case "gender":
        setIsGenderFilter(false);
        setGenderFilterText("");
        break;
      case "category":
        setIsCategoryFilter(false);
        setCategoryFilterText("");
        break;

      case "flag_type":
        setIsFlagFilter(false);
        setFlagFilterText("");
        break;

      case "profileStatus":
        setIsApprovelFilter(false);
        setApprovelFilterText("");
        break;

      case "profileIndicator":
        setIsProfileIndicatorFilter(false);
        setProfileIndicatorText("");
        break;

      case "motivationStatus":
        setIsCanMotivationStatusFilter(false);
        setCanMotivationStatusText("");
        break;

      case "amId":
        setIsAccountManagerFilter(false);
        setAccountManagerFilterText("");
        break;
      case "raId":
        setIsRecruiterAdminFilter(false);
        setRecruiterAdminFilterText("");
        break;

      case "rcId":
        dispatch(countAll());
        // dispatch(dbReadyList())
        // dispatch(recAdminList())
        dispatch(candidateSummaryList());
        setIsRecruiterFilter(false);
        dispatch(pipelineClearSummaryRcFilter());
        setRecruiterFilterText("");
        break;

      case "company":
        setIsCompanyFilter(false);
        setCompanyFilterText("");
        break;

      case "case_progress":
        setIsCaseProgressFilter(false);
        setCaseProgressFilterText("");
        break;

      case "industries":
        setIsIndustriesFilter(false);
        setIsIndustriesFilterText("");
        break;

      case "visaStatus":
        setIsVisaFilter(false);
        setIsVisaFilterText("");
        break;

      case "c_country":
        setIsCountryFilter(false);
        setIsCountryFilterText("");
        break;

      case "c_city":
        setIsCityFilter(false);
        setIsCityFilterText("");
        break;

      case "native1":
        setIsNative1Filter(false);
        setIsNative1FilterText("");
        break;

      case "native2":
        setIsNative2Filter(false);
        setIsNative2FilterText("");
        break;

      case "nationality":
        setIsNationalityFilter(false);
        setIsNationalityFilterText("");
        break;

      case "case_stage":
        setIsCaseStageFilter(false);
        setCaseStageFilterText("");
        break;
      case "cv_source":
        setIsCvSourceFilter(false);
        setCvSourceFilterText("");
        break;

      case "lang":
        setIsLanguageFilter(false);
        setLanguageFilterText("");
        break;
      case "location":
        setIsLocationFilter(false);
        setLocationText("");
        break;
      case "role":
        setIsRoleTypeFilter(false);
        setRoleTypeFilterText("");
        break;
      case "project":
        setIsProjectFilter(false);
        setProjectText("");
        break;

      default:
        break;
    }
    setFilter(filter);
    setList(filterBy(candidateDataSummaryList, filter));
    setFilterTags(filterTags.filter((item) => item.type !== type));
  };

  const isEmpty = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  useEffect(() => {
    if (isEmpty(filter)) {
      setTimeout(() => {
        setList(candidateDataSummaryList);
      }, 500);
    } else {
      setList(filterBy(list, filter));
    }
  }, [filter]);
  //}, [filterCategoryText, flagFilterId, rcFilterId, filter])

  return (
    <>
      <div className="container-fluid dk-candidatesuMMPageCon">
        <div className="row">
          <div className="dk-candSummBoxContainer">
            <div className="row">
              <div className="col-md-12 mt-2">
                <div className="dk-candtopLeftBoxCont">
                  <div className="dk-topLeftboxesCols">
                    <div
                      className={`${
                        !isYearFilter ? "dk-toptitle" : "dk-toptitle-filterBg"
                      }`}
                    >
                      {!isYearFilter ? (
                        <div className="dk-colstitle">Years</div>
                      ) : (
                        <div className="dk-dbsuMM-filterHeader">
                          {yearFilterText == "None" ? "Blank" : yearFilterText}
                          <span>
                            <i
                              className="fa fa-times-circle  ml-2"
                              data-type="year"
                              onClick={removeTag}
                            ></i>
                          </span>
                        </div>
                      )}
                      <span className="dk-totalCvCount">{list.length}</span>
                    </div>
                    <ul style={{ maxHeight: "146px", overflowY: "auto" }}>
                      <li
                        data-type="year"
                        data-value="2025"
                        onClick={selectFilter}
                      >
                        <span
                          className="leftItem d-flex align-items-center"
                          data-type="year"
                          data-value="2025"
                          onClick={selectFilter}
                        >
                          2025
                        </span>
                        <span
                          className="rightItem"
                          data-type="year"
                          data-value="2025"
                          onClick={selectFilter}
                        >
                          {
                            list.filter(function (val) {
                              return val.year == 2025;
                            }).length
                          }
                        </span>
                      </li>
                      <li
                        data-type="year"
                        data-value="2024"
                        onClick={selectFilter}
                      >
                        <span
                          className="leftItem d-flex align-items-center"
                          data-type="year"
                          data-value="2024"
                          onClick={selectFilter}
                        >
                          2024
                        </span>
                        <span
                          className="rightItem"
                          data-type="year"
                          data-value="2024"
                          onClick={selectFilter}
                        >
                          {
                            list.filter(function (val) {
                              return val.year == 2024;
                            }).length
                          }
                        </span>
                      </li>
                      <li
                        data-type="year"
                        data-value="2023"
                        onClick={selectFilter}
                      >
                        <span
                          className="leftItem d-flex align-items-center"
                          data-type="year"
                          data-value="2023"
                          onClick={selectFilter}
                        >
                          2023
                        </span>
                        <span
                          className="rightItem"
                          data-type="year"
                          data-value="2023"
                          onClick={selectFilter}
                        >
                          {
                            list.filter(function (val) {
                              return val.year == 2023;
                            }).length
                          }
                        </span>
                      </li>
                      <li
                        data-type="year"
                        data-value="2022"
                        onClick={selectFilter}
                      >
                        <span
                          className="leftItem d-flex align-items-center"
                          data-type="year"
                          data-value="2022"
                          onClick={selectFilter}
                        >
                          2022
                        </span>
                        <span
                          className="rightItem"
                          data-type="year"
                          data-value="2022"
                          onClick={selectFilter}
                        >
                          {
                            list.filter(function (val) {
                              return val.year === 2022;
                            }).length
                          }
                        </span>
                      </li>
                      <li
                        data-type="year"
                        data-value="2021"
                        onClick={selectFilter}
                      >
                        <span
                          className="leftItem d-flex align-items-center"
                          data-type="year"
                          data-value="2021"
                          onClick={selectFilter}
                        >
                          2021
                        </span>
                        <span
                          className="rightItem"
                          data-type="year"
                          data-value="2021"
                          onClick={selectFilter}
                        >
                          {
                            list.filter(function (val) {
                              return val.year === 2021;
                            }).length
                          }
                        </span>
                      </li>
                      <li
                        data-type="year"
                        data-value="2020"
                        onClick={selectFilter}
                      >
                        <span
                          className="leftItem d-flex align-items-center"
                          data-type="year"
                          data-value="2020"
                          onClick={selectFilter}
                        >
                          2020
                        </span>
                        <span
                          className="rightItem"
                          data-type="year"
                          data-value="2020"
                          onClick={selectFilter}
                        >
                          {
                            list.filter(function (val) {
                              return val.year === 2020;
                            }).length
                          }
                        </span>
                      </li>
                      <li
                        data-type="year"
                        data-value="2019"
                        onClick={selectFilter}
                      >
                        <span
                          className="leftItem d-flex align-items-center"
                          data-type="year"
                          data-value="2019"
                          onClick={selectFilter}
                        >
                          2019
                        </span>
                        <span
                          className="rightItem"
                          data-type="year"
                          data-value="2019"
                          onClick={selectFilter}
                        >
                          {
                            list.filter(function (val) {
                              return val.year === 2019;
                            }).length
                          }
                        </span>
                      </li>
                      <li
                        data-type="year"
                        data-value="2018"
                        onClick={selectFilter}
                      >
                        <span
                          className="leftItem d-flex align-items-center"
                          data-type="year"
                          data-value="2018"
                          onClick={selectFilter}
                        >
                          2018
                        </span>
                        <span
                          className="rightItem"
                          data-type="year"
                          data-value="2018"
                          onClick={selectFilter}
                        >
                          {
                            list.filter(function (val) {
                              return val.year === 2018;
                            }).length
                          }
                        </span>
                      </li>
                      <li
                        data-type="year"
                        data-value="2018"
                        onClick={selectFilter}
                      >
                        <span
                          className="leftItem d-flex align-items-center"
                          data-type="year"
                          data-value="2018"
                          onClick={selectFilter}
                        >
                          2018
                        </span>
                        <span
                          className="rightItem"
                          data-type="year"
                          data-value="2018"
                          onClick={selectFilter}
                        >
                          {
                            list.filter(function (val) {
                              return val.year === 2017;
                            }).length
                          }
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="dk-topLeftboxesCols">
                    <div
                      className={`${
                        !isRecruiterAdminFilter
                          ? "dk-toptitle"
                          : "dk-toptitle-filterBg"
                      }`}
                    >
                      {!isRecruiterAdminFilter ? (
                        <div className="dk-colstitle">RR</div>
                      ) : (
                        <div className="dk-dbsuMM-filterHeader">
                          {recruiterAdminFilterText}
                          <span>
                            <i
                              className="fa fa-times-circle  ml-2"
                              data-type="raId"
                              onClick={removeTag}
                            ></i>
                          </span>
                        </div>
                      )}
                      <span className="dk-totalCvCount">{list.length}</span>
                    </div>
                    <ul className="dk-bottomLeftLists dkg-recruiter-admin-ul">
                      {raUserList.map((item, index) => (
                        <li
                          key={`rcFilter${index}`}
                          data-value={item.id}
                          data-name={item.name}
                          data-img={item.userImg}
                          data-type="raId"
                          onClick={selectFilter}
                          data-recval={
                            list.filter(function (val) {
                              return val.raId === item.id;
                            }).length
                          }
                        >
                          <span
                            className="recuriterItem d-flex align-items-center"
                            data-value={item.id}
                            data-name={item.name}
                            data-img={item.userImg}
                            data-type="raId"
                            onClick={selectFilter}
                            data-recval={
                              list.filter(function (val) {
                                return val.raId === item.id;
                              }).length
                            }
                          >
                            <img
                              src={
                                process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                                item.userImg
                              }
                              alt={item.name}
                              className="recruitersImg"
                              data-value={item.id}
                              data-name={item.name}
                              data-img={item.userImg}
                              data-type="raId"
                              onClick={selectFilter}
                              data-recval={
                                list.filter(function (val) {
                                  return val.raId === item.id;
                                }).length
                              }
                            />
                            {item.name}
                          </span>
                          <span
                            className="rightItem"
                            data-value={item.id}
                            data-name={item.name}
                            data-img={item.userImg}
                            data-type="raId"
                            data-recval={
                              list.filter(function (val) {
                                return val.raId === item.id;
                              }).length
                            }
                          >
                            {
                              list.filter(function (val) {
                                return val.raId === item.id;
                              }).length
                            }
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="dk-topLeftboxesCols">
                    <div
                      className={`${
                        !isRecruiterFilter
                          ? "dk-toptitle"
                          : "dk-toptitle-filterBg"
                      }`}
                    >
                      {!isRecruiterFilter ? (
                        <div className="dk-colstitle">RC</div>
                      ) : (
                        <div className="dk-dbsuMM-filterHeader">
                          {recruiterFilterText}
                          <span>
                            <i
                              className="fa fa-times-circle  ml-2"
                              data-type="rcId"
                              onClick={removeTag}
                            ></i>
                          </span>
                        </div>
                      )}
                      <span className="dk-totalCvCount">{list.length}</span>
                    </div>
                    <ul className="dk-bottomLeftLists dkg-recruiter-admin-ul">
                      {rcUserList.map((item, index) => (
                        <li
                          key={`rcFilter${index}`}
                          data-value={item.id}
                          data-name={item.name}
                          data-img={item.userImg}
                          data-type="rcId"
                          onClick={selectFilter}
                          data-recval={
                            list.filter(function (val) {
                              return val.rcId === item.id;
                            }).length
                          }
                        >
                          <span
                            className="recuriterItem d-flex align-items-center"
                            data-value={item.id}
                            data-name={item.name}
                            data-img={item.userImg}
                            data-type="rcId"
                            onClick={selectFilter}
                            data-recval={
                              list.filter(function (val) {
                                return val.rcId === item.id;
                              }).length
                            }
                          >
                            <img
                              src={
                                process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                                item.userImg
                              }
                              alt={item.name}
                              className="recruitersImg"
                              data-value={item.id}
                              data-name={item.name}
                              data-img={item.userImg}
                              data-type="rcId"
                              onClick={selectFilter}
                              data-recval={
                                list.filter(function (val) {
                                  return val.rcId === item.id;
                                }).length
                              }
                            />
                            {item.name}
                          </span>
                          <span
                            className="rightItem"
                            data-value={item.id}
                            data-name={item.name}
                            data-img={item.userImg}
                            data-type="rcId"
                            data-recval={
                              list.filter(function (val) {
                                return val.rcId === item.id;
                              }).length
                            }
                          >
                            {
                              list.filter(function (val) {
                                return val.rcId === item.id;
                              }).length
                            }
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="dk-topLeftboxesCols">
                    <div
                      className={`${
                        !isAccountManagerFilter
                          ? "dk-toptitle"
                          : "dk-toptitle-filterBg"
                      }`}
                    >
                      {!isAccountManagerFilter ? (
                        <div className="dk-colstitle">AM</div>
                      ) : (
                        <div className="dk-dbsuMM-filterHeader">
                          {accountManagerFilterText}
                          <span>
                            <i
                              className="fa fa-times-circle  ml-2"
                              data-type="amId"
                              onClick={removeTag}
                            ></i>
                          </span>
                        </div>
                      )}
                      <span className="dk-totalCvCount">{list.length}</span>
                    </div>
                    <ul className="dk-bottomLeftLists dkg-recruiter-admin-ul">
                      {amUserList.map((item, index) => (
                        <li
                          key={`amFilter${index}`}
                          data-value={item.id}
                          data-name={item.name}
                          data-img={item.userImg}
                          data-type="amId"
                          onClick={selectFilter}
                        >
                          <span
                            className="recuriterItem d-flex align-items-center"
                            data-value={item.id}
                            data-name={item.name}
                            data-img={item.userImg}
                            data-type="amId"
                            onClick={selectFilter}
                          >
                            <img
                              src={
                                process.env.REACT_APP_IMG_CLOUD_BASE_URL +
                                item.userImg
                              }
                              alt={item.name}
                              className="recruitersImg"
                              data-value={item.id}
                              data-name={item.name}
                              data-img={item.userImg}
                              data-type="amId"
                              onClick={selectFilter}
                            />
                            {item.name}
                          </span>
                          <span
                            className="rightItem"
                            data-value={item.id}
                            data-name={item.name}
                            data-img={item.userImg}
                            data-type="amId"
                            onClick={selectFilter}
                          >
                            {
                              list.filter(function (val) {
                                return val.amId === item.id;
                              }).length
                            }
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="dk-topLeftboxesCols">
                    <div
                      className={`${
                        !isGenderFilter ? "dk-toptitle" : "dk-toptitle-filterBg"
                      }`}
                    >
                      {!isGenderFilter ? (
                        <div className="dk-colstitle">VERIFY EMAIL 1</div>
                      ) : (
                        <div className="dk-dbsuMM-filterHeader">
                          {genderFilterText}
                          <span>
                            <i
                              className="fa fa-times-circle  ml-2"
                              data-value="Male"
                              data-type="gender"
                              onClick={removeTag}
                            ></i>
                          </span>
                        </div>
                      )}
                      <span className="dk-totalCvCount">{list.length}</span>
                    </div>
                    <ul>
                      <li data-type="verifyemail-2" data-value="Verified">
                        <span
                          className="leftItem d-flex align-items-center"
                          data-type="verifyemail-2"
                          data-value="Verified"
                        >
                          <FaCheck
                            style={{
                              fontSize: "16px",
                              marginRight: "4px",
                              color: "#02a786",
                            }}
                          />
                          Verified
                        </span>
                        <span
                          className="rightItem"
                          data-type="verifyemail-2"
                          data-value="Verified"
                        >
                          1
                        </span>
                      </li>
                      <li data-type="verifyemail-2" data-value="non-verified">
                        <span
                          className="leftItem d-flex align-items-center"
                          data-type="verifyemail-2"
                          data-value="non-verified"
                        >
                          <FaTimes
                            style={{
                              fontSize: "16px",
                              marginRight: "4px",
                              color: "red",
                            }}
                          />
                          Non Verified
                        </span>
                        <span
                          className="rightItem"
                          data-type="verifyemail-2"
                          data-value="non-verified"
                        >
                          1
                        </span>
                      </li>
                      <li data-type="verifyemail-2" data-value="blank-value">
                        <span
                          className="leftItem d-flex align-items-center"
                          data-type="verifyemail-2"
                          data-value="blank-value"
                        >
                          <FaMinus
                            style={{
                              fontSize: "16px",
                              marginRight: "4px",
                              color: "#d3d3d3",
                            }}
                          />
                          Blank Value
                        </span>
                        <span
                          className="rightItem"
                          data-type="verifyemail-2"
                          data-value="blank-value"
                        >
                          1
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="dk-topLeftboxesCols">
                    <div
                      className={`${
                        !isGenderFilter ? "dk-toptitle" : "dk-toptitle-filterBg"
                      }`}
                    >
                      {!isGenderFilter ? (
                        <div className="dk-colstitle">VERIFY EMAIL 2</div>
                      ) : (
                        <div className="dk-dbsuMM-filterHeader">
                          {genderFilterText}
                          <span>
                            <i
                              className="fa fa-times-circle  ml-2"
                              data-value="Male"
                              data-type="gender"
                              onClick={removeTag}
                            ></i>
                          </span>
                        </div>
                      )}
                      <span className="dk-totalCvCount">{list.length}</span>
                    </div>
                    <ul>
                      <li data-type="verifyemail-2" data-value="Verified">
                        <span
                          className="leftItem d-flex align-items-center"
                          data-type="verifyemail-2"
                          data-value="Verified"
                        >
                          <FaCheck
                            style={{
                              fontSize: "16px",
                              marginRight: "4px",
                              color: "#02a786",
                            }}
                          />
                          Verified
                        </span>
                        <span
                          className="rightItem"
                          data-type="verifyemail-2"
                          data-value="Verified"
                        >
                          1
                        </span>
                      </li>
                      <li data-type="verifyemail-2" data-value="non-verified">
                        <span
                          className="leftItem d-flex align-items-center"
                          data-type="verifyemail-2"
                          data-value="non-verified"
                        >
                          <FaTimes
                            style={{
                              fontSize: "16px",
                              marginRight: "4px",
                              color: "red",
                            }}
                          />
                          Non Verified
                        </span>
                        <span
                          className="rightItem"
                          data-type="verifyemail-2"
                          data-value="non-verified"
                        >
                          1
                        </span>
                      </li>
                      <li data-type="verifyemail-2" data-value="blank-value">
                        <span
                          className="leftItem d-flex align-items-center"
                          data-type="verifyemail-2"
                          data-value="blank-value"
                        >
                          <FaMinus
                            style={{
                              fontSize: "16px",
                              marginRight: "4px",
                              color: "#d3d3d3",
                            }}
                          />
                          Blank Value
                        </span>
                        <span
                          className="rightItem"
                          data-type="verifyemail-2"
                          data-value="blank-value"
                        >
                          1
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="dk-candRightBoxCont dk-candbottomLeftBoxCont">
                  <div className="dk-bottomLeftboxesCols">
                    <div
                      className={`${
                        !isMonthsFilter ? "dk-toptitle" : "dk-toptitle-filterBg"
                      }`}
                    >
                      {!isMonthsFilter ? (
                        <div className="dk-colstitle">Months</div>
                      ) : (
                        <div className="dk-dbsuMM-filterHeader">
                          {monthsFilterText == "None"
                            ? "Blank"
                            : monthsFilterText}
                          <span>
                            <i
                              className="fa fa-times-circle  ml-2"
                              data-type="month"
                              onClick={removeTag}
                            ></i>
                          </span>
                        </div>
                      )}
                      <span className="dk-totalCvCount">{list.length}</span>
                    </div>
                    <ul className="dk-bottomLeftLists">
                      <li
                        data-type="month"
                        data-value="Jan"
                        onClick={selectFilter}
                      >
                        <span
                          className="leftItem d-flex align-items-center"
                          data-type="month"
                          data-value="Jan"
                          onClick={selectFilter}
                        >
                          Jan
                        </span>
                        <span
                          className="rightItem"
                          data-type="month"
                          data-value="Jan"
                          onClick={selectFilter}
                        >
                          {
                            list.filter(function (val) {
                              return val.month === "Jan";
                            }).length
                          }
                        </span>
                      </li>
                      <li
                        data-type="month"
                        data-value="Feb"
                        onClick={selectFilter}
                      >
                        <span
                          className="leftItem d-flex align-items-center"
                          data-type="month"
                          data-value="Feb"
                          onClick={selectFilter}
                        >
                          Feb
                        </span>
                        <span
                          className="rightItem"
                          data-type="month"
                          data-value="Feb"
                          onClick={selectFilter}
                        >
                          {
                            list.filter(function (val) {
                              return val.month === "Feb";
                            }).length
                          }
                        </span>
                      </li>
                      <li
                        data-type="month"
                        data-value="Mar"
                        onClick={selectFilter}
                      >
                        <span
                          className="leftItem d-flex align-items-center"
                          data-type="month"
                          data-value="Mar"
                          onClick={selectFilter}
                        >
                          March
                        </span>
                        <span
                          className="rightItem"
                          data-type="month"
                          data-value="Mar"
                          onClick={selectFilter}
                        >
                          {
                            list.filter(function (val) {
                              return val.month === "Mar";
                            }).length
                          }
                        </span>
                      </li>
                      <li
                        data-type="month"
                        data-value="Apr"
                        onClick={selectFilter}
                      >
                        <span
                          className="leftItem d-flex align-items-center"
                          data-type="month"
                          data-value="Apr"
                          onClick={selectFilter}
                        >
                          April
                        </span>
                        <span
                          className="rightItem"
                          data-type="month"
                          data-value="Apr"
                          onClick={selectFilter}
                        >
                          {
                            list.filter(function (val) {
                              return val.month === "Apr";
                            }).length
                          }
                        </span>
                      </li>
                      <li
                        data-type="month"
                        data-value="May"
                        onClick={selectFilter}
                      >
                        <span
                          className="leftItem d-flex align-items-center"
                          data-type="month"
                          data-value="May"
                          onClick={selectFilter}
                        >
                          May
                        </span>
                        <span
                          className="rightItem"
                          data-type="month"
                          data-value="May"
                          onClick={selectFilter}
                        >
                          {
                            list.filter(function (val) {
                              return val.month === "May";
                            }).length
                          }
                        </span>
                      </li>
                      <li
                        data-type="month"
                        data-value="Jun"
                        onClick={selectFilter}
                      >
                        <span
                          className="leftItem d-flex align-items-center"
                          data-type="month"
                          data-value="Jun"
                          onClick={selectFilter}
                        >
                          June
                        </span>
                        <span
                          className="rightItem"
                          data-type="month"
                          data-value="Jun"
                          onClick={selectFilter}
                        >
                          {
                            list.filter(function (val) {
                              return val.month === "Jun";
                            }).length
                          }
                        </span>
                      </li>
                      <li
                        data-type="month"
                        data-value="Jul"
                        onClick={selectFilter}
                      >
                        <span
                          className="leftItem d-flex align-items-center"
                          data-type="month"
                          data-value="Jul"
                          onClick={selectFilter}
                        >
                          July
                        </span>
                        <span
                          className="rightItem"
                          data-type="month"
                          data-value="Jul"
                          onClick={selectFilter}
                        >
                          {
                            list.filter(function (val) {
                              return val.month === "Jul";
                            }).length
                          }
                        </span>
                      </li>
                      <li
                        data-type="month"
                        data-value="Aug"
                        onClick={selectFilter}
                      >
                        <span
                          className="leftItem d-flex align-items-center"
                          data-type="month"
                          data-value="Aug"
                          onClick={selectFilter}
                        >
                          Aug
                        </span>
                        <span
                          className="rightItem"
                          data-type="month"
                          data-value="Aug"
                          onClick={selectFilter}
                        >
                          {
                            list.filter(function (val) {
                              return val.month === "Aug";
                            }).length
                          }
                        </span>
                      </li>
                      <li
                        data-type="month"
                        data-value="Sep"
                        onClick={selectFilter}
                      >
                        <span
                          className="leftItem d-flex align-items-center"
                          data-type="month"
                          data-value="Sep"
                          onClick={selectFilter}
                        >
                          Sep
                        </span>
                        <span
                          className="rightItem"
                          data-type="month"
                          data-value="Sep"
                          onClick={selectFilter}
                        >
                          {
                            list.filter(function (val) {
                              return val.month === "Sep";
                            }).length
                          }
                        </span>
                      </li>
                      <li
                        data-type="month"
                        data-value="Oct"
                        onClick={selectFilter}
                      >
                        <span
                          className="leftItem d-flex align-items-center"
                          data-type="month"
                          data-value="Oct"
                          onClick={selectFilter}
                        >
                          Oct
                        </span>
                        <span
                          className="rightItem"
                          data-type="month"
                          data-value="Oct"
                          onClick={selectFilter}
                        >
                          {
                            list.filter(function (val) {
                              return val.month === "Oct";
                            }).length
                          }
                        </span>
                      </li>
                      <li
                        data-type="month"
                        data-value="Nov"
                        onClick={selectFilter}
                      >
                        <span
                          className="leftItem d-flex align-items-center"
                          data-type="month"
                          data-value="Nov"
                          onClick={selectFilter}
                        >
                          Nov
                        </span>
                        <span
                          className="rightItem"
                          data-type="month"
                          data-value="Nov"
                          onClick={selectFilter}
                        >
                          {
                            list.filter(function (val) {
                              return val.month === "Nov";
                            }).length
                          }
                        </span>
                      </li>
                      <li
                        data-type="month"
                        data-value="Dec"
                        onClick={selectFilter}
                      >
                        <span
                          className="leftItem d-flex align-items-center"
                          data-type="month"
                          data-value="Dec"
                          onClick={selectFilter}
                        >
                          Dec
                        </span>
                        <span
                          className="rightItem"
                          data-type="month"
                          data-value="Dec"
                          onClick={selectFilter}
                        >
                          {
                            list.filter(function (val) {
                              return val.month === "Dec";
                            }).length
                          }
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="dk-bottomLeftboxesCols">
                    <div
                      className={`${
                        !isPipelineFilter
                          ? "dk-toptitle"
                          : "dk-toptitle-filterBg"
                      }`}
                    >
                      {!isPipelineFilter ? (
                        <div className="dk-colstitle">PIPELINES</div>
                      ) : (
                        <div className="dk-dbsuMM-filterHeader">
                          {pipelineFilterText == "New CV"
                            ? "New Case"
                            : pipelineFilterText}
                          <span>
                            <i
                              className="fa fa-times-circle  ml-2"
                              data-type="status"
                              onClick={removeTag}
                            ></i>
                          </span>
                        </div>
                      )}
                      <span className="dk-totalCvCount">{list.length}</span>
                    </div>
                    <ul className="dk-bottomLeftLists dk-case-status-bgColor23">
                      {statusList.filter(function (val) {
                        return val.name === "New CV";
                      }).length !== 0 ? (
                        <li
                          style={{
                            backgroundColor: "#5c737d",
                            color: "#fff",
                            borderBottom: "none",
                            height: "2.078rem",
                          }}
                          data-type="status"
                          data-value="New CV"
                          onClick={selectFilter}
                        >
                          <span
                            className="recuriterItem d-flex align-items-center"
                            data-type="status"
                            data-value="New CV"
                            onClick={selectFilter}
                          >
                            New Case
                          </span>
                          <span
                            className="caseStatusCounter"
                            data-type="status"
                            data-value="New CV"
                            onClick={selectFilter}
                          >
                            {statusList.length > 0 &&
                              statusList.map((data, index) => {
                                if (data.name === "New CV") {
                                  return data.count;
                                }
                              })}
                          </span>
                        </li>
                      ) : null}
                      {statusList.filter(function (val) {
                        return val.name === "Selected";
                      }).length !== 0 ? (
                        <li
                          style={{
                            backgroundColor: "#715f8f",
                            color: "#fff",
                            borderBottom: "none",
                            height: "2.078rem",
                          }}
                          data-type="status"
                          data-value="Selected"
                          onClick={selectFilter}
                        >
                          <span
                            className="recuriterItem d-flex align-items-center"
                            data-type="status"
                            data-value="Selected"
                            onClick={selectFilter}
                          >
                            Selected
                          </span>
                          <span
                            className="caseStatusCounter"
                            data-type="status"
                            data-value="Selected"
                            onClick={selectFilter}
                          >
                            {statusList.length > 0 &&
                              statusList.map((data, index) => {
                                if (data.name === "Selected") {
                                  return data.count;
                                }
                              })}
                          </span>
                        </li>
                      ) : null}

                      {statusList.filter(function (val) {
                        return val.name === "To Progress";
                      }).length !== 0 ? (
                        <li
                          style={{
                            backgroundColor: "#57496d",
                            color: "#fff",
                            borderBottom: "none",
                            height: "2.078rem",
                          }}
                          data-type="status"
                          data-value="To Progress"
                          onClick={selectFilter}
                        >
                          <span
                            className="recuriterItem d-flex align-items-center"
                            data-type="status"
                            data-value="To Progress"
                            onClick={selectFilter}
                          >
                            Qualifying
                          </span>
                          <span
                            className="caseStatusCounter"
                            data-type="status"
                            data-value="To Progress"
                            onClick={selectFilter}
                          >
                            {statusList.length > 0 &&
                              statusList.map((data, index) => {
                                if (data.name === "To Progress") {
                                  return data.count;
                                }
                              })}
                          </span>
                        </li>
                      ) : null}
                      {statusList.filter(function (val) {
                        return val.name === "In Progress";
                      }).length !== 0 ? (
                        <li
                          style={{
                            backgroundColor: "#02A786",
                            color: "#fff",
                            borderBottom: "none",
                            height: "2.078rem",
                          }}
                          data-type="status"
                          data-value="In Progress"
                          onClick={selectFilter}
                        >
                          <span
                            className="recuriterItem d-flex align-items-center"
                            data-type="status"
                            data-value="In Progress"
                            onClick={selectFilter}
                          >
                            Sendouts
                          </span>
                          <span
                            className="caseStatusCounter"
                            data-type="status"
                            data-value="In Progress"
                            onClick={selectFilter}
                          >
                            {statusList.length > 0 &&
                              statusList.map((data, index) => {
                                if (data.name === "In Progress") {
                                  return data.count;
                                }
                              })}
                          </span>
                        </li>
                      ) : null}

                      {statusList.filter(function (val) {
                        return val.name === "Sendout";
                      }).length !== 0 ? (
                        <li
                          style={{
                            backgroundColor: "#316a67",
                            color: "#fff",
                            borderBottom: "none",
                            height: "2.078rem",
                          }}
                          data-type="status"
                          data-value="Sendout"
                          onClick={selectFilter}
                        >
                          <span
                            className="recuriterItem d-flex align-items-center"
                            data-type="status"
                            data-value="Sendout"
                            onClick={selectFilter}
                          >
                            Interviews
                          </span>
                          <span
                            className="caseStatusCounter"
                            data-type="status"
                            data-value="Sendout"
                            onClick={selectFilter}
                          >
                            {statusList.length > 0 &&
                              statusList.map((data, index) => {
                                if (data.name === "Sendout") {
                                  return data.count;
                                }
                              })}
                          </span>
                        </li>
                      ) : null}

                      {statusList.filter(function (val) {
                        return val.name === "Job Offer";
                      }).length !== 0 ? (
                        <li
                          style={{
                            backgroundColor: "#629bd3",
                            color: "#fff",
                            borderBottom: "none",
                            height: "2.078rem",
                          }}
                          data-type="status"
                          data-value="Job Offers"
                          onClick={selectFilter}
                        >
                          <span
                            className="recuriterItem d-flex align-items-center"
                            data-type="status"
                            data-value="Job Offers"
                            onClick={selectFilter}
                          >
                            Job Offers
                          </span>
                          <span
                            className="caseStatusCounter"
                            data-type="status"
                            data-value="Job Offers"
                            onClick={selectFilter}
                          >
                            {statusList.length > 0 &&
                              statusList.map((data, index) => {
                                if (data.name === "Job Offer") {
                                  return data.count;
                                }
                              })}
                          </span>
                        </li>
                      ) : (
                        ""
                      )}

                      {/* {
                                                (statusList.filter(function (val) {
                                                    return val.name === 'Promotion';
                                                }).length !== 0) ? <li style={{ backgroundColor: "#57496d", color: "#fff", borderBottom: "none", height: "2.078rem" }} data-type="status" data-value="Promotion" onClick={selectFilter}>
                                                    <span className="recuriterItem d-flex align-items-center" data-type="status" data-value="Promotion" onClick={selectFilter}>
                                                        Promotion
                                                    </span>
                                                    <span className="caseStatusCounter" data-type="status" data-value="Promotion" onClick={selectFilter}>
                                                        {
                                                            statusList.length > 0 && statusList.map((data, index) => {
                                                                if (data.name === "Promotion") {
                                                                    return data.count;
                                                                }
                                                            })
                                                        }
                                                    </span>
                                                </li> : null
                                            } */}

                      {/* {
                                                (statusList.filter(function (val) {
                                                    return val.name === 'Joined';
                                                }).length !== 0) ? <li style={{ backgroundColor: "#4768b0", color: "#fff", borderBottom: "none", height: "2.078rem" }} data-type="status" data-value="Joined" onClick={selectFilter}>
                                                    <span className="recuriterItem d-flex align-items-center" data-type="status" data-value="Joined" onClick={selectFilter}>
                                                        Joined
                                                    </span>
                                                    <span className="caseStatusCounter" data-type="status" data-value="Joined" onClick={selectFilter}>
                                                        {
                                                            statusList.length > 0 && statusList.map((data, index) => {
                                                                if (data.name === "Joined") {
                                                                    return data.count;
                                                                }
                                                            })
                                                        }
                                                    </span>
                                                </li> : null
                                            } */}
                      {/* {
                                                (statusList.filter(function (val) {
                                                    return val.name === 'Refused';
                                                }).length !== 0) ?
                                                    <li style={{ backgroundColor: "#a84d4d", color: "#fff", borderBottom: "none", height: "2.078rem" }} data-type="status" data-value="Refused" onClick={selectFilter}>
                                                        <span className="recuriterItem d-flex align-items-center" data-type="status" data-value="Refused" onClick={selectFilter}>
                                                            Refused
                                                        </span>
                                                        <span className="caseStatusCounter" data-type="status" data-value="Refused" onClick={selectFilter}>
                                                            {
                                                                statusList.length > 0 && statusList.map((data, index) => {
                                                                    if (data.name === "Refused") {
                                                                        return data.count;
                                                                    }
                                                                })
                                                            }
                                                        </span>
                                                    </li> : null
                                            } */}
                      {/* {
                                                (statusList.filter(function (val) {
                                                    return val.name === 'Credit';
                                                }).length !== 0) ? <li style={{ backgroundColor: "#d9714e", color: "#fff", borderBottom: "none", height: "2.078rem" }} data-type="status" data-value="Credit" onClick={selectFilter}>
                                                    <span className="recuriterItem d-flex align-items-center" data-type="status" data-value="Credit" onClick={selectFilter}>
                                                        Credit
                                                    </span>
                                                    <span className="caseStatusCounter" data-type="status" data-value="Credit" onClick={selectFilter}>
                                                        {
                                                            statusList.length > 0 && statusList.map((data, index) => {
                                                                if (data.name === "Credit") {
                                                                    return data.count;
                                                                }
                                                            })
                                                        }
                                                    </span>
                                                </li>
                                                    : null

                                            } */}
                      {/* {
                                                (statusList.filter(function (val) {
                                                    return val.name === 'Invoice';
                                                }).length !== 0) ? <li style={{ backgroundColor: "#39485d", color: "#fff", borderBottom: "none", height: "2.078rem" }} data-type="status" data-value="Invoice" onClick={selectFilter}>
                                                    <span className="recuriterItem d-flex align-items-center" data-type="status" data-value="Invoice" onClick={selectFilter}>
                                                        Invoice
                                                    </span>
                                                    <span className="caseStatusCounter" data-type="status" data-value="Invoice" onClick={selectFilter}>
                                                        {
                                                            statusList.length > 0 && statusList.map((data, index) => {
                                                                if (data.name === "Invoice") {
                                                                    return data.count;
                                                                }
                                                            })
                                                        }
                                                    </span>
                                                </li> : null
                                            } */}
                      {statusList.filter(function (val) {
                        return val.name === "Case Query";
                      }).length !== 0 ? (
                        <li
                          style={{
                            backgroundColor: "#d58b5d",
                            color: "#fff",
                            borderBottom: "none",
                            height: "2.078rem",
                          }}
                          data-type="status"
                          data-value="Case Query"
                          onClick={selectFilter}
                        >
                          <span
                            className="recuriterItem d-flex align-items-center"
                            data-type="status"
                            data-value="Case Query"
                            onClick={selectFilter}
                          >
                            In Progress
                          </span>
                          <span
                            className="caseStatusCounter"
                            data-type="status"
                            data-value="Case Query"
                            onClick={selectFilter}
                          >
                            {statusList.length > 0 &&
                              statusList.map((data, index) => {
                                if (data.name === "Case Query") {
                                  return data.count;
                                }
                              })}
                          </span>
                        </li>
                      ) : null}
                      {statusList.filter(function (val) {
                        return val.name === "Completed";
                      }).length !== 0 ? (
                        <li
                          style={{
                            backgroundColor: "#03a786",
                            color: "#fff",
                            borderBottom: "none",
                            height: "2.078rem",
                          }}
                          data-type="status"
                          data-value="Completed"
                          onClick={selectFilter}
                        >
                          <span
                            className="recuriterItem d-flex align-items-center"
                            data-type="status"
                            data-value="Completed"
                            onClick={selectFilter}
                          >
                            Completed
                          </span>
                          <span
                            className="caseStatusCounter"
                            data-type="status"
                            data-value="Completed"
                            onClick={selectFilter}
                          >
                            {statusList.length > 0 &&
                              statusList.map((data, index) => {
                                if (data.name === "Completed") {
                                  return data.count;
                                }
                              })}
                          </span>
                        </li>
                      ) : null}
                      {statusList.filter(function (val) {
                        return val.name === "Closed";
                      }).length !== 0 ? (
                        <li
                          style={{
                            backgroundColor: "#da3d5e",
                            color: "#fff",
                            borderBottom: "none",
                            height: "2.078rem",
                          }}
                          data-type="status"
                          data-value="Closed"
                          onClick={selectFilter}
                        >
                          <span
                            className="recuriterItem d-flex align-items-center"
                            data-type="status"
                            data-value="Closed"
                            onClick={selectFilter}
                          >
                            Closed
                          </span>
                          <span
                            className="caseStatusCounter"
                            data-type="status"
                            data-value="Closed"
                            onClick={selectFilter}
                          >
                            {statusList.length > 0 &&
                              statusList.map((data, index) => {
                                if (data.name === "Closed") {
                                  return data.count;
                                }
                              })}
                          </span>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                  <div className="dk-bottomLeftboxesCols">
                    <div
                      className={`${
                        !isProfileStatus
                          ? "dk-toptitle"
                          : "dk-toptitle-filterBg"
                      }`}
                    >
                      {!isProfileStatus ? (
                        <div className="dk-colstitle">Status</div>
                      ) : (
                        <div className="dk-dbsuMM-filterHeader">
                          {ProfileStatusText}
                          <span>
                            <i
                              className="fa fa-times-circle  ml-2"
                              data-type="newprofilestatus"
                              onClick={removeTag}
                            ></i>
                          </span>
                        </div>
                      )}
                      <span className="dk-totalCvCount">{list.length}</span>
                    </div>
                    <ul className="dk-bottomLeftLists dk-case-status-bgColor23">
                      {list.filter(function (val) {
                        return val.newprofilestatus === "Open";
                      }).length !== 0 ? (
                        <li
                          data-type="newprofilestatus"
                          style={{
                            backgroundColor: "#02a786",
                            color: "#fff",
                            borderBottom: "none",
                          }}
                          data-value="Open"
                          onClick={selectFilter}
                        >
                          <span
                            className="recuriterItem leftItem d-flex align-items-center"
                            data-type="newprofilestatus"
                            data-value="Open"
                            onClick={selectFilter}
                          >
                            Open
                          </span>
                          <span
                            className="caseStatusCounter rightItem"
                            data-type="newprofilestatus"
                            data-value="Open"
                            style={{ color: "#fff" }}
                            onClick={selectFilter}
                          >
                            {
                              list.filter(function (val) {
                                return val.newprofilestatus === "Open";
                              }).length
                            }
                          </span>
                        </li>
                      ) : null}
                      {/* {
                                                (list.filter(function (val) {
                                                    return val.newprofilestatus === 'On Hold';
                                                }).length !== 0) ?
                                                    <li data-type="newprofilestatus" style={{ backgroundColor: "#8b572a", color: '#fff', borderBottom: "none" }} data-value="On Hold" onClick={selectFilter} >
                                                        <span className="leftItem d-flex align-items-center" data-type="newprofilestatus" data-value="On Hold" onClick={selectFilter}>On Hold</span>
                                                        <span className="rightItem" data-type="newprofilestatus" data-value="On Hold" style={{ color: "#fff" }} onClick={selectFilter}>
                                                            {
                                                                list.filter(function (val) {
                                                                    return val.newprofilestatus === 'On Hold';
                                                                }).length
                                                            }
                                                        </span>
                                                    </li>
                                                    : null
                                            } */}
                      {list.filter(function (val) {
                        return val.newprofilestatus === "Closed";
                      }).length !== 0 ? (
                        <li
                          data-type="newprofilestatus"
                          style={{
                            backgroundColor: "#da3d5e",
                            color: "#fff",
                            borderBottom: "none",
                          }}
                          data-value="Closed"
                          onClick={selectFilter}
                        >
                          <span
                            className="recuriterItem leftItem d-flex align-items-center"
                            data-type="newprofilestatus"
                            data-value="Closed"
                            onClick={selectFilter}
                          >
                            Closed
                          </span>
                          <span
                            className="caseStatusCounter rightItem"
                            data-type="newprofilestatus"
                            data-value="Closed"
                            style={{ color: "#fff" }}
                            onClick={selectFilter}
                          >
                            {
                              list.filter(function (val) {
                                return val.newprofilestatus === "Closed";
                              }).length
                            }
                          </span>
                        </li>
                      ) : null}

                      {/* <li data-type="newprofilestatus" style={{ backgroundColor: "#8b572a", color: '#fff', borderBottom: "none" }}>
                                                <span className="leftItem d-flex align-items-center" >On Hold</span>
                                                <span className="rightItem" data-type="newprofilestatus" data-text="Updated" data-value="On Hold" style={{ color: "#fff" }}>12</span>
                                            </li> */}
                      {/* <li data-type="newprofilestatus" style={{ backgroundColor: "#da3d5e", color: '#fff', borderBottom: "none" }}>
                                                <span className="leftItem d-flex align-items-center" >Closed</span>
                                                <span className="rightItem" data-type="newprofilestatus" data-text="Updated" data-value="Closed" style={{ color: "#fff" }}>6</span>
                                            </li> */}
                    </ul>
                  </div>
                  <div className="dk-bottomLeftboxesCols">
                    <div
                      className={`${
                        !isCaseStatusFilter
                          ? "dk-toptitle"
                          : "dk-toptitle-filterBg"
                      }`}
                    >
                      {!isCaseStatusFilter ? (
                        <div className="dk-colstitle">STAGE</div>
                      ) : (
                        <div className="dk-dbsuMM-filterHeader">
                          {CaseStatusFilterText}
                          <span>
                            <i
                              className="fa fa-times-circle  ml-2"
                              data-type="caseStatus"
                              onClick={removeTag}
                            ></i>
                          </span>
                        </div>
                      )}
                      <span className="dk-totalCvCount">{list.length}</span>
                    </div>
                    <ul className="dk-bottomLeftLists dk-case-status-bgColor23">
                      {/* {
                                                (list.filter(function (val) {
                                                    return val.caseStatus === 'Closed - L1';
                                                }).length !== 0) ? <li style={{ backgroundColor: "#da3d5e", color: "#fff", borderBottom: "none" }} data-type="caseStatus" data-value="Closed - L1" onClick={selectFilter}>
                                                    <span className="recuriterItem d-flex align-items-center" data-type="caseStatus" data-value="Closed - L1" onClick={selectFilter}>
                                                        Closed - L1
                                                    </span>
                                                    <span className="caseStatusCounter" data-type="caseStatus" data-value="Closed - L1" onClick={selectFilter}>
                                                        {
                                                            list.filter(function (val) {
                                                                return val.caseStatus === 'Closed - L1';
                                                            }).length
                                                        }
                                                    </span>
                                                </li> : null
                                            } */}
                      {/* {
                                                (list.filter(function (val) {
                                                    return val.caseStatus === 'Closed - L2';
                                                }).length !== 0) ? <li style={{ backgroundColor: "#d0021b", color: "#fff", borderBottom: "none" }} data-type="caseStatus" data-value="Closed - L2" onClick={selectFilter}>
                                                    <span className="recuriterItem d-flex align-items-center" data-type="caseStatus" data-value="Closed - L2" onClick={selectFilter}>
                                                        Closed - L2
                                                    </span>
                                                    <span className="caseStatusCounter" data-type="caseStatus" data-value="Closed - L2" onClick={selectFilter}>
                                                        {
                                                            list.filter(function (val) {
                                                                return val.caseStatus === 'Closed - L2';
                                                            }).length
                                                        }
                                                    </span>
                                                </li> : null
                                            } */}
                      {list.filter(function (val) {
                        return val.caseStatus === "New Case";
                      }).length !== 0 ? (
                        <li
                          style={{
                            backgroundColor: "#5c737d",
                            color: "#fff",
                            borderBottom: "none",
                          }}
                          data-type="caseStatus"
                          data-value="New Case"
                          onClick={selectFilter}
                        >
                          <span
                            className="recuriterItem d-flex align-items-center"
                            data-type="caseStatus"
                            data-value="New Case"
                            onClick={selectFilter}
                          >
                            New Case
                          </span>
                          <span
                            className="caseStatusCounter"
                            data-type="caseStatus"
                            data-value="New Case"
                            onClick={selectFilter}
                          >
                            {
                              list.filter(function (val) {
                                return val.caseStatus === "New Case";
                              }).length
                            }
                          </span>
                        </li>
                      ) : null}
                      {list.filter(function (val) {
                        return val.caseStatus === "Selected";
                      }).length !== 0 ? (
                        <li
                          style={{
                            backgroundColor: "#715f8f",
                            color: "#fff",
                            borderBottom: "none",
                          }}
                          data-type="caseStatus"
                          data-value="Selected"
                          onClick={selectFilter}
                        >
                          <span
                            className="recuriterItem d-flex align-items-center"
                            data-type="caseStatus"
                            data-value="Selected"
                            onClick={selectFilter}
                          >
                            Selected
                          </span>
                          <span
                            className="caseStatusCounter"
                            data-type="caseStatus"
                            data-value="Selected"
                            onClick={selectFilter}
                          >
                            {
                              list.filter(function (val) {
                                return val.caseStatus === "Selected";
                              }).length
                            }
                          </span>
                        </li>
                      ) : null}
                      {
                        // (list.filter(function (val) {
                        //     return val.caseStatus === 'Screening';
                        // }).length !== 0) ? <li style={{ backgroundColor: "#715f8f", color: "#fff", borderBottom: "none" }} data-type="caseStatus" data-value="Screening" onClick={selectFilter}>
                        //     <span className="recuriterItem d-flex align-items-center" data-type="caseStatus" data-value="Screening" onClick={selectFilter}>
                        //         Screening
                        //     </span>
                        //     <span className="caseStatusCounter" data-type="caseStatus" data-value="Screening" onClick={selectFilter}>
                        //         {
                        //             list.filter(function (val) {
                        //                 return val.caseStatus === 'Screening';
                        //             }).length
                        //         }
                        //     </span>
                        // </li> : null
                      }

                      {list.filter(function (val) {
                        return val.caseStatus === "Qualifying";
                      }).length !== 0 ? (
                        <li
                          style={{
                            backgroundColor: "#57496d",
                            color: "#fff",
                            borderBottom: "none",
                          }}
                          data-type="caseStatus"
                          data-value="Qualifying"
                          onClick={selectFilter}
                        >
                          <span
                            className="recuriterItem d-flex align-items-center"
                            data-type="caseStatus"
                            data-value="Qualifying"
                            onClick={selectFilter}
                          >
                            Qualifying
                          </span>
                          <span
                            className="caseStatusCounter"
                            data-type="caseStatus"
                            data-value="Qualifying"
                            onClick={selectFilter}
                          >
                            {
                              list.filter(function (val) {
                                return val.caseStatus === "Qualifying";
                              }).length
                            }
                          </span>
                        </li>
                      ) : null}

                      {list.filter(function (val) {
                        return val.caseStatus === "Sendout";
                      }).length !== 0 ? (
                        <li
                          style={{
                            backgroundColor: "#02a786",
                            color: "#fff",
                            borderBottom: "none",
                          }}
                          data-type="caseStatus"
                          data-value="Sendout"
                          onClick={selectFilter}
                        >
                          <span
                            className="recuriterItem d-flex align-items-center"
                            data-type="caseStatus"
                            data-value="Sendout"
                            onClick={selectFilter}
                          >
                            Sendout
                          </span>
                          <span
                            className="caseStatusCounter"
                            data-type="caseStatus"
                            data-value="Sendout"
                            onClick={selectFilter}
                          >
                            {
                              list.filter(function (val) {
                                return val.caseStatus === "Sendout";
                              }).length
                            }
                          </span>
                        </li>
                      ) : null}

                      {list.filter(function (val) {
                        return val.caseStatus === "Interviews";
                      }).length !== 0 ? (
                        <li
                          style={{
                            backgroundColor: "#316a67",
                            color: "#fff",
                            borderBottom: "none",
                          }}
                          data-type="caseStatus"
                          data-value="Interviews"
                          onClick={selectFilter}
                        >
                          <span
                            className="recuriterItem d-flex align-items-center"
                            data-type="caseStatus"
                            data-value="Interviews"
                            onClick={selectFilter}
                          >
                            Interviews
                          </span>
                          <span
                            className="caseStatusCounter"
                            data-type="caseStatus"
                            data-value="Interviews"
                            onClick={selectFilter}
                          >
                            {
                              list.filter(function (val) {
                                return val.caseStatus === "Interviews";
                              }).length
                            }
                          </span>
                        </li>
                      ) : null}

                      {list.filter(function (val) {
                        return val.caseStatus === "Job Offers";
                      }).length !== 0 ? (
                        <li
                          style={{
                            backgroundColor: "#629bd3",
                            color: "#fff",
                            borderBottom: "none",
                          }}
                          data-type="caseStatus"
                          data-value="Job Offers"
                          onClick={selectFilter}
                        >
                          <span
                            className="recuriterItem d-flex align-items-center"
                            data-type="caseStatus"
                            data-value="Job Offers"
                            onClick={selectFilter}
                          >
                            Job Offers
                          </span>
                          <span
                            className="caseStatusCounter"
                            data-type="caseStatus"
                            data-value="Job Offers"
                            onClick={selectFilter}
                          >
                            {
                              list.filter(function (val) {
                                return val.caseStatus === "Job Offers";
                              }).length
                            }
                          </span>
                        </li>
                      ) : null}

                      {list.filter(function (val) {
                        return val.caseStatus === "Job Joining";
                      }).length !== 0 ? (
                        <li
                          style={{
                            backgroundColor: "#4768b0",
                            color: "#fff",
                            borderBottom: "none",
                          }}
                          data-type="caseStatus"
                          data-value="Job Joining"
                          onClick={selectFilter}
                        >
                          <span
                            className="recuriterItem d-flex align-items-center"
                            data-type="caseStatus"
                            data-value="Job Joining"
                            onClick={selectFilter}
                          >
                            Job Joined
                          </span>
                          <span
                            className="caseStatusCounter"
                            data-type="caseStatus"
                            data-value="Job Joining"
                            onClick={selectFilter}
                          >
                            {
                              list.filter(function (val) {
                                return val.caseStatus === "Job Joining";
                              }).length
                            }
                          </span>
                        </li>
                      ) : null}

                      {list.filter(function (val) {
                        return val.caseStatus === "Invoicing";
                      }).length !== 0 ? (
                        <li
                          style={{
                            backgroundColor: "#470000",
                            color: "#fff",
                            borderBottom: "none",
                          }}
                          data-type="caseStatus"
                          data-value="Invoicing"
                          onClick={selectFilter}
                        >
                          <span
                            className="recuriterItem d-flex align-items-center"
                            data-type="caseStatus"
                            data-value="Invoicing"
                            onClick={selectFilter}
                          >
                            Invoicing
                          </span>
                          <span
                            className="caseStatusCounter"
                            data-type="caseStatus"
                            data-value="Invoicing"
                            onClick={selectFilter}
                          >
                            {
                              list.filter(function (val) {
                                return val.caseStatus === "Invoicing";
                              }).length
                            }
                          </span>
                        </li>
                      ) : null}

                      {list.filter(function (val) {
                        return val.caseStatus === "Database";
                      }).length !== 0 ? (
                        <li
                          style={{
                            backgroundColor: "#26596d",
                            color: "#fff",
                            borderBottom: "none",
                          }}
                          data-type="caseStatus"
                          data-value="Database"
                          onClick={selectFilter}
                        >
                          <span
                            className="recuriterItem d-flex align-items-center"
                            data-type="caseStatus"
                            data-value="Database"
                            onClick={selectFilter}
                          >
                            Database
                          </span>
                          <span
                            className="caseStatusCounter"
                            data-type="caseStatus"
                            data-value="Database"
                            onClick={selectFilter}
                          >
                            {
                              list.filter(function (val) {
                                return val.caseStatus === "Database";
                              }).length
                            }
                          </span>
                        </li>
                      ) : null}

                      {list.filter(function (val) {
                        return val.caseStatus === "Closed";
                      }).length !== 0 ? (
                        <li
                          style={{
                            backgroundColor: "#da3d5e",
                            color: "#fff",
                            borderBottom: "none",
                          }}
                          data-type="caseStatus"
                          data-value="Closed"
                          onClick={selectFilter}
                        >
                          <span
                            className="recuriterItem d-flex align-items-center"
                            data-type="caseStatus"
                            data-value="Closed"
                            onClick={selectFilter}
                          >
                            Closed
                          </span>
                          <span
                            className="caseStatusCounter"
                            data-type="caseStatus"
                            data-value="Closed"
                            onClick={selectFilter}
                          >
                            {
                              list.filter(function (val) {
                                return val.caseStatus === "Closed";
                              }).length
                            }
                          </span>
                        </li>
                      ) : null}

                      {/* {
                                                (list.filter(function (val) {
                                                    return val.caseStatus === 'Promotion';
                                                }).length !== 0) ? <li style={{ backgroundColor: "#d58b5d", color: "#fff", borderBottom: "none" }} data-type="caseStatus" data-value="Promotion" onClick={selectFilter}>
                                                    <span className="recuriterItem d-flex align-items-center" data-type="caseStatus" data-value="Promotion" onClick={selectFilter}>
                                                        Promotion
                                                    </span>
                                                    <span className="caseStatusCounter" data-type="caseStatus" data-value="Promotion" onClick={selectFilter}>

                                                        {
                                                            list.filter(function (val) {
                                                                return val.caseStatus === 'Promotion';
                                                            }).length
                                                        }
                                                    </span>
                                                </li> : null
                                            } */}

                      {/* {
                                                (list.filter(function (val) {
                                                    return val.caseStatus === 'Refused';
                                                }).length !== 0) ?
                                                    <li style={{ backgroundColor: "#a84d4d", color: "#fff", borderBottom: "none" }} data-type="caseStatus" data-value="Refused" onClick={selectFilter}>
                                                        <span className="recuriterItem d-flex align-items-center" data-type="caseStatus" data-value="Refused" onClick={selectFilter}>
                                                            Refused
                                                        </span>
                                                        <span className="caseStatusCounter" data-type="caseStatus" data-value="Refused" onClick={selectFilter}>
                                                            {
                                                                list.filter(function (val) {
                                                                    return val.caseStatus === 'Refused';
                                                                }).length
                                                            }
                                                        </span>
                                                    </li> : null
                                            } */}

                      {/* {
                                                (list.filter(function (val) {
                                                    return val.caseStatus === 'Credit';
                                                }).length !== 0) ?
                                                    <li style={{ backgroundColor: "#470000", color: "#fff", borderBottom: "none" }} data-type="caseStatus" data-value="Credit" onClick={selectFilter}>
                                                        <span className="recuriterItem d-flex align-items-center" data-type="caseStatus" data-value="Credit" onClick={selectFilter}>
                                                            Credit
                                                        </span>
                                                        <span className="caseStatusCounter" data-type="caseStatus" data-value="Credit" onClick={selectFilter}>
                                                            {
                                                                list.filter(function (val) {
                                                                    return val.caseStatus === 'Credit';
                                                                }).length
                                                            }
                                                        </span>
                                                    </li> : null
                                            } */}

                      {/* {
                                                (list.filter(function (val) {
                                                    return val.caseStatus === 'Blacklist';
                                                }).length !== 0) ? <li style={{ backgroundColor: "#3C3939", color: "#fff", borderBottom: "none" }} data-type="caseStatus" data-value="Blacklist" onClick={selectFilter}>
                                                    <span className="recuriterItem d-flex align-items-center" data-type="caseStatus" data-value="Blacklist" onClick={selectFilter}>
                                                        Blacklist
                                                    </span>
                                                    <span className="caseStatusCounter" data-type="caseStatus" data-value="Blacklist" onClick={selectFilter}>
                                                        {
                                                            list.filter(function (val) {
                                                                return val.caseStatus === 'Blacklist';
                                                            }).length
                                                        }
                                                    </span>
                                                </li>
                                                    : null

                                            } */}
                    </ul>
                  </div>

                  <div className="dk-bottomLeftboxesCols">
                    <div
                      className={`${
                        !isCaseProgressFilter
                          ? "dk-toptitle"
                          : "dk-toptitle-filterBg"
                      }`}
                    >
                      {!isCaseProgressFilter ? (
                        <div className="dk-colstitle">PROGRESS</div>
                      ) : (
                        <div className="dk-dbsuMM-filterHeader">
                          {CaseProgressFilterText}
                          <span>
                            <i
                              className="fa fa-times-circle  ml-2"
                              data-type="case_progress"
                              onClick={removeTag}
                            ></i>
                          </span>
                        </div>
                      )}
                      <span className="dk-totalCvCount">{list.length}</span>
                    </div>
                    <ul className="dk-bottomLeftLists">
                      {cpList.map((item, index) =>
                        item.name != "Blank" ? (
                          <li
                            key={`caseprogress${index}`}
                            data-type="case_progress"
                            data-value={item.name}
                            onClick={selectFilter}
                          >
                            <span
                              className="recuriterItem d-flex align-items-center"
                              data-type="case_progress"
                              data-value={item.name}
                              onClick={selectFilter}
                            >
                              {item.name}
                            </span>
                            <span
                              className="rightItem"
                              data-type="case_progress"
                              data-value={item.name}
                              onClick={selectFilter}
                            >
                              {
                                list.filter(function (val) {
                                  return val.case_progress === item.name;
                                }).length
                              }
                            </span>
                          </li>
                        ) : null
                      )}
                    </ul>
                  </div>
                  <div className="dk-bottomLeftboxesCols">
                    <div
                      className={`${
                        !isCaseStageFilter
                          ? "dk-toptitle"
                          : "dk-toptitle-filterBg"
                      }`}
                    >
                      {!isCaseStageFilter ? (
                        <div className="dk-colstitle">SITUATION</div>
                      ) : (
                        <div className="dk-dbsuMM-filterHeader">
                          {CaseStageFilterText}
                          <span>
                            <i
                              className="fa fa-times-circle  ml-2"
                              data-type="case_stage"
                              onClick={removeTag}
                            ></i>
                          </span>
                        </div>
                      )}
                      <span className="dk-totalCvCount">{list.length}</span>
                    </div>
                    <ul className="dk-bottomLeftLists">
                      {csList.map((item, index) =>
                        item.name != "Blank" ? (
                          <li
                            key={`casestage${index}`}
                            data-type="case_stage"
                            data-value={item.name}
                            onClick={selectFilter}
                          >
                            <span
                              className="recuriterItem d-flex align-items-center"
                              data-type="case_stage"
                              data-value={item.name}
                              onClick={selectFilter}
                            >
                              {item.name}
                            </span>
                            <span
                              className="rightItem"
                              data-type="case_stage"
                              data-value={item.name}
                              onClick={selectFilter}
                            >
                              {
                                list.filter(function (val) {
                                  return val.case_stage === item.name;
                                }).length
                              }
                            </span>
                          </li>
                        ) : null
                      )}
                    </ul>
                  </div>
                </div>
                <div className="dk-candtopLeftBoxCont">
                  <div className="dk-topLeftboxesCols">
                    <div
                      className={`${
                        !isProfileIndicatorFilter
                          ? "dk-toptitle"
                          : "dk-toptitle-filterBg"
                      }`}
                    >
                      {!isProfileIndicatorFilter ? (
                        <div className="dk-colstitle">PROFILE</div>
                      ) : (
                        <div className="dk-dbsuMM-filterHeader">
                          {profileIndicatorText}
                          <span>
                            <i
                              className="fa fa-times-circle  ml-2"
                              data-type="profileIndicator"
                              onClick={removeTag}
                            ></i>
                          </span>
                        </div>
                      )}
                      <span className="dk-totalCvCount">{list.length}</span>
                    </div>
                    <ul>
                      {list.filter(function (val) {
                        return val.profileIndicator === 2;
                      }).length != 0 ? (
                        <li
                          data-type="profileIndicator"
                          data-text="Updated"
                          data-value="2"
                          onClick={selectFilter}
                        >
                          <span
                            className="leftItem d-flex align-items-center"
                            data-type="profileIndicator"
                            data-text="Updated"
                            data-value="2"
                            onClick={selectFilter}
                          >
                            <i
                              className="fas fa-circle mr-2"
                              style={{ color: "green" }}
                              data-type="profileIndicator"
                              data-text="Updated"
                              data-value="2"
                              onClick={selectFilter}
                            ></i>
                            Updated
                          </span>
                          <span
                            className="rightItem"
                            data-type="profileIndicator"
                            data-text="Updated"
                            data-value="2"
                            onClick={selectFilter}
                          >
                            {
                              list.filter(function (val) {
                                return val.profileIndicator === 2;
                              }).length
                            }
                          </span>
                        </li>
                      ) : null}
                      {list.filter(function (val) {
                        return val.profileIndicator === 1;
                      }).length != 0 ? (
                        <li
                          data-type="profileIndicator"
                          data-text="Blank"
                          data-value="1"
                          onClick={selectFilter}
                        >
                          <span
                            className="leftItem d-flex align-items-center"
                            data-type="profileIndicator"
                            data-text="Blank"
                            data-value="1"
                            onClick={selectFilter}
                          >
                            <i
                              className="fas fa-circle mr-2"
                              style={{ color: "#d3d3d3" }}
                              data-type="profileIndicator"
                              data-text="Blank"
                              data-value="1"
                              onClick={selectFilter}
                            ></i>
                            Blank
                          </span>
                          <span
                            className="rightItem"
                            data-type="profileIndicator"
                            data-text="Blank"
                            data-value="1"
                            onClick={selectFilter}
                          >
                            {
                              list.filter(function (val) {
                                return val.profileIndicator === 1;
                              }).length
                            }
                          </span>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                  <div className="dk-topLeftboxesCols">
                    <div
                      className={`${
                        !isFlagFilter ? "dk-toptitle" : "dk-toptitle-filterBg"
                      }`}
                    >
                      <div className="dk-dbsuMM-filterHeader">
                        {!isFlagFilter ? (
                          <div className="dk-colstitle">COMPLEXITY</div>
                        ) : (
                          <div className="dk-dbsuMM-filterHeader">
                            {flagFilterText}
                            <span>
                              <i
                                className="fa fa-times-circle  ml-2"
                                data-type="flag_type"
                                onClick={removeTag}
                              ></i>
                            </span>
                          </div>
                        )}
                      </div>
                      <span className="dk-totalCvCount">{list.length}</span>
                    </div>
                    <ul>
                      {/* <li data-text="Concerns" data-value="4" onClick={selectFilter}>
                                                <span className="leftItem d-flex align-items-center" data-type="flag_type" data-text="Concerns" data-value="4" onClick={selectFilter}>
                                                    <i className="fas fa-flag mr-2" style={{ color: "#2e75b6" }} data-type="flag_type" data-text="Concerns" data-value="4" onClick={selectFilter}></i>
                                                    Concerns 75%
                                                </span>
                                                <span className="rightItem" data-type="flag_type" data-text="Concerns" data-value="4" onClick={selectFilter}>
                                                    {
                                                        list.filter(function (val) {
                                                            return val.flag_type === 4;
                                                        }).length
                                                    }
                                                </span>
                                            </li> */}

                      {list.filter(function (val) {
                        return val.flag_type === 5;
                      }).length != 0 ? (
                        <li
                          data-type="flag_type"
                          data-text="On Time"
                          data-value="5"
                          onClick={selectFilter}
                        >
                          <span
                            className="leftItem d-flex align-items-center"
                            data-type="flag_type"
                            data-text="On Time"
                            data-value="5"
                            onClick={selectFilter}
                          >
                            <i
                              className="fas fa-flag mr-2"
                              style={{ color: "green" }}
                              data-type="flag_type"
                              data-text="On Time"
                              data-value="5"
                              onClick={selectFilter}
                            ></i>
                            Good Case
                          </span>
                          <span
                            className="rightItem"
                            data-type="flag_type"
                            data-text="On Time"
                            data-value="5"
                            onClick={selectFilter}
                          >
                            {
                              list.filter(function (val) {
                                return val.flag_type === 5;
                              }).length
                            }
                          </span>
                        </li>
                      ) : null}
                      {list.filter(function (val) {
                        return val.flag_type === 3;
                      }).length != 0 ? (
                        <li
                          data-type="flag_type"
                          data-text="Delayed"
                          data-value="3"
                          onClick={selectFilter}
                        >
                          <span
                            className="leftItem d-flex align-items-center"
                            data-type="flag_type"
                            data-text="Delayed"
                            data-value="3"
                            onClick={selectFilter}
                          >
                            <i
                              className="fas fa-flag mr-2"
                              style={{ color: "#d58b5d" }}
                              data-type="flag_type"
                              data-text="Delayed"
                              data-value="3"
                              onClick={selectFilter}
                            ></i>
                            Normal Case
                          </span>
                          <span
                            className="rightItem"
                            data-type="flag_type"
                            data-text="Delayed"
                            data-value="3"
                            onClick={selectFilter}
                          >
                            {
                              list.filter(function (val) {
                                return val.flag_type === 3;
                              }).length
                            }
                          </span>
                        </li>
                      ) : null}
                      {list.filter(function (val) {
                        return val.flag_type === 2;
                      }).length != 0 ? (
                        <li
                          data-type="flag_type"
                          data-text="Stopped"
                          data-value="2"
                          onClick={selectFilter}
                        >
                          <span
                            className="leftItem d-flex align-items-center"
                            data-type="flag_type"
                            data-text="Stopped"
                            data-value="2"
                            onClick={selectFilter}
                          >
                            <i
                              className="fas fa-flag mr-2"
                              style={{ color: "#d60101" }}
                              data-type="flag_type"
                              data-text="Stopped"
                              data-value="2"
                              onClick={selectFilter}
                            ></i>
                            Bad Case
                          </span>
                          <span
                            className="rightItem"
                            data-type="flag_type"
                            data-text="Stopped"
                            data-value="2"
                            onClick={selectFilter}
                          >
                            {
                              list.filter(function (val) {
                                return val.flag_type === 2;
                              }).length
                            }
                          </span>
                        </li>
                      ) : null}

                      {/* <li data-type="flag_type" data-text="Blank" data-value="1" onClick={selectFilter}>
                                                <span className="leftItem d-flex align-items-center" data-type="flag_type" data-text="Blank" data-value="1" onClick={selectFilter}>
                                                    <i className="fas fa-flag mr-2" style={{ color: "grey" }} data-type="flag_type" data-text="Blank" data-value="1" onClick={selectFilter}></i>
                                                    Blank 0%
                                                </span>
                                                <span className="rightItem" data-type="flag_type" data-text="Blank" data-value="1" onClick={selectFilter}>
                                                    {
                                                        list.filter(function (val) {
                                                            return val.flag_type === 1;
                                                        }).length
                                                    }
                                                </span>
                                            </li> */}
                      {list.filter(function (val) {
                        return val.flag_type === 1;
                      }).length != 0 ? (
                        <li
                          data-type="flag_type"
                          data-text="Blank"
                          data-value="1"
                          onClick={selectFilter}
                        >
                          <span
                            className="leftItem d-flex align-items-center"
                            data-type="flag_type"
                            data-text="Blank"
                            data-value="1"
                            onClick={selectFilter}
                          >
                            <i
                              className="fas fa-flag mr-2"
                              style={{
                                color: "#d3d3d3",
                                fontSize: "16px",
                                marginRight: "4px",
                              }}
                              data-type="flag_type"
                              data-text="Blank"
                              data-value="1"
                              onClick={selectFilter}
                            ></i>
                            Blank
                          </span>
                          <span
                            className="rightItem"
                            data-type="flag_type"
                            data-text="Blank"
                            data-value="1"
                            onClick={selectFilter}
                          >
                            {
                              list.filter(function (val) {
                                return val.flag_type === 1;
                              }).length
                            }
                          </span>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                  <div className="dk-topLeftboxesCols">
                    <div
                      className={`${
                        !isCanMotivationStatusFilter
                          ? "dk-toptitle"
                          : "dk-toptitle-filterBg"
                      }`}
                    >
                      {!isCanMotivationStatusFilter ? (
                        <div className="dk-colstitle">RESPONSE</div>
                      ) : (
                        <div className="dk-dbsuMM-filterHeader">
                          {canMotivationStatusText}
                          <span>
                            <i
                              className="fa fa-user  mr-2"
                              data-type="motivationStatus"
                              onClick={removeTag}
                            ></i>
                          </span>
                          <span>
                            <i
                              className="fa fa-times-circle  ml-2"
                              data-type="motivationStatus"
                              onClick={removeTag}
                            ></i>
                          </span>
                        </div>
                      )}
                      <span className="dk-totalCvCount">{list.length}</span>
                    </div>
                    <ul>
                      {list.filter(function (val) {
                        return val.motivationStatus === 1;
                      }).length != 0 ? (
                        <li
                          data-type="motivationStatus"
                          data-text="Motivated"
                          data-value="1"
                          onClick={selectFilter}
                        >
                          <span
                            className="leftItem d-flex align-items-center"
                            data-type="motivationStatus"
                            data-text="Motivated"
                            data-value="1"
                            onClick={selectFilter}
                          >
                            {/* <i className="fa fa-user mr-2" style={{ color: "#048204", fontSize: '16px', marginRight: '4px' }} data-type="motivationStatus" data-text="Motivated" data-value="1" onClick={selectFilter}></i> */}
                            <FaReplyAll
                              className="mr-2"
                              style={{
                                color: "#048204",
                                fontSize: "16px",
                                marginRight: "4px",
                              }}
                              data-type="motivationStatus"
                              data-text="Motivated"
                              data-value="1"
                              onClick={selectFilter}
                            />
                            High Response
                          </span>
                          <span
                            className="rightItem"
                            data-type="motivationStatus"
                            data-text="Motivated"
                            data-value="1"
                            onClick={selectFilter}
                          >
                            {
                              list.filter(function (val) {
                                return val.motivationStatus === 1;
                              }).length
                            }
                          </span>
                        </li>
                      ) : null}
                      {list.filter(function (val) {
                        return val.motivationStatus === 2;
                      }).length != 0 ? (
                        <li
                          data-type="motivationStatus"
                          data-text="Doubtful"
                          data-value="2"
                          onClick={selectFilter}
                        >
                          <span
                            className="leftItem d-flex align-items-center"
                            data-type="motivationStatus"
                            data-text="Doubtful"
                            data-value="2"
                            onClick={selectFilter}
                          >
                            {/* <i className="fa fa-user mr-2" style={{ color: "#d58b5d", fontSize: '16px', marginRight: '4px' }} data-type="motivationStatus" data-text="Doubtful" data-value="2" onClick={selectFilter}></i> */}
                            <FaReplyAll
                              className="mr-2"
                              style={{
                                color: "#d58b5d",
                                fontSize: "16px",
                                marginRight: "4px",
                              }}
                              data-type="motivationStatus"
                              data-text="Doubtful"
                              data-value="2"
                              onClick={selectFilter}
                            />
                            Good Response
                          </span>
                          <span
                            className="rightItem"
                            data-type="motivationStatus"
                            data-text="Doubtful"
                            data-value="2"
                            onClick={selectFilter}
                          >
                            {
                              list.filter(function (val) {
                                return val.motivationStatus === 2;
                              }).length
                            }
                          </span>
                        </li>
                      ) : null}
                      {list.filter(function (val) {
                        return val.motivationStatus === 3;
                      }).length != 0 ? (
                        <li
                          data-type="motivationStatus"
                          data-text="Unmotivated"
                          data-value="3"
                          onClick={selectFilter}
                        >
                          <span
                            className="leftItem d-flex align-items-center"
                            data-type="motivationStatus"
                            data-text="Unmotivated"
                            data-value="3"
                            onClick={selectFilter}
                          >
                            {/* <i className="fa fa-user mr-2" style={{ color: "#d60101", fontSize: '16px', marginRight: '4px' }} data-type="motivationStatus" data-text="Unmotivated" data-value="3" onClick={selectFilter}></i> */}
                            <FaReplyAll
                              className="fa fa-user mr-2"
                              style={{
                                color: "#d60101",
                                fontSize: "16px",
                                marginRight: "4px",
                              }}
                              data-type="motivationStatus"
                              data-text="Unmotivated"
                              data-value="3"
                              onClick={selectFilter}
                            />
                            Low Response
                          </span>
                          <span
                            className="rightItem"
                            data-type="motivationStatus"
                            data-text="Unmotivated"
                            data-value="3"
                            onClick={selectFilter}
                          >
                            {
                              list.filter(function (val) {
                                return val.motivationStatus === 3;
                              }).length
                            }
                          </span>
                        </li>
                      ) : null}
                      {list.filter(function (val) {
                        return val.motivationStatus === 0;
                      }).length != 0 ? (
                        <li
                          data-type="motivationStatus"
                          data-text="Blank"
                          data-value="0"
                          onClick={selectFilter}
                        >
                          <span
                            className="leftItem d-flex align-items-center"
                            data-type="motivationStatus"
                            data-text="Blank"
                            data-value="0"
                            onClick={selectFilter}
                          >
                            {/* <i className="fa fa-user ml-r" style={{ color: '#d3d3d3', fontSize: '16px', marginRight: '4px' }} data-type="motivationStatus" data-text="Blank" data-value="0" onClick={selectFilter}></i> */}
                            <FaReplyAll
                              className="ml-r"
                              style={{
                                color: "#d3d3d3",
                                fontSize: "16px",
                                marginRight: "4px",
                              }}
                              data-type="motivationStatus"
                              data-text="Blank"
                              data-value="0"
                              onClick={selectFilter}
                            />
                            Blank
                          </span>
                          <span
                            className="rightItem"
                            data-type="motivationStatus"
                            data-text="Blank"
                            data-value="0"
                            onClick={selectFilter}
                          >
                            {
                              list.filter(function (val) {
                                return val.motivationStatus === 0;
                              }).length
                            }
                          </span>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                  <div className="dk-topLeftboxesCols">
                    <div
                      className={`${
                        !isApprovelFilter
                          ? "dk-toptitle"
                          : "dk-toptitle-filterBg"
                      }`}
                    >
                      {!isApprovelFilter ? (
                        <div className="dk-colstitle">PRIORITY</div>
                      ) : (
                        <div className="dk-dbsuMM-filterHeader">
                          {approvelFilterText}
                          <span>
                            <i
                              className="fa fa-times-circle  ml-2"
                              data-type="profileStatus"
                              onClick={removeTag}
                            ></i>
                          </span>
                        </div>
                      )}
                      <span className="dk-totalCvCount">{list.length}</span>
                    </div>
                    <ul>
                      {list.filter(function (val) {
                        return val.profileStatus === 1;
                      }).length != 0 ? (
                        <li
                          data-type="profileStatus"
                          data-value="1"
                          data-name="100% Matched"
                          onClick={selectFilter}
                        >
                          <span
                            className="leftItem d-flex align-items-center"
                            data-type="profileStatus"
                            data-value="1"
                            onClick={selectFilter}
                            data-name="100% Matched"
                          >
                            <FaCheck
                              style={{
                                color: "#048204",
                                fontSize: "16px",
                                marginRight: "4px",
                              }}
                              data-type="profileStatus"
                              data-value="1"
                              data-name="100% Matched"
                              onClick={selectFilter}
                            />
                            Top Priority
                          </span>
                          <span
                            className="rightItem"
                            data-type="profileStatus"
                            data-value="1"
                            data-name="100% Matched"
                            onClick={selectFilter}
                          >
                            {
                              list.filter(function (val) {
                                return val.profileStatus === 1;
                              }).length
                            }
                          </span>
                        </li>
                      ) : null}
                      {list.filter(function (val) {
                        return val.profileStatus === 2;
                      }).length != 0 ? (
                        <li
                          data-type="profileStatus"
                          data-value="2"
                          data-name="75% Matched"
                          onClick={selectFilter}
                        >
                          <span
                            className="leftItem d-flex align-items-center"
                            data-name="75% Matched"
                            data-type="profileStatus"
                            data-value="2"
                            onClick={selectFilter}
                          >
                            <FaCheck
                              style={{
                                color: "#d58b5d",
                                fontSize: "16px",
                                marginRight: "4px",
                              }}
                              data-type="profileStatus"
                              data-value="2"
                              data-name="75% Matched"
                              onClick={selectFilter}
                            />
                            Mid Priority
                          </span>
                          <span
                            className="rightItem"
                            data-type="profileStatus"
                            data-name="75% Matched"
                            data-value="2"
                            onClick={selectFilter}
                          >
                            {
                              list.filter(function (val) {
                                return val.profileStatus === 2;
                              }).length
                            }
                          </span>
                        </li>
                      ) : null}
                      {list.filter(function (val) {
                        return val.profileStatus === 1;
                      }).length != 0 ? (
                        <li
                          data-type="profileStatus"
                          data-value="4"
                          data-name="0% Matched"
                          onClick={selectFilter}
                        >
                          <span
                            className="leftItem d-flex align-items-center"
                            data-name="0% Matched"
                            data-type="profileStatus"
                            data-value="4"
                            onClick={selectFilter}
                          >
                            <FaCheck
                              style={{
                                color: "#d60101",
                                fontSize: "16px",
                                marginRight: "4px",
                              }}
                              data-type="profileStatus"
                              data-value="4"
                              onClick={selectFilter}
                              data-name="0% Matched"
                            />
                            Low Priority
                          </span>
                          <span
                            className="rightItem"
                            data-type="profileStatus"
                            data-value="4"
                            data-name="0% Matched"
                            onClick={selectFilter}
                          >
                            {
                              list.filter(function (val) {
                                return val.profileStatus === 4;
                              }).length
                            }
                          </span>
                        </li>
                      ) : null}
                      {list.filter(function (val) {
                        return val.profileStatus === 0;
                      }).length != 0 ? (
                        <li
                          data-type="profileStatus"
                          data-value="0"
                          data-name="Blank"
                          onClick={selectFilter}
                        >
                          <span
                            className="leftItem d-flex align-items-center"
                            data-name="Blank"
                            data-type="profileStatus"
                            data-value="0"
                            onClick={selectFilter}
                          >
                            <FaCheck
                              style={{
                                color: "#d3d3d3",
                                fontSize: "16px",
                                marginRight: "4px",
                              }}
                              data-type="profileStatus"
                              data-value="0"
                              onClick={selectFilter}
                              data-name="Blank"
                            />{" "}
                            Blank
                          </span>
                          <span
                            className="rightItem"
                            data-type="profileStatus"
                            data-value="0"
                            data-name="Blank"
                            onClick={selectFilter}
                          >
                            {
                              list.filter(function (val) {
                                return val.profileStatus === 0;
                              }).length
                            }
                          </span>
                        </li>
                      ) : null}

                      {/* <li data-type="profileStatus" data-value="3" data-name="50% Matched" onClick={selectFilter}>
                                                <span className="leftItem d-flex align-items-center" data-name="50% Matched" data-type="profileStatus" data-value="3" onClick={selectFilter}>
                                                    <FaCheck style={{ color: '#3a76b4', fontSize: '16px', marginRight: '4px' }} data-type="profileStatus" data-value="3" onClick={selectFilter} data-name="50% Matched" /> 50% Matched
                                                </span>
                                                <span className="rightItem" data-type="profileStatus" data-value="3" data-name="50% Matched" onClick={selectFilter}>
                                                    {
                                                        list.filter(function (val) {
                                                            return val.profileStatus === 3;
                                                        }).length
                                                    }
                                                </span>
                                            </li> */}
                    </ul>
                  </div>

                  <div className="dk-topLeftboxesCols">
                    <div
                      className={`${
                        !isIndustriesFilter
                          ? "dk-toptitle"
                          : "dk-toptitle-filterBg"
                      }`}
                    >
                      {!isIndustriesFilter ? (
                        <div className="dk-colstitle">INDUSTRY</div>
                      ) : (
                        <div className="dk-dbsuMM-filterHeader">
                          {isIndustriesFilterText}
                          <span>
                            <i
                              className="fa fa-times-circle  ml-2"
                              data-type="industries"
                              onClick={removeTag}
                            ></i>
                          </span>
                        </div>
                      )}
                      <span className="dk-totalCvCount">{list.length}</span>
                    </div>
                    <ul
                      className="dk-bottomLeftLists"
                      style={{ maxHeight: "144px", overflowY: "scroll" }}
                    >
                      {inList.map((item, index) =>
                        item.name != "Blank" ? (
                          <li
                            key={`industries${index}`}
                            data-type="industries"
                            data-value={item.name}
                            onClick={selectFilter}
                          >
                            <span
                              className="recuriterItem d-flex align-items-center"
                              data-type="industries"
                              data-value={item.name}
                              onClick={selectFilter}
                            >
                              {item.name}
                            </span>
                            <span
                              className="rightItem"
                              data-type="industries"
                              data-value={item.name}
                              onClick={selectFilter}
                            >
                              {
                                list.filter(function (val) {
                                  return val.industries === item.name;
                                }).length
                              }
                            </span>
                          </li>
                        ) : null
                      )}
                    </ul>
                  </div>

                  <div className="dk-topLeftboxesCols">
                    <div
                      className={`${
                        !isVisaFilter ? "dk-toptitle" : "dk-toptitle-filterBg"
                      }`}
                    >
                      {!isVisaFilter ? (
                        <div className="dk-colstitle">VISA STATUS</div>
                      ) : (
                        <div className="dk-dbsuMM-filterHeader">
                          {isVisaFilterText}
                          <span>
                            <i
                              className="fa fa-times-circle  ml-2"
                              data-type="visaStatus"
                              onClick={removeTag}
                            ></i>
                          </span>
                        </div>
                      )}
                      <span className="dk-totalCvCount">{list.length}</span>
                    </div>
                    <ul
                      className="dk-bottomLeftLists"
                      style={{ maxHeight: "144px", overflowY: "scroll" }}
                    >
                      {visaList.map((item, index) =>
                        item.name != "Blank" ? (
                          <li
                            key={`visaStatus${index}`}
                            data-type="visaStatus"
                            data-value={item.name}
                            onClick={selectFilter}
                          >
                            <span
                              className="recuriterItem d-flex align-items-center"
                              data-type="visaStatus"
                              data-value={item.name}
                              onClick={selectFilter}
                            >
                              {item.name}
                            </span>
                            <span
                              className="rightItem"
                              data-type="visaStatus"
                              data-value={item.name}
                              onClick={selectFilter}
                            >
                              {
                                list.filter(function (val) {
                                  return val.visaStatus === item.name;
                                }).length
                              }
                            </span>
                          </li>
                        ) : null
                      )}
                    </ul>
                  </div>
                </div>
                <div className="dk-candRightBoxCont dk-candbottomLeftBoxCont">
                  <div className="dk-bottomLeftboxesCols">
                    <div
                      className={`${
                        !isCategoryFilter
                          ? "dk-toptitle"
                          : "dk-toptitle-filterBg"
                      }`}
                    >
                      {!isCategoryFilter ? (
                        <div className="dk-colstitle">CATEGORY</div>
                      ) : (
                        <div className="dk-dbsuMM-filterHeader">
                          {categoryFilterText == "None"
                            ? "Blank"
                            : categoryFilterText}
                          <span>
                            <i
                              className="fa fa-times-circle  ml-2"
                              data-type="category"
                              onClick={removeTag}
                            ></i>
                          </span>
                        </div>
                      )}
                      <span className="dk-totalCvCount">{list.length}</span>
                    </div>
                    <ul className="dk-bottomLeftLists">
                      {list.filter(function (val) {
                        return val.category === "ML";
                      }).length != 0 ? (
                        <li
                          data-type="category"
                          data-value="ML"
                          onClick={selectFilter}
                        >
                          <span
                            className="leftItem d-flex align-items-center"
                            data-type="category"
                            data-value="ML"
                            onClick={selectFilter}
                          >
                            <i
                              className="fas fa-language mr-2"
                              data-type="category"
                              data-value="ML"
                              onClick={selectFilter}
                            ></i>
                            ML
                          </span>
                          <span
                            className="rightItem"
                            data-type="category"
                            data-value="ML"
                            onClick={selectFilter}
                          >
                            {
                              list.filter(function (val) {
                                return val.category === "ML";
                              }).length
                            }
                          </span>
                        </li>
                      ) : null}
                      {list.filter(function (val) {
                        return val.category === "IT";
                      }).length != 0 ? (
                        <li
                          data-type="category"
                          data-value="IT"
                          onClick={selectFilter}
                        >
                          <span
                            className="leftItem d-flex align-items-center"
                            data-type="category"
                            data-value="IT"
                            onClick={selectFilter}
                          >
                            <i
                              className="fas fa-tv mr-2"
                              data-type="category"
                              data-value="IT"
                              onClick={selectFilter}
                            ></i>
                            IT
                          </span>
                          <span
                            className="rightItem"
                            data-type="category"
                            data-value="IT"
                            onClick={selectFilter}
                          >
                            {
                              list.filter(function (val) {
                                return val.category === "IT";
                              }).length
                            }
                          </span>
                        </li>
                      ) : null}
                      {list.filter(function (val) {
                        return val.category === "";
                      }).length != 0 ? (
                        <li
                          data-type="category"
                          data-value=""
                          onClick={selectFilter}
                        >
                          <span
                            className="leftItem d-flex align-items-center"
                            data-type="category"
                            data-value=""
                            onClick={selectFilter}
                          >
                            <i
                              className="far fa-circle mr-2"
                              data-type="category"
                              data-value=""
                              onClick={selectFilter}
                            ></i>
                            Blank
                          </span>
                          <span
                            className="rightItem"
                            data-type="category"
                            data-value=""
                            onClick={selectFilter}
                          >
                            {
                              list.filter(function (val) {
                                return val.category == "";
                              }).length
                            }
                          </span>
                        </li>
                      ) : null}
                    </ul>
                  </div>

                  <div className="dk-bottomLeftboxesCols">
                    <div
                      className={`${
                        !isCountryFilter
                          ? "dk-toptitle"
                          : "dk-toptitle-filterBg"
                      }`}
                    >
                      {!isCountryFilter ? (
                        <div className="dk-colstitle">COUNTRY</div>
                      ) : (
                        <div className="dk-dbsuMM-filterHeader">
                          {isCountryFilterText}
                          <span>
                            <i
                              className="fa fa-times-circle  ml-2"
                              data-type="c_country"
                              onClick={removeTag}
                            ></i>
                          </span>
                        </div>
                      )}
                      <span className="dk-totalCvCount">{list.length}</span>
                    </div>
                    <ul className="dk-bottomLeftLists">
                      {countryList.map((item, index) =>
                        item.name != "Blank" ? (
                          <li
                            key={`c_country${index}`}
                            data-type="c_country"
                            data-value={item.name}
                            onClick={selectFilter}
                          >
                            <span
                              className="recuriterItem d-flex align-items-center"
                              data-type="c_country"
                              data-value={item.name}
                              onClick={selectFilter}
                            >
                              {item.name}
                            </span>
                            <span
                              className="rightItem"
                              data-type="c_country"
                              data-value={item.name}
                              onClick={selectFilter}
                            >
                              {
                                list.filter(function (val) {
                                  return val.c_country === item.name;
                                }).length
                              }
                            </span>
                          </li>
                        ) : null
                      )}
                    </ul>
                  </div>

                  <div className="dk-bottomLeftboxesCols">
                    <div
                      className={`${
                        !isCityFilter ? "dk-toptitle" : "dk-toptitle-filterBg"
                      }`}
                    >
                      {!isCityFilter ? (
                        <div className="dk-colstitle">CITY/TOWN</div>
                      ) : (
                        <div className="dk-dbsuMM-filterHeader">
                          {isCityFilterText}
                          <span>
                            <i
                              className="fa fa-times-circle  ml-2"
                              data-type="c_city"
                              onClick={removeTag}
                            ></i>
                          </span>
                        </div>
                      )}
                      <span className="dk-totalCvCount">{list.length}</span>
                    </div>
                    <ul className="dk-bottomLeftLists">
                      {cityList.map((item, index) =>
                        item.name != "Blank" ? (
                          <li
                            key={`c_city${index}`}
                            data-type="c_city"
                            data-value={item.name}
                            onClick={selectFilter}
                          >
                            <span
                              className="recuriterItem d-flex align-items-center"
                              data-type="c_city"
                              data-value={item.name}
                              onClick={selectFilter}
                            >
                              {item.name}
                            </span>
                            <span
                              className="rightItem"
                              data-type="c_city"
                              data-value={item.name}
                              onClick={selectFilter}
                            >
                              {
                                list.filter(function (val) {
                                  return val.c_city === item.name;
                                }).length
                              }
                            </span>
                          </li>
                        ) : null
                      )}
                    </ul>
                  </div>

                  <div className="dk-bottomLeftboxesCols">
                    <div
                      className={`${
                        !isNationalityFilter
                          ? "dk-toptitle"
                          : "dk-toptitle-filterBg"
                      }`}
                    >
                      {!isNationalityFilter ? (
                        <div className="dk-colstitle">NATIONALITY</div>
                      ) : (
                        <div className="dk-dbsuMM-filterHeader">
                          {isNationalityFilterText}
                          <span>
                            <i
                              className="fa fa-times-circle  ml-2"
                              data-type="nationality"
                              onClick={removeTag}
                            ></i>
                          </span>
                        </div>
                      )}
                      <span className="dk-totalCvCount">{list.length}</span>
                    </div>
                    <ul className="dk-bottomLeftLists">
                      {nationalityList.map((item, index) =>
                        item.name != "Blank" ? (
                          <li
                            key={`nationality${index}`}
                            data-type="nationality"
                            data-value={item.name}
                            onClick={selectFilter}
                          >
                            <span
                              className="recuriterItem d-flex align-items-center"
                              data-type="nationality"
                              data-value={item.name}
                              onClick={selectFilter}
                            >
                              {item.name}
                            </span>
                            <span
                              className="rightItem"
                              data-type="nationality"
                              data-value={item.name}
                              onClick={selectFilter}
                            >
                              {
                                list.filter(function (val) {
                                  return val.nationality === item.name;
                                }).length
                              }
                            </span>
                          </li>
                        ) : null
                      )}
                    </ul>
                  </div>

                  <div className="dk-bottomLeftboxesCols">
                    <div
                      className={`${
                        !isNative1Filter
                          ? "dk-toptitle"
                          : "dk-toptitle-filterBg"
                      }`}
                    >
                      {!isNative1Filter ? (
                        <div className="dk-colstitle">NATIVE LANGUAGE</div>
                      ) : (
                        <div className="dk-dbsuMM-filterHeader">
                          {isNative1FilterText}
                          <span>
                            <i
                              className="fa fa-times-circle  ml-2"
                              data-type="native1"
                              onClick={removeTag}
                            ></i>
                          </span>
                        </div>
                      )}
                      <span className="dk-totalCvCount">{list.length}</span>
                    </div>
                    <ul className="dk-bottomLeftLists">
                      {native1List.map((item, index) =>
                        item.name != "Blank" ? (
                          <li
                            key={`native1${index}`}
                            data-type="native1"
                            data-value={item.name}
                            onClick={selectFilter}
                          >
                            <span
                              className="recuriterItem d-flex align-items-center"
                              data-type="native1"
                              data-value={item.name}
                              onClick={selectFilter}
                            >
                              {item.name}
                            </span>
                            <span
                              className="rightItem"
                              data-type="native1"
                              data-value={item.name}
                              onClick={selectFilter}
                            >
                              {
                                list.filter(function (val) {
                                  return val.native1 === item.name;
                                }).length
                              }
                            </span>
                          </li>
                        ) : null
                      )}
                    </ul>
                  </div>

                  <div className="dk-bottomLeftboxesCols">
                    <div
                      className={`${
                        !isNative2Filter
                          ? "dk-toptitle"
                          : "dk-toptitle-filterBg"
                      }`}
                    >
                      {!isNative2Filter ? (
                        <div className="dk-colstitle">FLUENT LANGUAGE</div>
                      ) : (
                        <div className="dk-dbsuMM-filterHeader">
                          {isNative2FilterText}
                          <span>
                            <i
                              className="fa fa-times-circle  ml-2"
                              data-type="native2"
                              onClick={removeTag}
                            ></i>
                          </span>
                        </div>
                      )}
                      <span className="dk-totalCvCount">{list.length}</span>
                    </div>
                    <ul className="dk-bottomLeftLists">
                      {native2List.map((item, index) =>
                        item.name != "Blank" ? (
                          <li
                            key={`native2${index}`}
                            data-type="native2"
                            data-value={item.name}
                            onClick={selectFilter}
                          >
                            <span
                              className="recuriterItem d-flex align-items-center"
                              data-type="native2"
                              data-value={item.name}
                              onClick={selectFilter}
                            >
                              {item.name}
                            </span>
                            <span
                              className="rightItem"
                              data-type="native2"
                              data-value={item.name}
                              onClick={selectFilter}
                            >
                              {
                                list.filter(function (val) {
                                  return val.native2 === item.name;
                                }).length
                              }
                            </span>
                          </li>
                        ) : null
                      )}
                    </ul>
                  </div>
                </div>
                <div className="dk-candRightBoxCont dk-candbottomLeftBoxCont">
                  <div className="dk-bottomLeftboxesCols">
                    <div
                      className={`${
                        !isCvSourceFilter
                          ? "dk-toptitle"
                          : "dk-toptitle-filterBg"
                      }`}
                    >
                      {!isCvSourceFilter ? (
                        <div className="dk-colstitle">CV SOURCE</div>
                      ) : (
                        <div className="dk-dbsuMM-filterHeader">
                          {cvSourceFilterText}
                          <span>
                            <i
                              className="fa fa-times-circle  ml-2"
                              data-type="cv_source"
                              onClick={removeTag}
                            ></i>
                          </span>
                        </div>
                      )}
                      <span className="dk-totalCvCount">{list.length}</span>
                    </div>
                    <ul className="dk-bottomLeftLists">
                      {cvSourceList.map((item, index) =>
                        item.name != "Blank" ? (
                          <li
                            key={`cvsource${index}`}
                            data-type="cv_source"
                            data-value={item.name}
                            onClick={selectFilter}
                          >
                            <span
                              className="recuriterItem d-flex align-items-center"
                              data-type="cv_source"
                              data-value={item.name}
                              onClick={selectFilter}
                            >
                              {item.name}
                            </span>
                            <span
                              className="rightItem"
                              data-type="cv_source"
                              data-value={item.name}
                              onClick={selectFilter}
                            >
                              {
                                list.filter(function (val) {
                                  return val.cv_source === item.name;
                                }).length
                              }
                            </span>
                          </li>
                        ) : null
                      )}
                    </ul>
                  </div>
                  <div className="dk-bottomLeftboxesCols">
                    <div
                      className={`${
                        !isCompanyFilter
                          ? "dk-toptitle"
                          : "dk-toptitle-filterBg"
                      }`}
                    >
                      {!isCompanyFilter ? (
                        <div className="dk-colstitle">JOB COMPANY</div>
                      ) : (
                        <div className="dk-dbsuMM-filterHeader">
                          {companyFilterText}
                          <span>
                            <i
                              className="fa fa-times-circle  ml-2"
                              data-type="company"
                              onClick={removeTag}
                            ></i>
                          </span>
                        </div>
                      )}
                      <span className="dk-totalCvCount">{list.length}</span>
                    </div>
                    <ul className="dk-bottomLeftLists">
                      {companyList.map((item, index) =>
                        item.name != "Blank" ? (
                          <li
                            id={`company${index}`}
                            key={`company${index}`}
                            data-type="company"
                            data-value={item.name}
                            onClick={selectFilter}
                          >
                            <span
                              className="recuriterItem d-flex align-items-center"
                              data-type="company"
                              data-value={item.name}
                              onClick={selectFilter}
                            >
                              {item.name}
                            </span>
                            <span
                              className="rightItem"
                              data-type="company"
                              data-value={item.name}
                              onClick={selectFilter}
                            >
                              {
                                list.filter(function (val) {
                                  return val.company === item.name;
                                }).length
                              }
                            </span>
                          </li>
                        ) : null
                      )}
                    </ul>
                  </div>
                  <div className="dk-bottomLeftboxesCols">
                    <div
                      className={`${
                        !isLocationFilter
                          ? "dk-toptitle"
                          : "dk-toptitle-filterBg"
                      }`}
                    >
                      {!isLocationFilter ? (
                        <div className="dk-colstitle">JOB CITY/TOWN</div>
                      ) : (
                        <div className="dk-dbsuMM-filterHeader">
                          {locationText}
                          <span>
                            <i
                              className="fa fa-times-circle  ml-2"
                              data-type="location"
                              onClick={removeTag}
                            ></i>
                          </span>
                        </div>
                      )}
                      <span className="dk-totalCvCount">{list.length}</span>
                    </div>
                    <ul className="dk-bottomLeftLists">
                      {locationList.map((item, index) =>
                        item.name != "Blank" ? (
                          <li
                            key={`location${index}`}
                            data-type="location"
                            data-value={item.name}
                            onClick={selectFilter}
                          >
                            <span
                              className="recuriterItem d-flex align-items-center"
                              data-type="location"
                              data-value={item.name}
                              onClick={selectFilter}
                            >
                              {item.name}
                            </span>
                            <span
                              className="rightItem"
                              data-type="location"
                              data-value={item.name}
                              onClick={selectFilter}
                            >
                              {
                                list.filter(function (val) {
                                  return val.location === item.name;
                                }).length
                              }
                            </span>
                          </li>
                        ) : null
                      )}
                    </ul>
                  </div>
                  <div className="dk-bottomLeftboxesCols">
                    <div
                      className={`${
                        !isProjectFilter
                          ? "dk-toptitle"
                          : "dk-toptitle-filterBg"
                      }`}
                    >
                      {!isProjectFilter ? (
                        <div className="dk-colstitle">JOB PROJECT</div>
                      ) : (
                        <div className="dk-dbsuMM-filterHeader">
                          {projectText}
                          <span>
                            <i
                              className="fa fa-times-circle  ml-2"
                              data-type="project"
                              onClick={removeTag}
                            ></i>
                          </span>
                        </div>
                      )}
                      <span className="dk-totalCvCount">{list.length}</span>
                    </div>
                    <ul className="dk-bottomLeftLists">
                      {projectList.map((item, index) =>
                        item.name != "Blank" ? (
                          <li
                            key={`project${index}`}
                            data-type="project"
                            data-value={item.name}
                            onClick={selectFilter}
                          >
                            <span
                              className="recuriterItem d-flex align-items-center"
                              data-type="project"
                              data-value={item.name}
                              onClick={selectFilter}
                            >
                              {item.name}
                            </span>
                            <span
                              className="rightItem"
                              data-type="project"
                              data-value={item.name}
                              onClick={selectFilter}
                            >
                              {
                                list.filter(function (val) {
                                  return val.project === item.name;
                                }).length
                              }
                            </span>
                          </li>
                        ) : null
                      )}
                    </ul>
                  </div>
                  <div className="dk-bottomLeftboxesCols">
                    <div
                      className={`${
                        !isRoleTypeFilter
                          ? "dk-toptitle"
                          : "dk-toptitle-filterBg"
                      }`}
                    >
                      {!isRoleTypeFilter ? (
                        <div className="dk-colstitle">JOB ROLE TYPE</div>
                      ) : (
                        <div className="dk-dbsuMM-filterHeader">
                          {roleTypeFilterText}
                          <span>
                            <i
                              className="fa fa-times-circle  ml-2"
                              data-type="role"
                              onClick={removeTag}
                            ></i>
                          </span>
                        </div>
                      )}
                      <span className="dk-totalCvCount">{list.length}</span>
                    </div>
                    <ul className="dk-bottomLeftLists">
                      {roleTypeList.map((item, index) =>
                        item.name != "Blank" ? (
                          <li
                            key={`role${index}`}
                            data-type="role"
                            data-value={item.name}
                            onClick={selectFilter}
                          >
                            <span
                              className="recuriterItem d-flex align-items-center"
                              data-type="role"
                              data-value={item.name}
                              onClick={selectFilter}
                            >
                              {item.name}
                            </span>
                            <span
                              className="rightItem"
                              data-type="role"
                              data-value={item.name}
                              onClick={selectFilter}
                            >
                              {
                                list.filter(function (val) {
                                  return val.role === item.name;
                                }).length
                              }
                            </span>
                          </li>
                        ) : null
                      )}
                    </ul>
                  </div>
                  <div className="dk-bottomLeftboxesCols">
                    <div
                      className={`${
                        !isLanguageFilter
                          ? "dk-toptitle"
                          : "dk-toptitle-filterBg"
                      }`}
                    >
                      {!isLanguageFilter ? (
                        <div className="dk-colstitle">JOB LANGUAGE</div>
                      ) : (
                        <div className="dk-dbsuMM-filterHeader">
                          {languageFilterText}
                          <span>
                            <i
                              className="fa fa-times-circle  ml-2"
                              data-type="lang"
                              onClick={removeTag}
                            ></i>
                          </span>
                        </div>
                      )}
                      <span className="dk-totalCvCount">{list.length}</span>
                    </div>
                    <ul className="dk-bottomLeftLists">
                      {langList.map((item, index) =>
                        item.name != "Blank" ? (
                          <li
                            key={`lang${index}`}
                            data-type="lang"
                            data-value={item.name}
                            onClick={selectFilter}
                          >
                            <span
                              className="recuriterItem d-flex align-items-center"
                              data-type="lang"
                              data-value={item.name}
                              onClick={selectFilter}
                            >
                              {item.name}
                            </span>
                            <span
                              className="rightItem"
                              data-type="lang"
                              data-value={item.name}
                              onClick={selectFilter}
                            >
                              {
                                list.filter(function (val) {
                                  return val.lang === item.name;
                                }).length
                              }
                            </span>
                          </li>
                        ) : null
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Summary;
