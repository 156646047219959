import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './financemodule.scss';
// import PaymentsIcon from '../../../../assets/images/euro_icon.png';
// import Accounting from '../../../../assets/images/accounting.png';
// import BusinessOverview from '../../../../assets/images/business.png';
import Casflow from '../../../../assets/images/cash-flow.png';
import Contracts from '../../../../assets/images/contracts.png';
import Bankwhite from '../../../../assets/images/bank-white.png';
// import Knowledgebase from '../../../../assets/images/knowledge.png';
// import Vatfiles from '../../../../assets/images/vatfile.png';
import Clients from '../../../../assets/images/euro.png';
import Invoices from '../../../../assets/images/invoice-white.png';
import Information from '../../../../assets/images/information_white.png';
import Report from '../../../../assets/images/report_white.png';
import Reports from '../../../../assets/images/report.png';
import VatReturns from '../../../../assets/images/vat-returns.png';
import ExpenseBook from '../../../../assets/images/expense-book.png';
import Liability from '../../../../assets/images/liability-insurance.png';

// import Profiloss from '../../../../assets/images/profit-and-loss.png';
import { setMyModule } from "../../../../slice/modules/moduleSlice";
import { getList } from "../../../../slice/accounting/salesBooks";
import { getInvoiceList, fetchCaseStatus } from "../../../../slice/accounting/invoiceBooks";
import { getBankingList } from "../../../../slice/accounting/accountBanking";
import { listTable, cashoutListTable } from "../../../../slice/accounting/cashflow";
import { useDispatch, useSelector } from "react-redux";
import {  showError } from "../../../../components/utils/messages/messageSlice";
import Moduleheader from "../../../elements/header/index";
// import { FaEuroSign } from "react-icons/fa";
import { SYSTEM_CONSTANTS } from "../../../../constants";

const FinanceDashboard = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        document.title = "DK Global Finance"
    }, []);

    const { userInfo } = useSelector(state => state.auth);

    const { caseStatusList } = useSelector(state => state.common);
    const { salesBookList, isLoading } = useSelector(state => state.salesBooks);
    const { invoiceBookList, invoiceCaseStatusList, isInvoiceLoading } = useSelector(state => state.invoiceBooks);
    const { bankingBookList, isbankingLoading } = useSelector(state => state.bankingBooks);
    const { cashflowList, cashoutHeadingList, isCashflowLoading } = useSelector(state => state.cashflowdata);

    const [year, setYear] = useState('2022');
    const [dataList, setData] = useState([])
    const [invoiceDataList, setDataList] = useState([])
    const [bankingDataList, setBankingDataList] = useState([])
    const [listcashflowIncome, setListcashflowIncome] = useState([]);
    const [listCashOutExpense, setListCashOutExpense] = useState([]);

    useEffect(() => {
        const fetchList = async () => {
            try {
                await dispatch(getList({}))
                await dispatch(getInvoiceList({}))
                await dispatch(fetchCaseStatus({ id: SYSTEM_CONSTANTS.INVOICE_SALES_BOOK.caseStatusID }))
                await dispatch(getBankingList({}))

                await dispatch(listTable({ year: year }))
                await dispatch(cashoutListTable({ year: year }))

            } catch (err) {
                console.log(err)
            }
        }

        fetchList();

    }, [dispatch])

    useEffect(() => {
        setData(salesBookList)
        setDataList(invoiceBookList)
        setBankingDataList(bankingBookList)
        setListcashflowIncome(cashflowList)
        setListCashOutExpense(cashoutHeadingList)

    }, [isLoading, isInvoiceLoading, isbankingLoading, isCashflowLoading, dispatch])


    const removeValFromIndex = [0, 1, 2, 3, 4, 8, 9, 10]; // remove status index from usersList
    const indexSet = new Set(removeValFromIndex);
    const businessBook = caseStatusList.filter((value, i) => !indexSet.has(i));


    const setModule = async (myModule, moduleColor) => {
        await dispatch(setMyModule({ module: myModule, color: moduleColor }))

        if (userInfo.role === "User" || userInfo.role === "Admin") {

            if(myModule=="payments"){
                 switch (myModule) {
                case 'payments':
                    history.push("/accounting/payments/summary");
                    break;
                default:
                    break;
            }
            }else {  dispatch(showError({ msg: 'You are not authorized to view' })) }            

        } else {

            switch (myModule) {
                case 'accounting':
                    history.push("/dkglobalfinance/accounting/dashboard");
                    break;

                case 'clients-invoices':
                    history.push("/dkglobalfinance/clients-invoices/dashboard");
                    break;
                case 'reports':
                    history.push("/dkglobalfinance/reports/dashboard");
                    break;

                case 'banking':
                    history.push("/dkglobalfinance/banking/dashboard");
                    break;

                case 'cashflow':
                    history.push("/dkglobalfinance/cashflow/dashboard");
                    break;

                case 'businessoverview':
                    history.push("/accounting/businessoverview/dashboard");
                    break;

                case 'payments':
                    history.push("/accounting/payments/summary");
                    break;

                case 'expense':
                    history.push("/dkglobalfinance/invoices/dashboard");
                    break;

                case 'profitloss':
                    history.push("/dkglobalfinance/profitloss/dashboard");
                    break;

                case 'contracts':
                    history.push("/dkglobalfinance/contracts/summary");
                    break;

                case 'knowledgebase':
                    history.push("/dkglobalfinance/knowledgebase/dashboard");
                    break;

                case 'legal':
                    history.push("/dkglobalfinance/legal/dashboard");
                    break;

                case 'finance-payments':
                    history.push("/dkglobalfinance/finance-payments/dashboard");
                    break;

                case 'tax-returns':
                    history.push("/dkglobalfinance/tax-returns/summary");
                    break;

                case 'acc-contracts':
                    history.push("/dkglobalfinance/acc-contracts/summary");
                    break;

                default:
                    break;
            }
        }
    }

    return (
        <>
            <Moduleheader dashboardheaderLink="Dashboard -" dashboardLink="/dashboard/plateform" linkcolor="#3c4c62" mainheaderTitle="ACCOUNTING" textcolor="#5A4C72" />
            <div className="dk-master-moduleCon dk-financeAccounting-container">
                <div className="dk-module-blocksContainer">
                    <div className="w-100">
                        <h4 className="title" style={{ marginTop: '0', color: "#504d4d" }}>ACCOUNTING Department</h4>
                        {/* <div className="dk-portalCols">
                            <div className="dk-portalBox dkg-account-grey-boxs" style={{ backgroundColor: "#fff", border: "1px solid #ddd" }}>
                                <div className="title">Sales Book</div>
                                <div className="dk-textPanel">
                                    <table className="table dkg-accounting-boxtable">

                                        <tbody>
                                            {
                                                invoiceCaseStatusList.map((statusItem, index) =>
                                                    <tr>
                                                        {
                                                            (() => {
                                                                if (statusItem.value == "Open Invoice")
                                                                    return (<>
                                                                        <td className="dkg-orange-color">{statusItem.value} </td>
                                                                        {
                                                                            userInfo.role === "Super Admin" ? <><td className="dkg-orange-color">
                                                                            {
                                                                                invoiceDataList.filter(function (val) {
                                                                                    return val.caseStatus === statusItem.value;
                                                                                }).length
                                                                            }
                                                                        </td></> : 
                                                                         <>
                                                                         <td className="dkg-orange-color">xxxx</td>
                                                                         </>
                                                                        }
                                                                        
                                                                    </>)
                                                                else if (statusItem.value == "Closed Invoice")
                                                                    return (<>
                                                                        <td className="dkg-green-color">{statusItem.value}</td>
                                                                     
                                                                        {
                                                                            userInfo.role === "Super Admin" ? <><td className="dkg-green-color">
                                                                            {
                                                                                invoiceDataList.filter(function (val) {
                                                                                    return val.caseStatus === statusItem.value;
                                                                                }).length
                                                                            }
                                                                        </td></> : 
                                                                         <>
                                                                         <td className="dkg-green-color">xxxx</td>
                                                                         </>
                                                                        }
                                                                    </>)
                                                                else
                                                                    return (<>
                                                                        <td className="dkg-red-color">{statusItem.value}</td>
                                                                        
                                                                         {
                                                                            userInfo.role === "Super Admin" ? <><td className="dkg-red-color">
                                                                            {
                                                                                invoiceDataList.filter(function (val) {
                                                                                    return val.caseStatus === statusItem.value;
                                                                                }).length
                                                                            }
                                                                        </td></> : 
                                                                         <>
                                                                         <td className="dkg-red-color">xxxx</td>
                                                                         </>
                                                                        }

                                                                    </>)
                                                            }
                                                            )()
                                                        }

                                                    </tr>
                                                )
                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="dk-portalBox dkg-account-grey-boxs" style={{ backgroundColor: "#fff", border: "1px solid #ddd" }}>
                                <div className="title">BANK STATEMENT</div>
                                <div className="dk-textPanel">
                                    <table className="table dkg-accounting-boxtable">
                                        <tbody>

                                            <tr>
                                                <td className="dkg-green-color">Total Paid In</td>
                                               
                                                 {
                                                    userInfo.role === "Super Admin" ? 
                                                    <><td className="dkg-green-color">
                                                    {
                                                        (bankingDataList.reduce((a, v) => a = a + Number(v.paidIn), 0)).toFixed(2)
                                                    }
                                                     </td></> : 
                                                    <>
                                                    <td className="dkg-green-color">xxxx</td>
                                                    </>
                                                }
                                            </tr>
                                            <tr>
                                                <td className="dkg-red-color">Total Paid Out</td>
                                              
                                                  {
                                                    userInfo.role === "Super Admin" ? 
                                                    <><td className="dkg-red-color">
                                                    {
                                                        (bankingDataList.reduce((a, v) => a = a + Number(v.paidOut), 0)).toFixed(2)
                                                    }
                                                     </td></> : 
                                                    <>
                                                    <td className="dkg-red-color">xxxx</td>
                                                    </>
                                                }
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="dk-portalBox dkg-account-grey-boxs" style={{ backgroundColor: "#fff", border: "1px solid #ddd" }}>
                                <div className="title">Cashflow</div>
                                <div className="dk-textPanel">
                                    <table className="table dkg-accounting-boxtable">

                                        <tbody>
                                            <tr>
                                                <td className="dkg-green-color">Total Income</td>
                                                {
                                                     userInfo.role === "Super Admin" ? 
                                                     <>
                                                     <td className="dkg-green-color">
                                                    {
                                                        (listcashflowIncome.reduce((a, v) => a = a + Number(v.jan), 0) + listcashflowIncome.reduce((a, v) => a = a + Number(v.feb), 0) + listcashflowIncome.reduce((a, v) => a = a + Number(v.march), 0) + listcashflowIncome.reduce((a, v) => a = a + Number(v.april), 0) + listcashflowIncome.reduce((a, v) => a = a + Number(v.may), 0) + listcashflowIncome.reduce((a, v) => a = a + Number(v.june), 0) + listcashflowIncome.reduce((a, v) => a = a + Number(v.july), 0) + listcashflowIncome.reduce((a, v) => a = a + Number(v.aug), 0) + listcashflowIncome.reduce((a, v) => a = a + Number(v.sep), 0) + listcashflowIncome.reduce((a, v) => a = a + Number(v.oct), 0) + listcashflowIncome.reduce((a, v) => a = a + Number(v.nov), 0) + listcashflowIncome.reduce((a, v) => a = a + Number(v.dec), 0)).toFixed(2)
                                                    }€
                                                </td>
                                                     </>
                                                     :
                                                     <>
                                                     <td className="dkg-green-color">xxxx</td>
                                                     </>
                                                }
                                                
                                            </tr>
                                            <tr>
                                                <td className="dkg-red-color">Total Expense</td>
                                                {
                                                    userInfo.role === "Super Admin" ? <>
                                                     <td className="dkg-red-color">
                                                        {
                                                            (listCashOutExpense?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.jan), 0) + listCashOutExpense?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.feb), 0) + listCashOutExpense?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.march), 0) + listCashOutExpense?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.april), 0) + listCashOutExpense?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.may), 0) + listCashOutExpense?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.june), 0) + listCashOutExpense?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.july), 0) + listCashOutExpense?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.aug), 0) + listCashOutExpense?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.sep), 0) + listCashOutExpense?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.oct), 0) + listCashOutExpense?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.nov), 0) + listCashOutExpense?.filter(a => a.pid != -1).reduce((a, v) => a = a + Number(v.dec), 0)).toFixed(2)
                                                        }€
                                                </td>
                                                    </>
                                                    :
                                                    <>
                                                    <td className="dkg-red-color">xxxx</td>
                                                    </>
                                                }                                              

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="dk-portalBox dkg-account-grey-boxs" style={{ backgroundColor: "#fff", border: "1px solid #ddd" }}>
                                <div className="title">PAYMENTS</div>
                                <div className="dk-textPanel">
                                    <table className="table dkg-accounting-boxtable">
                                        <tbody>
                                            <tr>
                                                <td className="dkg-orange-color">New Task</td>
                                                {
                                                    userInfo.role === "Super Admin" ? <>
                                                    <td className="dkg-orange-color">8</td>
                                                    </>
                                                    :
                                                    <><td className="dkg-orange-color">xxxx</td></>
                                                }
                                                
                                            </tr>
                                            <tr>
                                                <td className="dkg-green-color">Partial Paid</td>
                                                
                                                {
                                                    userInfo.role === "Super Admin" ? <>
                                                    <td className="dkg-green-color">29.00</td>
                                                    </>
                                                    :
                                                    <><td className="dkg-green-color">xxxx</td></>
                                                }
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div> */}
                        <div className="dk-portalCols">
                            <div className="dk-portalBox dk-payroll-boxbg">
                                <Link to="#" onClick={() => setModule('reports', '#3A9469')}>
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img
                                                src={Reports}
                                                className="img-fluid"
                                                alt="Clients Reports"
                                            />
                                        </div>
                                        <div className="dk-PortalTitle">Reports</div>
                                    </div>
                                </Link>
                            </div>
                            <div className="dk-portalBox dk-banking-boxbg">
                                <Link to="#" onClick={() => setModule('banking', '#692D77')}>
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img
                                                src={Bankwhite}
                                                className="img-fluid"
                                                alt="Bankwhite"
                                            />
                                        </div>
                                        <div className="dk-PortalTitle">Bank Statement</div>
                                    </div>
                                </Link>
                            </div>
                            <div className="dk-portalBox dk-cashflow-boxbg">
                                <Link to="#" onClick={() => setModule('cashflow', '#144773')}>
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img
                                                src={Casflow}
                                                alt="PR"
                                            />
                                        </div>
                                        <div className="dk-PortalTitle">Cashflow </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="dk-portalBox dk-income-boxbg">
                                <Link to="#" onClick={() => setModule('payments', '#483252')}>
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img
                                                src={Clients}
                                                className="img-fluid"
                                                alt="PAYMENTS"
                                            />
                                        </div>
                                        <div className="dk-PortalTitle">Payment Tasks</div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="dk-portalCols">
                            <div className="dk-portalBox dk-payroll-boxbg">
                                <Link to="#" onClick={() => setModule('clients-invoices', '#3A9469')}>
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img
                                                src={Invoices}
                                                className="img-fluid"
                                                alt="Clients Invoices"
                                            />
                                        </div>
                                        <div className="dk-PortalTitle">Sales Book</div>
                                    </div>
                                </Link>
                            </div>
                            <div className="dk-portalBox dk-expense-boxbg">
                                <Link to="#" onClick={() => setModule('expense', '#2e75b6')}>
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img
                                                src={Invoices}
                                                alt="PR"
                                            />
                                        </div>
                                        <div className="dk-PortalTitle">Invoice Book</div>
                                    </div>
                                </Link>
                            </div>
                            
                            <div className="dk-portalBox dk-knowleadge-boxbg">
                                <Link to="#" onClick={() => setModule('tax-returns', '#2e75b6')}>
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img
                                                src={Report}
                                                alt="VAT"
                                            />
                                        </div>
                                        <div className="dk-PortalTitle">Tax Returns</div>
                                    </div>
                                </Link>
                            </div>
                            <div className="dk-portalBox dk-business-overviewbox">
                                {/* <Link to="#" onClick={() => setModule('finance-payments')}> */}
                                <Link to="#" onClick={() => setModule('acc-contracts', '#4c5f59')}>
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img
                                                src={Contracts}
                                                className="img-fluid"
                                                alt="Payroll"
                                                style={{ width: "35px", height: "35px", opacity: "0.9" }}
                                            />
                                        </div>
                                        <div className="dk-PortalTitle">Contracts</div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="dk-portalCols">
                            {/* <div className="dk-portalBox accounting-boxbg">
                                <Link to="#" onClick={() => setModule('accounting', '#9D848E')}>
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img
                                                src={Accounting}
                                                className="img-fluid"
                                                alt=""
                                            />
                                        </div>
                                        <div className="dk-PortalTitle">Company Doc’s</div>
                                    </div>
                                </Link>
                            </div> */}
                            <div className="dk-portalBox dk-knowleadge-boxbg">
                                <Link to="#" onClick={() => setModule('knowledgebase', '#4c5f59')}>
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img
                                                src={Information}
                                                alt="LegalDocs"
                                            />
                                        </div>
                                        <div className="dk-PortalTitle">FAQ</div>
                                    </div>
                                </Link>
                            </div>
                            <div className="dk-portalBox dk-cashflow-boxbg"  style={{opacity: "0"}}>
                                <Link to="#">
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img
                                                src={ExpenseBook}
                                                alt="Expense Book"
                                            />
                                        </div>
                                        <div className="dk-PortalTitle">Expense Book</div>
                                    </div>
                                </Link>
                            </div>
                            <div className="dk-portalBox dk-income-boxbg "  style={{opacity: "0"}}>
                                <Link to="#" >
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img
                                                src={Liability}
                                                className="img-fluid"
                                                alt="Liabilities"
                                            />
                                        </div>
                                        <div className="dk-PortalTitle">Liabilities</div>
                                    </div>
                                </Link>
                            </div>
                            <div className="dk-portalBox dk-banking-boxbg" style={{opacity: "0"}}>
                                <Link to="#" >
                                    <div>
                                        <div className="dk-Portalicons">
                                            <img
                                                src={VatReturns}
                                                className="img-fluid"
                                                alt="Vat Returns"
                                            />
                                        </div>
                                        <div className="dk-PortalTitle">VAT Returns</div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default FinanceDashboard;