import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CheckInIcon from "../../../../../assets/images/checkin_icon.png";
import TestToolIcon from "../../../../../assets/images/testtool_icon.png";
import ReviewMailboxIcon from "../../../../../assets/images/reviewmailbox_icon.png";
import TeamMeetingIcon from "../../../../../assets/images/teammeeting_icon.png";
import WorkPlanIcon from "../../../../../assets/images/troubleshooting.png";
import PreparaActionIcon from "../../../../../assets/images/bell.png";
import SkypeIcon from "../../../../../assets/images/skype.png";
import DkMasterIcon from "../../../../../assets/images/dk_icon.png";
import DkOutlookIcon from "../../../../../assets/images/outlook_icon.png";
import DkEsendexIcon from "../../../../../assets/images/esendex.png";
import DkQuickCallIcon from "../../../../../assets/images/quick-talk.jpg";
import DkTwakioIcon from "../../../../../assets/images/twak-io.png";
import { getTextGetStart } from "../../../../../slice/getStart/getStartSlice"
import { showError } from "../../../../../components/utils/messages/messageSlice";
import './dayclosingmoods.scss';


const Index = () => {

    const dispatch = useDispatch();
    const { isLoading, getStartList } = useSelector(
        (state) => state.getStart
    );
    const [data, setData] = useState([]);

    useEffect(() => {
        if (!isLoading && getStartList && getStartList.length > 0) {
            setData(getStartList);
        }
    }, [isLoading]);

    useEffect(() => {
        async function fetchData() {
            try {
                await dispatch(getTextGetStart({}));
            } catch (err) {
                dispatch(showError({ msg: "Somthings went wrong" }));
            }
        }
        fetchData();
    }, [dispatch]);


    return (
        <React.Fragment>
            <div className="dk-dayclsoing-Main dk-dayclsoing-DetailMain dk-moodsMainContainer dk-moodContainer-main" style={{ backgroundColor: "#fff" }}>
                <div className="dk-detailsMain">
                    <div className="d-md-flex">
                        <div className="dk-detailRightPanel">
                            <div className="dk-detailTabsMain">
                                {/* <h4 className="title mt-2">MORNING KICK-OFF STEPS TO FOLLOW</h4> */}
                                <div className="dk-trainingInformation-cols">
                                    <div className="dk-payDetailRightPanel row">
                                        <div className='col-md-12 pl-0 pr-0'>
                                            <div className='dkg-mainsuMM-RightCon mt-2'>
                                                <div className='dk-workplansimileCon h-auto mt-0'>
                                                    <div className='dkg-workplanemo-Body'>
                                                        <div className='dkg-getstarted-pageCon'>
                                                            <div className='col-md-4 pl-0'>
                                                                <div className='dkg-getstarted-rightCon'>
                                                                    <div className='dkg-getstarted-rightboxCon'>
                                                                        <div className='dkg-getstarted-Header'>
                                                                            <h5 className='dkg-getstarted-Title'>{data != "" ? data[0].title : ""}</h5>
                                                                        </div>
                                                                        <div className='dkg-getstarted-Body'>
                                                                            <div className="dk-getstartedLogin-table">
                                                                                <table className="dk-getstartedLogin-table w-100">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td className="text-center" style={{ border: "none" }}>{data != "" ? data[1].title : ""}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                                <table className="table table-bordered dkg-step-whiteTable">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>1</td>
                                                                                            <td>
                                                                                                <div className="dk-softIcon">
                                                                                                    <img src={DkMasterIcon} alt="" />
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>DKG CRM</td>
                                                                                            <td>For Workflow</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>2</td>
                                                                                            <td>
                                                                                                <div className="dk-softIcon">
                                                                                                    <img src={SkypeIcon} alt="" />
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>Skype</td>
                                                                                            <td>For Chats</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>3</td>
                                                                                            <td>
                                                                                                <div className="dk-softIcon">
                                                                                                    <img src={DkOutlookIcon} alt="" />
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>Outlook</td>
                                                                                            <td>For Emails</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>4</td>
                                                                                            <td>
                                                                                                <div className="dk-softIcon"> 
                                                                                                    <img src={DkEsendexIcon} alt="DkEsendexIcon" />
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>Exendex</td>
                                                                                            <td>For SMS</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>5</td>
                                                                                            <td>
                                                                                               <div className="dk-softIcon"> 
                                                                                                    <img src={DkQuickCallIcon} alt="DkQuickCallIcon" />
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>QuickTalk</td>
                                                                                            <td>For Calls</td>
                                                                                        </tr>

                                                                                        <tr>
                                                                                            <td>6</td>
                                                                                            <td>
                                                                                                <div className="dk-softIcon"> 
                                                                                                    <img src={DkTwakioIcon} alt="DkTwakioIcon" />
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>Tawk.To</td>
                                                                                            <td>For Chats</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>7</td>
                                                                                            <td>
                                                                                                <div className="dk-softIcon"> 
                                                                                                    <img src={DkTwakioIcon} alt="DkTwakioIcon" />
                                                                                                </div>
                                                                                            </td>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>8</td>
                                                                                            <td>
                                                                                                <div className="dk-softIcon"> 
                                                                                                    <img src={DkTwakioIcon} alt="DkTwakioIcon" />
                                                                                                </div>
                                                                                            </td>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>9</td>
                                                                                            <td>
                                                                                               
                                                                                            </td>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>10</td>
                                                                                            <td>
                                                                                               
                                                                                            </td>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>11</td>
                                                                                            <td>
                                                                                                
                                                                                            </td>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                            {/* <div className="dk-getstartedLogin-table mt-3">
                                                                                <table className="dk-getstartedLogin-table w-100">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td className="text-center" style={{ fontWeight: '600', color: "#646060" }}>Workload</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                                <table className="table table-bordered dkg-step-whiteTable">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                            <td></td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div> */}
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className='dkg-getstarted-rightboxCon' style={{ marginTop: "30px" }}>
                                                                        <div className='dkg-getstarted-Header'>
                                                                            <h5 className='dkg-getstarted-Title twooo'>{data != "" ? data[3].title : ""}</h5>
                                                                        </div>
                                                                        <div className='dkg-getstarted-Body dkg-step-six-block'>
                                                                            <div className="d-flex align-items-center w-100">
                                                                                <div className="dk-getStartedIcon">
                                                                                    <div className="title">{data != "" ? data[4].title : ""}</div>
                                                                                    <img src={WorkPlanIcon} alt="" />
                                                                                </div>
                                                                                <h5 className='dk-getstartedbox-title'>{data != "" ? data[5].title : ""}</h5>
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                            <div className='col-md-8 mr-0'>
                                                                <div className='dkg-getstarted-rowCon'>
                                                                    <div className='dkg-getstarted-leftCon'>
                                                                        <div className='dkg-getstarted-boxCon'>
                                                                            <div className='dkg-getstarted-Header'>
                                                                                <h5 className='dkg-getstarted-Title threeee'>{data != "" ? data[6].title : ""}</h5>
                                                                            </div>
                                                                            <div className='dkg-getstarted-Body'>
                                                                                <div className="d-flex align-items-center w-100">
                                                                                    <div className="dk-getStartedIcon">
                                                                                        <div className="title">{data != "" ? data[7].title : ""}</div>
                                                                                        <img src={TestToolIcon} alt="" />
                                                                                    </div>
                                                                                    <h5 className='dk-getstartedbox-title'>{data != "" ? data[8].title : ""}</h5>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='dkg-getstarted-boxCon'>
                                                                            {/* <div className='dkg-getstarted-Header'>
                                                                            <h5 className='dkg-getstarted-Title'>STEP 1</h5>
                                                                        </div> */}
                                                                            {/* <div className='dkg-getstarted-Body'></div> */}
                                                                            <div className='dkg-getstarted-boxCon'>
                                                                                <div className='dkg-getstarted-Header'>
                                                                                    <h5 className='dkg-getstarted-Title tesst'>{data != "" ? data[15].title : ""}</h5>
                                                                                </div>
                                                                                <div className='dkg-getstarted-Body dkg-step-six-block'>
                                                                                    <div className="d-flex align-items-center w-100">
                                                                                        <div className="dk-getStartedIcon">
                                                                                            <div className="title">{data != "" ? data[16].title : ""}</div>
                                                                                            <img src={ReviewMailboxIcon} alt="" />
                                                                                        </div>
                                                                                        <h5 className='dk-getstartedbox-title'>{data != "" ? data[17].title : ""}</h5>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='dkg-getstarted-rowCon'>
                                                                    <div className='dkg-getstarted-leftCon'>
                                                                        <div className='dkg-getstarted-boxCon'>
                                                                            <div className='dkg-getstarted-Header'>
                                                                                <h5 className='dkg-getstarted-Title fourrrr'>{data != "" ? data[9].title : ""}</h5>
                                                                            </div>
                                                                            <div className='dkg-getstarted-Body'>
                                                                                <div className="d-flex align-items-center w-100">
                                                                                    <div className="dk-getStartedIcon">
                                                                                        <div className="title">{data != "" ? data[10].title : ""}</div>
                                                                                        <img src={CheckInIcon} alt="" />
                                                                                    </div>
                                                                                    <h5 className='dk-getstartedbox-title'>{data != "" ? data[11].title : ""}</h5>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='dkg-getstarted-boxCon'>
                                                                            <div className='dkg-getstarted-Header'>
                                                                                <h5 className='dkg-getstarted-Title sixxx'>{data != "" ? data[18].title : ""}</h5>
                                                                            </div>
                                                                            <div className='dkg-getstarted-Body'>
                                                                                <div className="d-flex align-items-center w-100">
                                                                                    <div className="dk-getStartedIcon">
                                                                                        <div className="title">{data != "" ? data[19].title : ""}</div>
                                                                                        <img src={TestToolIcon} alt="" />
                                                                                    </div>
                                                                                    <h5 className='dk-getstartedbox-title'>{data != "" ? data[20].title : ""}</h5>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='dkg-getstarted-rowCon'>
                                                                    <div className='dkg-getstarted-leftCon'>
                                                                        <div className='dkg-getstarted-boxCon'>
                                                                            <div className='dkg-getstarted-Header'>
                                                                                <h5 className='dkg-getstarted-Title five'>{data != "" ? data[12].title : ""}</h5>
                                                                            </div>
                                                                            <div className='dkg-getstarted-Body'>
                                                                                <div className="d-flex align-items-center w-100">
                                                                                    <div className="dk-getStartedIcon">
                                                                                        <div className="title">{data != "" ? data[13].title : ""}</div>
                                                                                        <img src={PreparaActionIcon} alt="" />
                                                                                    </div>
                                                                                    <h5 className='dk-getstartedbox-title'>{data != "" ? data[14].title : ""}</h5>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='dkg-getstarted-boxCon'>
                                                                            <div className='dkg-getstarted-Header'>
                                                                                <h5 className='dkg-getstarted-Title sevennn'>{data != "" ? data[21].title : ""}</h5>
                                                                            </div>
                                                                            <div className='dkg-getstarted-Body'>
                                                                                <div className="d-flex align-items-center w-100">
                                                                                    <div className="dk-getStartedIcon">
                                                                                        <div className="title">{data != "" ? data[22].title : ""}</div>
                                                                                        <img src={TeamMeetingIcon} alt="" />
                                                                                    </div>
                                                                                    <h5 className='dk-getstartedbox-title'>{data != "" ? data[23].title : ""}</h5>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Index;
