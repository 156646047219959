import React, { useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom'
import './actionrecord.scss'
import Modal from 'react-bootstrap/Modal'
import TodoTrackingTab from './TodoTrackingTab'
//import Recruiter from '../../../../../assets/images/deepak.png'
import moment from "moment-timezone";
const ActionRecord = () => {
    const { userInfo } = useSelector(state => state.auth)
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [renderTodo, setRenderTodo] = useState(1)
    const [rowId, setRowId] = useState(0)
    const handleSelect = (key) => {
        setRowId(0)
        switch (key) {
            case 'todo':
                setRenderTodo(1)
                break;

            case 'notes':
                setRowId(1)
                break;
        }
    }

    return (
        <>
            {/* <Link to="#" onClick={handleShow}><i className="fas fa-calendar"></i></Link> */}
            <Link to="#" onClick={handleShow} className="dk-cricleBtn"><i className="fas fa-plus"></i></Link>
            <Modal show={show} onHide={handleClose} className="dkg-create-todo-task-modal" >
                <Modal.Header closeButton className=' dkg-create-todo-task-modal-header'>
                    <div className="dk-userimgCon d-none">
                        <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + userInfo.profileImage} className="dk-userimg" alt="User" />
                    </div>
                    <Modal.Title>CREATE TASKS  - {moment(new Date()).format("Do MMM, ddd")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="dk-actonrecordModalBody dkg-create-todo-task-modal-body">
                    <TodoTrackingTab keyId="1" />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ActionRecord