import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import './supplierdashboard.scss';
import CompanyIcon from "../../../../../assets/images/building_icon.png";
// import EmployeesIcon from "../../../../../assets/images/enterprise.png";
// import ContractsIcon from "../../../../../assets/images/contracts.png";
// import LoginsIcon from "../../../../../assets/images/login.png";
import FaqIcon from "../../../../../assets/images/information_white.png";
// import HrIcon from "../../../../../assets/images/hr_white.png";
// import KpiIcon from "../../../../../assets/images/kpi-white.png";
import ResearchIcon from "../../../../../assets/images/resourcemanagement_icon.png";
import BusgetIcon from "../../../../../assets/images/euro_icon.png";
import ReportIcon from "../../../../../assets/images/report_white.png";
import ItsupportIcon from "../../../../../assets/images/it_white.png";

import { useDispatch, useSelector } from "react-redux";
import { setMyModule } from "../../../../../slice/modules/moduleSlice";
import Moduleheader from "../../../../elements/header/index";

const SupplierDashboard = () => {
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.auth);

    const history = useHistory();
    const setModule = async (myModule, moduleColor) => {
        await dispatch(setMyModule({ module: myModule, color: moduleColor }))
        switch (myModule) {
            case 'Resource':
                history.push("/resources/summary");
                break;

            case 'Training':
                history.push("/training/get-started/overview");
                break;

            case 'resource-hr':
                history.push("/resource/resource-hr/dashboard");
                break;


            default:
                break;
        }
    }
    return (
        <>
            <Moduleheader mainheaderLink="Dahsboard -" moduleLink="/dashboard/plateform" linkcolor="#3c4c62" mainheaderTitle=" SUPPLIERS" textcolor="#01796f" />
            <div className="dk-vendorModulemain">
                <div className="dk-venderModule-container">
                    <div className="title mb-2">SUPPLIERS DEPARTMENT</div>
                    <div className="d-flex">
                        <div className="dk-hrModule-box" style={{opacity: "0"}}>
                            <Link to="#" style={{ background: '#01796f' }}>
                                <img src={CompanyIcon} alt="" />
                                <p>IT Services</p>
                            </Link>
                        </div>
                        <div className="dk-hrModule-box">
                            <Link to="#" onClick={() => setModule('Training')} style={{ background: '#01796f' }}>
                                <img src={ReportIcon} alt="" />
                                <p>Job Boards</p>
                            </Link>
                        </div>
                        <div className="dk-hrModule-box" style={{opacity: "0"}}>
                            <Link to="#" style={{ background: '#01796f' }}>
                                <img src={ItsupportIcon} alt="" />
                                <p>IT Support</p>
                            </Link>
                        </div>

                    </div>
                    <div className="d-flex">
                        <div className="dk-hrModule-box d-none">
                            <Link to="#" style={{ background: '#01796f', pointerEvents: 'none' }}>

                            </Link>
                        </div>
                        <div className="dk-hrModule-box d-none">
                            <Link to="#" style={{ background: '#01796f', pointerEvents: 'none' }}>

                            </Link>
                        </div>
                        <div className="dk-hrModule-box d-none">
                            <Link to="#" style={{ background: '#01796f', pointerEvents: 'none' }}>

                            </Link>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="dk-hrModule-box d-none">
                            <Link to="#" style={{ background: '#01796f' }}>
                                <img src={ResearchIcon} alt="" />
                                <p>Research</p>
                            </Link>
                        </div>
                        <div className="dk-hrModule-box d-none">
                            <Link to="#" style={{ background: '#01796f' }}>
                                <img src={BusgetIcon} alt="" />
                                <p>Budget</p>
                            </Link>
                        </div>
                        <div className="dk-hrModule-box d-none">
                            <Link to="#" style={{ background: '#01796f' }}>
                                <img src={FaqIcon} alt="" />
                                <p>FAQ</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SupplierDashboard;
