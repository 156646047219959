import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SubmitButton from "../../../ui/submitButton";
import {
  faEnvelope,
  faPaperclip,
  faXmark,
  faPaperPlane,
  faSignature,
  faFloppyDisk,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import IconButton from "./utils/button/IconSubmitButton";
import {
  sendMail,
  clearState,
  getUserMailboxSignature,
  sendBulkMail,
} from "../../../../slice/mailbox/mailboxSlice";
import {
  showSuccess,
  showError,
  clearMessage,
} from "../../../../slice/utils/message/messageSlice";
import { useDispatch, useSelector } from "react-redux";
import { Form, Row } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { Autocomplete, TextField } from "@mui/material";
import { GetData } from "../../../../services";
import { createFilterOptions } from "@material-ui/lab";
import SendEmail from "./editor/SendEmail";
import ReciveEmail from "./editor/ReciveEmail";
import MessageBox from "./editor/MessageBox";

const ComposeMail = (props) => {
  const dispatch = useDispatch();
  const { isLoading, isBtnSuccess, message, signature } = useSelector(
    (state) => state.mailbox
  );
  const { userInfo } = useSelector((state) => state.auth);
  const fileInput = useRef();

  const [mailList, setMailList] = useState([]);
  const [tempMailList, setTempMailList] = useState([]);
  const [from, setFrom] = useState("jobs@dkgrecruitment.com");
  const [to, setTo] = useState([]);
  const [cc, setCc] = useState([]);
  const [bcc, setBCc] = useState([]);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [isAttachment, setIsAttachment] = useState(1);
  const [attachment, setAttachment] = useState([]);
  const [displayFileAttachment, setDisplayFileAttachment] = useState([]);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fileName, setFileName] = useState("");
  const [currentAccount, setCurrentAccount] = useState("Form");

  const [eventKey, SetEventKey] = useState("tabs_1");

  const filterOptions = createFilterOptions({
    limit: 30,
  });

  useEffect(() => {
    if (props.show) {
      clearForm();
      getMailList();
      if (eventKey == "tabs_1" && currentAccount.id) {
        dispatch(
          getUserMailboxSignature({ id: currentAccount.id, type: "compose" })
        );
      }
      if (props.toList.length > 0) {
        console.log(props.toList);
        setTo(props.toList);
      }
    }
  }, [props.show]);

  useEffect(() => {
    if (eventKey) {
      switch (eventKey) {
        case "send_email":
          dispatch(
            getUserMailboxSignature({ id: currentAccount.id, type: "compose" })
          );
          break;

        case "receive_email":
          dispatch(
            getUserMailboxSignature({ id: currentAccount.id, type: "reply" })
          );
          break;
      }
    }
  }, [eventKey]);

  useEffect(() => {
    if (props.currentAccount) {
      setCurrentAccount(props.currentAccount);
    }
  }, [props.currentAccount]);

  const getMailList = async () => {
    try {
      const res = await GetData(true, "/mail/candidates/list");
      const mailList = res.payload.result;
      if (mailList.length > 0) {
        setTempMailList(mailList);
        setMailList(mailList.map((item) => item.email1));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fileUploadHandler = (e) => {
    for (const item of e.target.files) {
      attachment.push(item);
    }
    if (attachment.length > 1) {
      setFileName(attachment.length + " files");
    } else {
      setFileName(e.target.files[0].name);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccess(false);
    setLoading(true);
    if (currentAccount) {
      if (to.length == 0 || !subject || !body) {
        dispatch(showError({ msg: "All fields required" }));
        setLoading(false);
      } else {
        const formData = new FormData();
        // formData.append("uid", userInfo.id);
        formData.append("from", currentAccount.id);
        // formData.append("from", from);
        to.forEach((item, index) => {
          formData.append("to[" + index + "][email]", item.email);
          formData.append("to[" + index + "][name]", item.name);
        });
        cc.forEach((item, index) => {
          formData.append("cc[" + index + "][email]", item.email);
          formData.append("cc[" + index + "][name]", item.name);
        });
        bcc.forEach((item, index) => {
          formData.append("bcc[" + index + "][email]", item.email);
          formData.append("bcc[" + index + "][name]", item.name);
        });
        formData.append("subject", subject);
        formData.append("html", body);
        // console.log(attachment.length);
        if (attachment.length > 0) {
          formData.append("isAttachment", 1);
          console.log(attachment);
          // formData.append("attachments", attachment);
          // for (let i = 0; i < attachment.length; i++) {
          // }
          attachment.forEach((file) => {
            // Append each attachment as an array with the same key
            formData.append("attachments", file);
          });
        }
        if (props.type == "bulk") {
          await dispatch(sendBulkMail(formData));
        } else {
          await dispatch(sendMail(formData));
        }
      }
    }
  };

  const clearForm = () => {
    // setLoading(false);
    setFileName("");
    setTo([]);
    setCc([]);
    setBCc([]);
    setSubject("");
    setBody("");
    // fileInput.current.value = ''
    setIsAttachment(0);
    setAttachment([]);
    setDisplayFileAttachment([]);
  };

  const deleteImg = () => {
    setFileName("");
    fileInput.current.value = "";
    setIsAttachment(0);
    setAttachment([]);
    setDisplayFileAttachment([]);
  };

  useEffect(() => {
    if (isBtnSuccess) {
      // setLoading(false);
      setFileName("");
      setTo([]);
      setCc([]);
      setBCc([]);
      setSubject("");
      setBody("");
      // fileInput.current.value = ''
      setIsAttachment(0);
      setLoading(false);
      setAttachment([]);
      setDisplayFileAttachment([]);
      dispatch(showSuccess({ msg: message }));
      props.onHide();
      dispatch(clearState());
    }
  }, [isBtnSuccess]);

  const addFrom = (data) => {
    setFrom(data);
  };

  const createNewFilterOptions = (options, params) => {
    const filtered = filterOptions(options, params);
    const { inputValue } = params;
    const isExisting = options.some((option) => inputValue === option);
    if (inputValue !== "" && !isExisting) {
      filtered.push(inputValue);
    }
    return filtered;
  };

  const selectTo = (event, selectedList) => {
    const data = selectedList.map((sEmail) => {
      const findName = tempMailList.find((email) => sEmail == email.email1);
      return {
        email: sEmail,
        name: findName ? findName.name : sEmail,
      };
    });
    console.log(data);
    setTo(data);
  };

  const selectCc = (event, selectedList) => {
    const data = selectedList.map((sEmail) => {
      const findName = tempMailList.find((email) => sEmail == email.email1);
      return {
        email: sEmail,
        name: findName ? findName.name : sEmail,
      };
    });
    setCc(data);
  };

  const selectBcc = (event, selectedList) => {
    const data = selectedList.map((sEmail) => {
      const findName = tempMailList.find((email) => sEmail == email.email1);
      return {
        email: sEmail,
        name: findName ? findName.name : sEmail,
      };
    });
    setBCc(data);
  };

  return (
    <>
      <Modal className="dk-composeMailModal right" {...props}>
        <Modal.Header closeButton>
          {/* <button className="dk-saveBtn" title="Save">
            <FontAwesomeIcon icon={faFloppyDisk} />
          </button> */}
          <div className="dk-composeMail-PanelCols">
            <div className="dk-mailAttach-file">
              {/* <input type="file" name="" id="" name="attachment-files" onChange={fileUploadHandler} ref={fileInput} multiple /> */}
              <span>
                <FontAwesomeIcon icon={faPaperclip} />
              </span>
              <Form.File
                type="file"
                className="custom-file-label"
                ref={fileInput}
                id="inputGroupFile01"
                label={fileName}
                onClick={(e) => setAttachment([])}
                onChange={fileUploadHandler}
                multiple
                custom
              />
              {/* <span><FontAwesomeIcon icon={faPaperclip} /> Attach File</span> */}
            </div>
          </div>
          <Modal.Title>COMPOSE NEW EMAIL</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dkg-compose-email-body">
          <Tab.Container id="left-tabs-example" defaultActiveKey="tabs_1">
            <div className="d-flex dkg-compose-email-block">
              <div className="dk-mailboxLeft-panel dkg-compose-bottom-panel">
                <Nav
                  variant=""
                  onSelect={(selectedKey) => SetEventKey(selectedKey)}
                  className="dkg-compose-email-navpills"
                >
                  <Nav.Item className="dkg-compose-email-navitem">
                    <Nav.Link
                      className="dkg-compose-email-navlink"
                      eventKey="tabs_1"
                    >
                      New Email
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-compose-email-navitem">
                    <Nav.Link
                      className="dkg-compose-email-navlink"
                      eventKey="tabs_2"
                    >
                      Mailshot
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-compose-email-navitem">
                    <Nav.Link
                      className="dkg-compose-email-navlink"
                      eventKey="tabs_3"
                    >
                      Templates
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-compose-email-navitem">
                    <Nav.Link
                      className="dkg-compose-email-navlink"
                      eventKey="send_email"
                    >
                      New Signature
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="dkg-compose-email-navitem">
                    <Nav.Link
                      className="dkg-compose-email-navlink"
                      eventKey="receive_email"
                    >
                      Reply Signature
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <div className="dk-mailboxRight-panel">
                <Tab.Content>
                  <Tab.Pane eventKey="tabs_1">
                    <div className="dk-composeMailForm">
                      <form
                        onSubmit={handleSubmit}
                        enctype="multipart/form-data"
                      >
                        <div className="d-flex">
                          <div className="dk-mailField">
                            <div className="dkg-compose-mailbox-con">
                              <div className="dkg-compose-mail-left-con">
                                <div className="dk-mailSendOption">
                                  <div className="dk-mailOption-items">
                                    From
                                  </div>
                                  <input
                                    type="text"
                                    value={currentAccount?.domain}
                                    className="form-control readonly"
                                  />
                                  <div className="dk-attachFile-cols">
                                    {/* {
                                                                        displayFileAttachment.map((item, index) =>
                                                                            <div className="attachItems" key={index}>{item.name} <span><FontAwesomeIcon icon={faXmark} /></span></div>
                                                                        )
                                                                    } */}
                                  </div>
                                  <div className="dk-composeMail-option">
                                    <div className="d-flex align-items-center justify-content-end">
                                      {/* <div className="dk-composeMail-PanelCols">
                                      <div className="dk-mailAttach-file">
                                        <span>
                                          <FontAwesomeIcon icon={faPaperclip} />
                                        </span>
                                        <Form.File
                                          type="file"
                                          className="custom-file-label"
                                          ref={fileInput}
                                          id="inputGroupFile01"
                                          label={fileName}
                                          onClick={(e) => setAttachment([])}
                                          onChange={fileUploadHandler}
                                          multiple
                                          custom
                                        />
                                      </div>
                                    </div> */}
                                      <div className="dk-composeMail-PanelCols">
                                        <div className="dk-mailAttach-file">
                                          <Link
                                            to="#"
                                            onClick={deleteImg}
                                            className="d-none"
                                          >
                                            <FontAwesomeIcon
                                              icon={faTrashAlt}
                                            />{" "}
                                            Delete
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="dk-mailSendOption">
                                  <div className="dk-mailOption-items">
                                    To...
                                  </div>
                                  {/*<input*/}
                                  {/*  type="text"*/}
                                  {/*  className="form-control"*/}
                                  {/*  value={to}*/}
                                  {/*  onChange={(e) => setTo(e.target.value)}*/}
                                  {/*/>*/}
                                  <Autocomplete
                                    disablePortal
                                    value={to.map((to) => to.email)}
                                    freeSolo
                                    multiple
                                    getOptionLabel={(option) => option}
                                    className="form-control dk-autocomplete-input m-lb-5x"
                                    options={mailList}
                                    filterOptions={createNewFilterOptions}
                                    onChange={selectTo}
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                  />
                                  {/* <div className="dk-mailsTagMain">
                                                                <div className="dk-mailtags">mukul@gmail.com
                                                                    <span className="dk-mailTag-remove"><FontAwesomeIcon icon={faXmark} /></span>
                                                                </div>
                                                                <div className="dk-mailtags">deepak@dkmassterbox.com
                                                                    <span className="dk-mailTag-remove"><FontAwesomeIcon icon={faXmark} /></span>
                                                                </div>
                                                            </div> */}
                                </div>
                                <div className="dk-mailSendOption">
                                  <div className="dk-mailOption-items">
                                    Cc...
                                  </div>
                                  {/*<input*/}
                                  {/*  type="text"*/}
                                  {/*  className="form-control"*/}
                                  {/*  value={cc}*/}
                                  {/*  onChange={(e) => setCc(e.target.value)}*/}
                                  {/*/>*/}
                                  <Autocomplete
                                    disablePortal
                                    multiple
                                    freeSolo
                                    getOptionLabel={(option) => option}
                                    className="form-control dk-autocomplete-input m-lb-5x"
                                    options={mailList}
                                    filterOptions={createNewFilterOptions}
                                    onChange={selectCc}
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                  />
                                  {/* <div className="dk-mailsTagMain">
                                                                <div className="dk-mailtags">mukul@gmail.com
                                                                    <span className="dk-mailTag-remove"><FontAwesomeIcon icon={faXmark} /></span>
                                                                </div>
                                                                <div className="dk-mailtags">deepak@dkmassterbox.com
                                                                    <span className="dk-mailTag-remove"><FontAwesomeIcon icon={faXmark} /></span>
                                                                </div>
                                                            </div> */}
                                </div>
                                <div className="dk-mailSendOption">
                                  <div className="dk-mailOption-items">
                                    Bcc...
                                  </div>
                                  {/*<input*/}
                                  {/*  type="text"*/}
                                  {/*  className="form-control"*/}
                                  {/*  onChange={(e) => setBCc(e.target.value)}*/}
                                  {/*/>*/}
                                  <Autocomplete
                                    disablePortal
                                    multiple
                                    freeSolo
                                    getOptionLabel={(option) => option}
                                    className="form-control dk-autocomplete-input m-lb-5x"
                                    options={mailList}
                                    filterOptions={createNewFilterOptions}
                                    onChange={selectBcc}
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                  />
                                  {/* <div className="dk-mailsTagMain">
                                                                <div className="dk-mailtags">mukul@gmail.com
                                                                    <span className="dk-mailTag-remove"><FontAwesomeIcon icon={faXmark} /></span>
                                                                </div>
                                                                <div className="dk-mailtags">deepak@dkmassterbox.com
                                                                    <span className="dk-mailTag-remove"><FontAwesomeIcon icon={faXmark} /></span>
                                                                </div>
                                                            </div> */}
                                </div>
                              </div>
                              <div className="dkg-compose-mail-right-con">
                                <div className="dkg-cand-mail-attachtment-ul">
                                   
                                  <li className="dkg-cand-mail-attachtment-li">
                                    <div className="dkg-file-typeicon">
                                      <i className="far fa-file-pdf"></i>
                                    </div>
                                    <div className="dkg-cand-mail-file">
                                      <div className="dkg-cand-mail-file-name">
                                        logo192.png
                                      </div>
                                      <div className="dkg-cand-mail-cross-icon">
                                        <i className="fas fa-eye"></i>
                                      </div>
                                      <div className="dkg-cand-mail-cross-icon">
                                        <i className="fas fa-times"></i>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="dkg-cand-mail-attachtment-li">
                                    <div className="dkg-file-typeicon">
                                      <i className="far fa-file-pdf"></i>
                                    </div>
                                    <div className="dkg-cand-mail-file">
                                      <div className="dkg-cand-mail-file-name">
                                        logo192.png
                                      </div>
                                      <div className="dkg-cand-mail-cross-icon">
                                        <i className="fas fa-eye"></i>
                                      </div>
                                      <div className="dkg-cand-mail-cross-icon">
                                        <i className="fas fa-times"></i>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="dkg-cand-mail-attachtment-li">
                                    <div className="dkg-file-typeicon">
                                      <i className="far fa-file-pdf"></i>
                                    </div>
                                    <div className="dkg-cand-mail-file">
                                      <div className="dkg-cand-mail-file-name">
                                        logo192.png
                                      </div>
                                      <div className="dkg-cand-mail-cross-icon">
                                        <i className="fas fa-eye"></i>
                                      </div>
                                      <div className="dkg-cand-mail-cross-icon">
                                        <i className="fas fa-times"></i>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="dkg-cand-mail-attachtment-li">
                                    <div className="dkg-file-typeicon">
                                      <i className="far fa-file-pdf"></i>
                                    </div>
                                    <div className="dkg-cand-mail-file">
                                      <div className="dkg-cand-mail-file-name">
                                        logo192.png
                                      </div>
                                      <div className="dkg-cand-mail-cross-icon">
                                        <i className="fas fa-eye"></i>
                                      </div>
                                      <div className="dkg-cand-mail-cross-icon">
                                        <i className="fas fa-times"></i>
                                      </div>
                                    </div>
                                  </li>
                                </div>
                              </div>
                            </div>
                            <div className="dk-mailSendOption">
                              <div className="dk-mailOption-items br-none">
                                Subject
                              </div>
                              <div className="d-flex w-100">
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) => setSubject(e.target.value)}
                                />
                                <div className="dk-mailsCategory">
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      variant=""
                                      id="dropdown-basic"
                                    >
                                      Select Template
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item href="#/action-1">
                                        Category 1
                                      </Dropdown.Item>
                                      <Dropdown.Item href="#/action-2">
                                        Category 2
                                      </Dropdown.Item>
                                      <Dropdown.Item href="#/action-3">
                                        Category 3
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="dk-sendMail-btn">
                                                        <IconButton txt="Send" loading={loading} success={success} onClick={handleSubmit} position="justify-content-center" />
                                                    </div> */}
                        </div>
                        <div className="dk-textCompose-mail">
                          <div className="dk-mailAttach-file d-none">
                            {/* <Link to="#"><FontAwesomeIcon icon={faSignature} /> Add Signature</Link> */}
                            <Dropdown>
                              <Dropdown.Toggle variant="" id="dropdown-basic">
                                <FontAwesomeIcon icon={faSignature} /> Add
                                Signature
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  DK Global
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  Deepak Kumar
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-3">
                                  DK Masterbox
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <MessageBox
                            signature={signature}
                            onChange={(value) => setBody(value)}
                          />
                          <div className="dk-send-email-btnCon">
                            {/* <FontAwesomeIcon icon={faPaperPlane} />
                            <IconButton

                            /> */}
                            <SubmitButton
                              txt="Send"
                              loading={isLoading}
                              success={isBtnSuccess}
                              position="justify-content-center"
                            />
                          </div>
                          {/* <CKEditor
                            editor={ClassicEditor}
                            data=""
                            onReady={(editor) => {
                              // You can store the "editor" and use when it's needed.
                              console.log("Editor is ready to use!", editor);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setBody(data);
                            }}
                          /> */}
                        </div>
                        <div className="dk-composeMail-option">
                          <div className="d-flex align-items-center justify-content-between">
                            {/* <div className="dk-composeMail-PanelCols">
                                                            <div className="dk-mailAttach-file">
                                                                <span><FontAwesomeIcon icon={faPaperclip} /></span>
                                                                <Form.File
                                                                    type="file"
                                                                    className="custom-file-label"
                                                                    id="inputGroupFile01"
                                                                    label={fileName}
                                                                    onChange={(e) => setFileName(e.target.files[0].name)}
                                                                    custom
                                                                />

                                                            </div>

                                                        </div> */}
                            <div className="dk-composeMail-PanelCols">
                              {/* <div className="dk-mailAttach-file">
                                                                <Link to="#"><FontAwesomeIcon icon={faTrashAlt} onClick={clearForm} /> Delete</Link>
                                                            </div> */}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tabs_2"></Tab.Pane>
                  <Tab.Pane eventKey="tabs_2"></Tab.Pane>
                  <Tab.Pane eventKey="send_email">
                    <SendEmail mailboxUserId={currentAccount?.id} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="receive_email">
                    <ReciveEmail mailboxUserId={currentAccount?.id} />
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ComposeMail;
