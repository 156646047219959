import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './rightmodalfilter.scss';
import { Modal, Nav, Tab } from 'react-bootstrap';
import { MdInsertDriveFile } from "react-icons/md";
import { FaLevelUpAlt, FaCheck, FaCheckCircle, FaSpinner, FaRegDotCircle } from "react-icons/fa";

const RightModalFilter = ({ list, selectUserFilter, selectOtherFilter }) => {
    const [raUserList, setRaUserList] = useState([]);
    const [rcUserList, setRcUserList] = useState([]);
    const [amUserList, setAmUserList] = useState([]);
    const [monthsList, setMonthList] = useState([]);
    const [yearList, setYearList] = useState([]);
    const [cpList, setCpList] = useState([]);
    const [csList, setCsList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [langList, setLangList] = useState([]);
    const [cvSourceList, setCvSourceList] = useState([]);

    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    }

    const handleShow = () => {
        setShow(true);
    }

    useEffect(() => {
        const raResult = [];
        const ramap = new Map();
        for (const item of list) {
            if (!ramap.has(item.original.recAdminInfo.id)) {
                ramap.set(item.original.recAdminInfo.id, true);
                raResult.push({
                    id: item.original.recAdminInfo.uid,
                    name: item.original.recAdminInfo.firstName,
                    userImg: item.original.recAdminInfo.profileImage,
                    count: list.filter(function (val) {
                        return val.original.recAdminInfo.uid === item.original.recAdminInfo.uid;
                    }).length
                });
            }
        }
        raResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setRaUserList(raResult)

        const rcResult = [];
        const rcmap = new Map();
        for (const item of list) {
            if (!rcmap.has(item.original.recInfo.id)) {
                rcmap.set(item.original.recInfo.id, true);
                rcResult.push({
                    id: item.original.recInfo.uid,
                    name: item.original.recInfo.firstName,
                    userImg: item.original.recInfo.profileImage,
                    count: list.filter(function (val) {
                        return val.original.recInfo.uid === item.original.recInfo.uid;
                    }).length
                });
            }
        }
        rcResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setRcUserList(rcResult)

        const yearResult = [];
        const yearmap = new Map();
        for (const item of list) {
            if (!yearmap.has(item.original.year)) {
                yearmap.set(item.original.year, true);
                yearResult.push({
                    name: item.original.year,
                    count: list.filter(function (val) {
                        return val.original.year === item.original.year;
                    }).length
                });
            }
        }
        yearResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setYearList(yearResult)

        const monthResult = [];
        const monthmap = new Map();
        for (const item of list) {
            if (!monthmap.has(item.original.month)) {
                monthmap.set(item.original.month, true);
                monthResult.push({
                    name: item.original.month,
                    count: list.filter(function (val) {
                        return val.original.month === item.original.month;
                    }).length
                });
            }
        }
        monthResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setMonthList(monthResult)

        const amResult = [];
        const ammap = new Map();
        for (const item of list) {
            if (!ammap.has(item.original.amInfo.id)) {
                ammap.set(item.original.amInfo.id, true);
                amResult.push({
                    id: item.original.amInfo.uid,
                    name: item.original.amInfo.firstName,
                    userImg: item.original.amInfo.profileImage,
                    count: list.filter(function (val) {
                        return val.original.amInfo.uid === item.original.amInfo.uid;
                    }).length
                });
            }
        }
        amResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setAmUserList(amResult)

        const cpResult = [];
        const cpmap = new Map();
        for (const item of list) {
            if (!cpmap.has(item.original.case_progress)) {
                cpmap.set(item.original.case_progress, true);
                cpResult.push({
                    name: (item.original.case_progress !== '') ? item.original.case_progress : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.case_progress === item.original.case_progress;
                    }).length
                });
            }
        }
        cpResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setCpList(cpResult)

        const csResult = [];
        const csmap = new Map();
        for (const item of list) {
            if (!csmap.has(item.original.case_stage)) {
                csmap.set(item.original.case_stage, true);
                csResult.push({
                    name: (item.original.case_stage !== '') ? item.original.case_stage : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.case_stage === item.original.case_stage;
                    }).length
                });
            }
        }
        csResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setCsList(csResult)

        const companyResult = [];
        const companymap = new Map();
        for (const item of list) {
            if (!companymap.has(item.original.jobDetail.company)) {
                companymap.set(item.original.jobDetail.company, true);
                companyResult.push({
                    name: (item.original.jobDetail.company !== '') ? item.original.jobDetail.company : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.jobDetail.company === item.original.jobDetail.company;
                    }).length
                });
            }
        }
        companyResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setCompanyList(companyResult)

        const projectResult = [];
        const projectmap = new Map();
        for (const item of list) {
            if (!projectmap.has(item.original.jobDetail.project)) {
                projectmap.set(item.original.jobDetail.project, true);
                projectResult.push({
                    name: (item.original.jobDetail.project) ? item.original.jobDetail.project : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.jobDetail.project === item.original.jobDetail.project;
                    }).length
                });
            }
        }
        projectResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setProjectList(projectResult)

        const roleResult = [];
        const rolemap = new Map();
        for (const item of list) {
            if (!rolemap.has(item.original.jobDetail.role)) {
                rolemap.set(item.original.jobDetail.role, true);
                roleResult.push({
                    name: (item.original.jobDetail.role !== '') ? item.original.jobDetail.role : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.jobDetail.role === item.original.jobDetail.role;
                    }).length
                });
            }
        }
        roleResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setRoleList(roleResult)

        const langResult = [];
        const langmap = new Map();
        for (const item of list) {
            if (!langmap.has(item.original.jobDetail.lang)) {
                langmap.set(item.original.jobDetail.lang, true);
                langResult.push({
                    name: (item.original.jobDetail.lang !== '') ? item.original.jobDetail.lang : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.jobDetail.lang === item.original.jobDetail.lang;
                    }).length
                });
            }
        }
        langResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setLangList(langResult)

        const cvSourceResult = [];
        const cvSourcemap = new Map();
        for (const item of list) {
            if (!cvSourcemap.has(item.original.cv_source)) {
                cvSourcemap.set(item.original.cv_source, true);
                cvSourceResult.push({
                    name: (item.original.cv_source !== '') ? item.original.cv_source : 'Blank',
                    count: list.filter(function (val) {
                        return val.original.cv_source === item.original.cv_source;
                    }).length
                });
            }
        }
        cvSourceResult.sort(function (a, b) {
            return b.count - a.count;
        });
        setCvSourceList(cvSourceResult)
    }, [list])

    return (
        <React.Fragment>
            <button className="dk-reoderBtn" onClick={handleShow}>
                <i className="fa fa-filter"></i>
            </button>
            <div className="dk-jobsMainModalCon">
                <Modal
                    show={show}
                    onHide={handleClose}
                    dialogClassName="dk-pipenwqrightFliterModal"
                >
                    <Modal.Header closeButton className="dkmb-Fliterheader">
                        <Modal.Title className="dkmb-FliterTitle">
                            SEARCH & REPORT
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="dkmb-Fliterbody dkg-pipelinesRightFilterBody-13">
                        <Tab.Container id="dk-jobsRightFilterModal" defaultActiveKey="company">
                            <div className="row ml-0 mr-0">
                                <div className="col-md-4 pl-0 pr-0">
                                    <Nav variant="pills" className="flex-column dk-leftSideMenu dkg-right-sideFilter-13">
                                        <Nav.Item>
                                            <Nav.Link eventKey="company">
                                                <i className="fa fa-building mr-2" aria-hidden="true"></i>Company
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="languages">
                                                <i className="fa fa-language mr-2" aria-hidden="true"></i>Languages
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="project">
                                                <MdInsertDriveFile className="mr-3" />Project
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="roletype">
                                                <i className="fa fa-user mr-2" aria-hidden="true"></i>Role Type
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="category">
                                                <i className="fa fa-language mr-2" aria-hidden="true"></i>Category
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="resources">
                                                <i className="fa fa-sitemap mr-2" aria-hidden="true"></i>Resources
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="rec-admin">
                                                <i className="fa fa-user mr-2" aria-hidden="true"></i>RR
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="rc">
                                                <i className="fa fa-user mr-2" aria-hidden="true"></i>RC
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="account-manager">
                                                <i className="fa fa-user mr-2" aria-hidden="true"></i>AM
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="status">
                                                <FaSpinner className="mr-2" />Status
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="progress">
                                                <FaSpinner className="mr-2" />Progress
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="casestage">
                                                <FaSpinner className="mr-2" />Case Stage
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="approval">
                                                <FaCheck className='mr-2' />PRIORTY
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="tabProfile">
                                                <i className="fa fa-circle mr-2" aria-hidden="true"></i>PROFILE
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="motivation">
                                                <i className="fa fa-user mr-2" aria-hidden="true"></i>CANDIDATE
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="flags">
                                                <i className="fa fa-flag mr-2" aria-hidden="true"></i>INTERVIEWS
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="tabMonth">
                                                <i className="fa fa-calendar mr-2" aria-hidden="true"></i>MONTH
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="tabYear">
                                                <i className="fa fa-calendar mr-2" aria-hidden="true"></i>YEAR
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                                <div className="col-md-8 pl-0 pr-0">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="rec-admin">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-Recuriter">
                                                    <ul>
                                                        {raUserList.map((item, index) => (
                                                            <li key={`raFilter${index}`}>
                                                                <Link to="#" data-type="RAID" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>
                                                                    <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.userImg} alt={item.name} className="recImg" title={item.name} data-type="RAID" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter} />
                                                                    <span className="recuriterNo text-orange" data-type="RAID" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>
                                                                        {item.count}
                                                                    </span>
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="rc">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-Recuriter">
                                                    <ul>
                                                        {rcUserList.map((item, index) => (
                                                            <li key={`rcFilter${index}`}>
                                                                <Link to="#" data-type="RCID" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>
                                                                    <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.userImg} alt={item.name} className="recImg" title={item.name} data-type="RCID" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter} />
                                                                    <span className="recuriterNo text-orange" data-type="RCID" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>
                                                                        {item.count}
                                                                    </span>
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="account-manager">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-Recuriter">
                                                    <ul>
                                                        {amUserList.map((item, index) => (
                                                            <li key={`amFilter${index}`}>
                                                                <Link to="#" data-type="AMID" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>
                                                                    <img src={process.env.REACT_APP_IMG_CLOUD_BASE_URL + item.userImg} alt={item.name} className="recImg" title={item.name} data-type="AMID" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter} />
                                                                    <span className="recuriterNo text-orange" data-type="AMID" data-id={item.id} data-img={item.userImg} onClick={selectUserFilter}>
                                                                        {item.count}
                                                                    </span>
                                                                </Link>
                                                            </li>
                                                        ))}

                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        {/* <Tab.Pane eventKey="motivation">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-flagFilter">
                                                    <ul>
                                                        <li>
                                                            <Link to="#" data-type="FlagID" data-id="1" data-img="#d3d3d3">
                                                                <i className="fa fa-user mr-2" aria-hidden="true" style={{ color: "#d3d3d3" }} data-type="FlagID" data-id="1" data-img="#d3d3d3"></i>
                                                                <span className="text-orange" data-type="FlagID" data-id="1" data-img="#d3d3d3">12</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" data-type="FlagID" data-id="1" data-img="#048204">
                                                                <i className="fa fa-user mr-2" aria-hidden="true" style={{ color: "#048204" }} data-type="FlagID" data-id="1" data-img="#048204"></i>
                                                                <span className="text-orange" data-type="FlagID" data-id="1" data-img="#048204">12</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" data-type="FlagID" data-id="1" data-img="#d58b5d">
                                                                <i className="fa fa-user mr-2" aria-hidden="true" style={{ color: "#d58b5d" }} data-type="FlagID" data-id="1" data-img="#d58b5d"></i>
                                                                <span className="text-orange" data-type="FlagID" data-id="1" data-img="#d58b5d">12</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" data-type="FlagID" data-id="1" data-img="#d60101">
                                                                <i className="fa fa-user mr-2" aria-hidden="true" style={{ color: "#d60101" }} data-type="FlagID" data-id="1" data-img="#d60101"></i>
                                                                <span className="text-orange" data-type="FlagID" data-id="1" data-img="#d60101">12</span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="flags">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-flagFilter">
                                                    <ul>
                                                        <li>
                                                            <Link to="#" data-type="FlagID" data-id="1" data-img="#d0cece" onClick={selectUserFilter}>
                                                                <i className="fa fa-flag mr-2" aria-hidden="true" style={{ color: "#d0cecd" }} data-type="FlagID" data-id="1" data-img="#d0cece" onClick={selectUserFilter}></i>
                                                                <span className="text-orange" data-type="FlagID" data-id="1" data-img="#d0cece" onClick={selectUserFilter}>
                                                                    {
                                                                        list.filter(function (val) {
                                                                            return val.original.flag_type === 1;
                                                                        }).length
                                                                    }
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" data-type="FlagID" data-id="2" data-img="green" onClick={selectUserFilter}>
                                                                <i className="fa fa-flag mr-2" aria-hidden="true" style={{ color: "green" }} data-type="FlagID" data-id="2" data-img="green" onClick={selectUserFilter}></i>
                                                                <span className="text-orange" data-type="FlagID" data-id="2" data-img="green" onClick={selectUserFilter}>
                                                                    {
                                                                        list.filter(function (val) {
                                                                            return val.original.flag_type === 2;
                                                                        }).length
                                                                    }
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" data-type="FlagID" data-id="3" data-img="red" onClick={selectUserFilter}>
                                                                <i className="fa fa-flag mr-2" aria-hidden="true" style={{ color: "red" }} data-id="3" data-img="red" onClick={selectUserFilter}></i>
                                                                <span className="text-orange" data-id="3" data-img="red" onClick={selectUserFilter}>
                                                                    {
                                                                        list.filter(function (val) {
                                                                            return val.original.flag_type === 3;
                                                                        }).length
                                                                    }
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" data-type="FlagID" data-id="4" data-img="eaa03d" onClick={selectUserFilter}>
                                                                <i className="fa fa-flag mr-2" aria-hidden="true" style={{ color: "#eaa03d" }} data-id="4" data-img="eaa03d" onClick={selectUserFilter}></i>
                                                                <span className="text-orange" data-id="4" data-img="eaa03d" onClick={selectUserFilter}>
                                                                    {
                                                                        list.filter(function (val) {
                                                                            return val.original.flag_type === 4;
                                                                        }).length
                                                                    }
                                                                </span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane> */}
                                        <Tab.Pane eventKey="approval">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-filterList">
                                                    <ul>
                                                        <li>
                                                            <Link to="#" data-type="PSID" data-id="1" data-img="048204" onClick={selectUserFilter}>
                                                                <FaCheck style={{ color: "#048204" }} className="mr-2" aria-hidden="true" data-type="PSID" data-id="1" data-img="048204" onClick={selectUserFilter} />

                                                                Top Priority

                                                                <span className="text-orange" data-type="PSID" data-id="1" data-img="048204" onClick={selectUserFilter}>
                                                                    {
                                                                        list.filter(function (val) {
                                                                            return val.original.profileStatus === 1;
                                                                        }).length
                                                                    }
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" data-type="PSID" data-id="2" data-img="#D58B5D" onClick={selectUserFilter}>
                                                                <FaCheck style={{ color: "#D58B5D" }} className="mr-2" aria-hidden="true" data-type="PSID" data-id="2" data-img="#D58B5D" onClick={selectUserFilter} />

                                                                Mid Priority
                                                                <span className="text-orange" data-type="PSID" data-id="2" data-img="#D58B5D" onClick={selectUserFilter}>
                                                                    {
                                                                        list.filter(function (val) {
                                                                            return val.original.profileStatus === 2;
                                                                        }).length
                                                                    }
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" data-type="PSID" data-id="4" data-img="d60101" onClick={selectUserFilter}>
                                                                <FaCheck style={{ color: "#d60101" }} className="mr-2" aria-hidden="true" data-type="PSID" data-id="4" data-img="d60101" onClick={selectUserFilter} />

                                                                Low Priority
                                                                <span className="text-orange" data-type="PSID" data-id="4" data-img="d60101" onClick={selectUserFilter}>
                                                                    {
                                                                        list.filter(function (val) {
                                                                            return val.original.profileStatus === 4;
                                                                        }).length
                                                                    }
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" data-type="PSID" data-id="3" data-img="d3d3d3" onClick={selectUserFilter}>
                                                                <FaCheck style={{ color: "#d3d3d3", marginLeft: "23px" }} className="mr-2" aria-hidden="true" data-id="3" data-img="d3d3d3" data-type="PSID" onClick={selectUserFilter} />

                                                                Blank
                                                                <span className="text-orange" data-type="PSID" data-id="3" data-img="d3d3d3" onClick={selectUserFilter}>
                                                                    {
                                                                        list.filter(function (val) {
                                                                            return val.original.profileStatus === 0;
                                                                        }).length
                                                                    }
                                                                </span>
                                                            </Link>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="tabProfile">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-filterList">
                                                    <ul>
                                                        <li>
                                                            <Link data-type="PIID" data-id="2" data-img="green" onClick={selectUserFilter}>

                                                                <i className="fas fa-circle" style={{ color: "green", marginRight: "8px" }} data-type="PIID" data-id="2" data-img="green" onClick={selectUserFilter}></i>

                                                                Updated

                                                                <span className="text-orange" data-type="PIID" data-id="2" data-img="green" onClick={selectUserFilter}>
                                                                    {
                                                                        list.filter(function (val) {
                                                                            return val.original.profileIndicator === 2;
                                                                        }).length
                                                                    }
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link data-type="PIID" data-id="1" data-img="#d3d3d3" onClick={selectUserFilter}>
                                                                <i className="fas fa-circle" style={{ color: "#d3d3d3", marginRight: "8px" }} data-type="PIID" data-id="1" data-img="#d3d3d3" onClick={selectUserFilter}></i>

                                                                Not Updated
                                                                <span className="text-orange" data-type="PIID" data-id="1" data-img="#d3d3d3" onClick={selectUserFilter}>
                                                                    {
                                                                        list.filter(function (val) {
                                                                            return val.original.profileIndicator === 1;
                                                                        }).length
                                                                    }
                                                                </span>
                                                            </Link>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="motivation">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-flagFilter">
                                                    <ul>
                                                        <li>
                                                            <Link to="#" data-type="PMID" data-id="1" data-img="048204" onClick={selectUserFilter}>
                                                                <i className="fa fa-user mr-2" aria-hidden="true" style={{ color: "#048204" }} data-type="PMID" data-id="1" data-img="048204" onClick={selectUserFilter}></i>
                                                                Motivated
                                                                <span className="text-orange" data-type="PMID" data-id="1" data-img="048204" onClick={selectUserFilter}>
                                                                    {
                                                                        list.filter(function (val) {
                                                                            return val.original.motivationStatus === 1;
                                                                        }).length
                                                                    }
                                                                </span>
                                                            </Link>
                                                        </li>

                                                        <li>
                                                            <Link to="#" data-type="PMID" data-id="2" data-img="d58b5d" onClick={selectUserFilter}>
                                                                <i className="fa fa-user mr-2" aria-hidden="true" style={{ color: "#d58b5d" }} data-type="PMID" data-id="2" data-img="d58b5d" onClick={selectUserFilter}></i>
                                                                Doubtful
                                                                <span className="text-orange" data-type="PMID" data-id="2" data-img="d58b5d" onClick={selectUserFilter}>
                                                                    {
                                                                        list.filter(function (val) {
                                                                            return val.original.motivationStatus === 2;
                                                                        }).length
                                                                    }
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" data-type="PMID" data-id="3" data-img="red" onClick={selectUserFilter}>
                                                                <i className="fa fa-user mr-2" aria-hidden="true" style={{ color: "red" }} data-type="PMID" data-id="3" data-img="red" onClick={selectUserFilter}></i>
                                                                Unmotivated
                                                                <span className="text-orange" data-type="PMID" data-id="3" data-img="red" onClick={selectUserFilter}>
                                                                    {
                                                                        list.filter(function (val) {
                                                                            return val.original.motivationStatus === 3;
                                                                        }).length
                                                                    }
                                                                </span>
                                                            </Link>
                                                        </li>

                                                        {/* <li>
                                                        <Link to="#" data-type="FlagID" data-id="1" data-img="#048204">
                                                            <i className="fa fa-user mr-2" aria-hidden="true" style={{ color: "#048204" }} data-type="FlagID" data-id="1" data-img="#048204"></i>
                                                            <span className="text-orange" data-type="FlagID" data-id="1" data-img="#048204">12</span>
                                                        </Link>
                                                    </li> */}
                                                        <li>
                                                            <Link to="#" data-type="PMID" data-id="0" data-img="d3d3d3" onClick={selectUserFilter}>
                                                                <i className="fa fa-user mr-2" aria-hidden="true" style={{ color: "#d3d3d3" }} data-type="PMID" data-id="0" data-img="d3d3d3" onClick={selectUserFilter}></i>
                                                                Blank
                                                                <span className="text-orange" data-type="PMID" data-id="0" data-img="d3d3d3" onClick={selectUserFilter}>

                                                                    {
                                                                        list.filter(function (val) {
                                                                            return val.original.motivationStatus === 0;
                                                                        }).length
                                                                    }
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        {/* <li>
                                                        <Link to="#" data-type="FlagID" data-id="1" data-img="#d58b5d">
                                                            <i className="fa fa-user mr-2" aria-hidden="true" style={{ color: "#d58b5d" }} data-type="FlagID" data-id="1" data-img="#d58b5d"></i>
                                                            <span className="text-orange" data-type="FlagID" data-id="1" data-img="#d58b5d">12</span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#" data-type="FlagID" data-id="1" data-img="#d60101">
                                                            <i className="fa fa-user mr-2" aria-hidden="true" style={{ color: "#d60101" }} data-type="FlagID" data-id="1" data-img="#d60101"></i>
                                                            <span className="text-orange" data-type="FlagID" data-id="1" data-img="#d60101">12</span>
                                                        </Link>
                                                    </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="flags">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-flagFilter">
                                                    <ul>
                                                        {/* <li>
                                                        <Link to="#" data-type="FlagID" data-id="1" data-img="#d0cece" onClick={selectUserFilter}>
                                                            <i className="fa fa-flag mr-2" aria-hidden="true" style={{ color: "#d0cecd" }} data-type="FlagID" data-id="1" data-img="#d0cece" onClick={selectUserFilter}></i>
                                                            <span className="text-orange" data-type="FlagID" data-id="1" data-img="#d0cece" onClick={selectUserFilter}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.original.flag_type === 1;
                                                                    }).length
                                                                }
                                                            </span>
                                                        </Link>
                                                    </li> */}
                                                        <li>
                                                            <Link to="#" data-type="FlagID" data-id="5" data-img="048204" onClick={selectUserFilter}>
                                                                <i className="fa fa-flag mr-2 ml-2" aria-hidden="true" style={{ color: "#048204" }} data-type="FlagID" data-id="5" data-img="048204" onClick={selectUserFilter}></i>
                                                                On Time
                                                                <span className="text-orange" data-type="FlagID" data-id="5" data-img="048204" onClick={selectUserFilter}>
                                                                    {
                                                                        list.filter(function (val) {
                                                                            return val.original.flag_type === 5;
                                                                        }).length
                                                                    }
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" data-type="FlagID" data-id="3" data-img="d58b5d" onClick={selectUserFilter}>
                                                                <i className="fa fa-flag mr-2 ml-2" aria-hidden="true" style={{ color: "#d58b5d" }} data-id="3" data-img="d58b5d" onClick={selectUserFilter}></i>
                                                                Delayed
                                                                <span className="text-orange" data-id="3" data-img="d58b5d" onClick={selectUserFilter}>
                                                                    {
                                                                        list.filter(function (val) {
                                                                            return val.original.flag_type === 3;
                                                                        }).length
                                                                    }
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" data-type="FlagID" data-id="2" data-img="d60101" onClick={selectUserFilter}>
                                                                <i className="fa fa-flag mr-2 ml-2" aria-hidden="true" style={{ color: "#d60101" }} data-id="2" data-img="d60101" onClick={selectUserFilter}></i>
                                                                Stopped
                                                                <span className="text-orange" data-id="2" data-img="d60101" onClick={selectUserFilter}>
                                                                    {
                                                                        list.filter(function (val) {
                                                                            return val.original.flag_type === 2;
                                                                        }).length
                                                                    }
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" data-type="FlagID" data-id="1" data-img="d3d3d3" onClick={selectUserFilter}>
                                                                <i className="fa fa-flag mr-2 ml-2" aria-hidden="true" style={{ color: "#d3d3d3" }} data-id="1" data-img="d3d3d3" onClick={selectUserFilter}></i>
                                                                Blank
                                                                <span className="text-orange" data-id="1" data-img="d3d3d3" onClick={selectUserFilter}>
                                                                    {
                                                                        list.filter(function (val) {
                                                                            return val.original.flag_type === 1;
                                                                        }).length
                                                                    }
                                                                </span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="category">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-CategoryList">
                                                    <ul>
                                                        <li>
                                                            <Link to="#" data-type="category" data-value="ML" onClick={selectOtherFilter}>ML
                                                                <span className="text-orange" data-value="ML" onClick={selectOtherFilter}>
                                                                    {
                                                                        list.filter(function (val) {
                                                                            return val.original.category === 'ML';
                                                                        }).length
                                                                    }
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" data-type="category" data-value="IT" onClick={selectOtherFilter}>IT
                                                                <span className="text-orange" data-value="IT" onClick={selectOtherFilter}>
                                                                    {
                                                                        list.filter(function (val) {
                                                                            return val.original.category === 'IT';
                                                                        }).length
                                                                    }
                                                                </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" data-type="category" data-value="None" onClick={selectOtherFilter}>None
                                                                <span className="text-orange" data-value="None" onClick={selectOtherFilter}>
                                                                    {
                                                                        list.filter(function (val) {
                                                                            return val.original.category === 'None';
                                                                        }).length
                                                                    }
                                                                </span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="status">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-satusListFilter">
                                                    <ul>
                                                        <li>
                                                            <Link to="#" data-type="caseStatus" style={{ backgroundColor: "#5c737d", color: "#fff" }} data-value="New CV" onClick={selectOtherFilter}>New Case<span className="text-orange" data-value="New CV" onClick={selectOtherFilter}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.original.caseStatus === 'New CV';
                                                                    }).length
                                                                }
                                                            </span></Link>
                                                        </li>
                                                        {/* <li>
                                                            <Link to="#" data-type="caseStatus" style={{ backgroundColor: "#26596d", color: "#fff" }} data-value="Selected" onClick={selectOtherFilter}>Selected<span className="text-orange" data-value="Screening" onClick={selectOtherFilter}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.original.caseStatus === 'Selected';
                                                                    }).length
                                                                }
                                                            </span></Link>
                                                        </li> */}
                                                        <li>
                                                            <Link to="#" data-type="caseStatus" style={{ backgroundColor: "#715f8f", color: "#fff" }} data-value="Screening" onClick={selectOtherFilter}>1st Level<span className="text-orange" data-value="Reminders" onClick={selectOtherFilter}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.original.caseStatus === 'Reminders';
                                                                    }).length
                                                                }
                                                            </span></Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" data-type="caseStatus" style={{ backgroundColor: "#57496d", color: "#fff" }} data-value="Processing" onClick={selectOtherFilter}>2nd Level <span className="text-orange" data-value="DKG Process" onClick={selectOtherFilter}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.original.caseStatus === 'DKG Process';
                                                                    }).length
                                                                }
                                                            </span></Link>
                                                        </li>
                                                        {/* <li>
                                                            <Link to="#" data-type="caseStatus" style={{ backgroundColor: "#42908c", color: "#fff" }} data-value="Queries" onClick={selectOtherFilter}>Queries <span className="text-orange" data-value="Client Process" onClick={selectOtherFilter}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.original.caseStatus === 'Client Process';
                                                                    }).length
                                                                }
                                                            </span></Link>
                                                        </li> */}
                                                        <li>
                                                            <Link to="#" data-type="caseStatus" style={{ backgroundColor: "#316a67", color: "#fff" }} data-value="Sendout" onClick={selectOtherFilter}>Interviews<span className="text-orange" data-value="Sendout" onClick={selectOtherFilter}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.original.caseStatus === 'Sendout';
                                                                    }).length
                                                                }
                                                            </span></Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" data-type="caseStatus" style={{ backgroundColor: "#629bd3", color: "#fff" }} data-value="Job Offer" onClick={selectOtherFilter}>Job Offer <span className="text-orange" data-value="Refused Offer" onClick={selectOtherFilter}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.original.caseStatus === 'Refused Offer';
                                                                    }).length
                                                                }
                                                            </span></Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" data-type="caseStatus" style={{ backgroundColor: "#a84d4d", color: "#fff" }} data-value="Refused Offer" onClick={selectOtherFilter}>Refused<span className="text-orange" data-value="Closed" onClick={selectOtherFilter}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.original.caseStatus === 'Refused Offer';
                                                                    }).length
                                                                }
                                                            </span></Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" data-type="caseStatus" style={{ backgroundColor: "#4768b0", color: "#fff" }} data-value="Joined" onClick={selectOtherFilter}>Joined <span className="text-orange" data-value="Joined" onClick={selectOtherFilter}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.original.caseStatus === 'Credit';
                                                                    }).length
                                                                }
                                                            </span></Link>
                                                        </li>
                                                        {/* <li>
                                                            <Link to="#" data-type="caseStatus" style={{ backgroundColor: "#d9714e", color: "#fff" }} data-value="Credit" onClick={selectOtherFilter}>Credit<span className="text-orange" data-value="Credit" onClick={selectOtherFilter}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.original.caseStatus === 'Credit';
                                                                    }).length
                                                                }
                                                            </span></Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#" data-type="caseStatus" style={{ backgroundColor: "#39485d", color: "#fff" }} data-value="Invoice" onClick={selectOtherFilter}>Invoice<span className="text-orange" data-value="Invoice" onClick={selectOtherFilter}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.original.caseStatus === 'Invoice';
                                                                    }).length
                                                                }
                                                            </span></Link>
                                                        </li> */}
                                                        <li>
                                                            <Link to="#" data-type="caseStatus" style={{ backgroundColor: "#da3d5e", color: "#fff" }} data-value="Closed" onClick={selectOtherFilter}>Closed<span className="text-orange" data-value="Closed" onClick={selectOtherFilter}>
                                                                {
                                                                    list.filter(function (val) {
                                                                        return val.original.caseStatus === 'Closed';
                                                                    }).length
                                                                }
                                                            </span></Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="progress">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-filterList">
                                                    <ul>
                                                        {cpList.map((item, index) => (
                                                            <li key={`caseprogress${index}`} data-type="case_progress" data-value={item.name} onClick={selectOtherFilter}>
                                                                <Link to="#" data-type="case_progress" data-value={item.name} onClick={selectOtherFilter}>
                                                                    {item.name}
                                                                    <span className="text-orange" data-type="case_progress" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="casestage">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-filterList">
                                                    <ul>
                                                        {csList.map((item, index) => (
                                                            <li key={`casestage${index}`} data-type="case_stage" data-value={item.name} onClick={selectOtherFilter}>
                                                                <Link to="#" data-type="case_stage" data-value={item.name} onClick={selectOtherFilter}>
                                                                    {item.name}
                                                                    <span className="text-orange" data-type="case_stage" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="company">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-filterList">
                                                    <ul>
                                                        {companyList.map((item, index) => (
                                                            <li key={`company${index}`} data-type="company" data-value={item.name} onClick={selectOtherFilter}>
                                                                <Link to="#" data-type="company" data-value={item.name} onClick={selectOtherFilter}>
                                                                    {item.name}
                                                                    <span className="text-orange" data-type="company" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="project">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-filterList">
                                                    <ul>
                                                        {projectList.map((item, index) => (
                                                            <li key={`project${index}`} data-type="project" data-value={item.name} onClick={selectOtherFilter}>
                                                                <Link to="#" data-type="project" data-value={item.name} onClick={selectOtherFilter}>
                                                                    {item.name}
                                                                    <span className="text-orange" data-type="project" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="roletype">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-filterList dkg-roletypeFilterlist12">
                                                    <ul>
                                                        {roleList.map((item, index) => (
                                                            <li key={`role${index}`} data-type="role" data-value={item.name} onClick={selectOtherFilter}>
                                                                <Link to="#" data-type="role" data-value={item.name} onClick={selectOtherFilter}>
                                                                    {item.name}
                                                                    <span className="text-orange" data-type="role" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="languages">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-filterList">
                                                    <ul>
                                                        {langList.map((item, index) => (
                                                            <li key={`lang${index}`} data-type="lang" data-value={item.name} onClick={selectOtherFilter}>
                                                                <Link to="#" data-type="lang" data-value={item.name} onClick={selectOtherFilter}>
                                                                    {item.name}
                                                                    <span className="text-orange" data-type="lang" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="resources">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-filterList">
                                                    <ul>
                                                        {cvSourceList.map((item, index) => (
                                                            <li key={`cv_source${index}`} data-type="cv_source" data-value={item.name} onClick={selectOtherFilter}>
                                                                <Link to="#" data-type="cv_source" data-value={item.name} onClick={selectOtherFilter}>
                                                                    {item.name}
                                                                    <span className="text-orange" data-type="cv_source" data-value={item.name} onClick={selectOtherFilter}>{item.count}</span>
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="tabMonth">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-flagFilter">
                                                    <ul>
                                                        {monthsList.map((item, index) => (
                                                            <li key={`monthFilter${index}`} data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                {
                                                                    <Link to="#" data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                        {item.name}
                                                                        <span className="recuriterNo text-orange" data-type="month" data-value={item.name} onClick={selectOtherFilter}>
                                                                            {item.count}
                                                                        </span>
                                                                    </Link>
                                                                }
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="tabYear">
                                            <div className="dk-rightMainContent">
                                                <div className="dk-flagFilter">
                                                    <ul>
                                                        {yearList.map((item, index) => (
                                                            <li key={`yearFilter${index}`} data-type="year" data-value={item.name} onClick={selectOtherFilter}>
                                                                {
                                                                    <Link data-type="year" data-value={item.name} onClick={selectOtherFilter}>
                                                                        {item.name}
                                                                        <span className="recuriterNo text-orange" data-type="year" data-value={item.name} onClick={selectOtherFilter}>
                                                                            {item.count}
                                                                        </span>
                                                                    </Link>
                                                                }
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </Tab.Pane>

                                    </Tab.Content>
                                </div>
                            </div>
                        </Tab.Container>
                    </Modal.Body>
                </Modal>
            </div>
        </React.Fragment>
    )
}
export default RightModalFilter;