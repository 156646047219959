import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import './details/itteam-summary.scss';
import { setMyModule } from '../../../slice/modules/moduleSlice';
import ProjectionsIcon from '../../../assets/images/projections.png';
import InformationIcon from '../../../assets/images/information_white.png';
import CalendarIcon from '../../../assets/images/calendar-white.png';
import UidesignerIcon from '../../../assets/images/uidesigner.png';
import DevelopmentIcon from '../../../assets/images/app-development.png';
import TesterIcon from '../../../assets/images/tester.png';
import ResourcesIcon from '../../../assets/images/resources.png';
import ServiceStatusIcon from '../../../assets/images/service-status.png';
import SecurityStatusIcon from '../../../assets/images/security-status.png';

import Moduleheader from "../../elements/header/index";

const ItTeamModule = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        document.title = "DK Global CRM - TECHNICAL"
    }, []);
    const { userInfo } = useSelector(state => state.auth);

    const setModule = async (myModule, moduleColor) => {
        await dispatch(setMyModule({ module: myModule, color: moduleColor }))
        switch (myModule) {
            case 'it-teams':
                history.push("/dkglobaladmin/it-teams/details");
                break;

            case 'onboarding':
                history.push("/dkglobaladmin/onboarding/dashboard");
                break;

            case 'guidelines':
                history.push("/itteams/guidelines/dashboard");
                break;

            case 'developertask':
                history.push("/itteams/developertask/developer-tasks");
                break;

            case 'designertasks':
                history.push("/itteams/designertasks/designer-tasks");
                break;

            default:
                break;
        }
    }
    return (
        <>
            <Moduleheader dashboardheaderLink="Dashboard -" dashboardLink="/dashboard/plateform" linkcolor="#3c4c62" mainheaderTitle="TECHNICAL" textcolor="#b55355" />
            <div className="dk-itteamModulemain">
                <div className="dk-it-teamemModule-container">
                    <div className="title mb-2">TECHNICAL DEPARTMENT</div>
                    <div className="d-flex d-none" style={{display: "none"}}>
                        <div className="dk-it-team-Module-box d-none">
                            <Link to="#" style={{ background: '#b55355' }}>
                                <img src={ResourcesIcon} alt="" />
                                <p>Resources</p>
                            </Link>
                        </div>
                        <div className="dk-it-team-Module-box d-none">
                            <Link to="#" style={{ background: '#b55355' }}>
                                <img src={ServiceStatusIcon} alt="" />
                                <p>Services Status</p>
                            </Link>
                        </div>
                        <div className="dk-it-team-Module-box d-none">
                            <Link to="#" style={{ background: '#b55355' }}>
                                <img src={SecurityStatusIcon} alt="" />
                                <p>Security Steps</p>
                            </Link>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="dk-it-team-Module-box">
                            <Link to="#" onClick={() => setModule('it-teams')} style={{ background: '#b55355' }}>
                                <img src={ProjectionsIcon} alt="" />
                                <p>Projects</p>
                            </Link>
                        </div>
                        <div className="dk-it-team-Module-box">
                            <Link to="#" onClick={() => setModule('guidelines')} style={{ background: '#b55355' }}>
                                <img src={InformationIcon} alt="" />
                                <p>Guidelines</p>
                            </Link>
                        </div>
                        <div className="dk-it-team-Module-box" style={{opacity: "0"}}>
                            <Link to="#" style={{ background: '#b55355' }}>
                                <img src={CalendarIcon} alt="" />
                                <p>Backlogs</p>
                            </Link>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="dk-it-team-Module-box d-none">
                            <Link to="#" onClick={() => setModule('designertasks')} style={{ background: '#b55355' }}>
                                <img src={UidesignerIcon} alt="" />
                                <p>Designers Tasks</p>
                            </Link>
                        </div>
                        <div className="dk-it-team-Module-box d-none">
                            <Link to="#" onClick={() => setModule('developertask')} style={{ background: '#b55355' }}>
                                <img src={DevelopmentIcon} alt="" />
                                <p>Developer Tasks</p>
                            </Link>
                        </div>
                        <div className="dk-it-team-Module-box d-none">
                            <Link to="#" style={{ background: '#b55355' }}>
                                <img src={TesterIcon} alt="" />
                                <p>Sample Designs</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ItTeamModule;
